import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import React, { FC, useEffect } from 'react'

import { useStyles } from './useStyles'
import { useTranslation } from 'react-i18next'

type ValueType = string | number

type Options = {
  id: ValueType
  value: string
}

type Props = {
  label: string
  options: Options[]
  select: string | number
  onChange: (value: ValueType) => void
  disabled?: boolean
}

export const SelectOutlined: FC<Props> = ({
  label,
  options,
  select,
  onChange,
  disabled,
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [value, setValue] = React.useState<ValueType>('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as ValueType
    if (value === newValue) {
      return
    }
    setValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    setValue(select)
  }, [select])

  const selectedText = t('appointment.new.schedule.selected')

  return (
    <FormControl variant="standard" fullWidth className={classes.selectLine}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value ?? ''}
        label={label}
        onChange={handleChange}
        disabled={disabled ?? false}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              maxWidth: 400,
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          <em>{selectedText}</em>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
