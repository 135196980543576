/* eslint-disable no-unused-expressions */

import './HospitalizationStyles.css'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import FormDischarge from './Forms/FormDischarge'
import FormFinancial from '../FormFinancial/FormFinancial'
import FormHospitalization from './Forms/FormHospitalization'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import RegularButton from '../CustomButtons/Button.jsx'
import Snackbar from '../Snackbar/Snackbar'
import FormPatients from '../FormPatients'

import { useHospitalizationForm } from './hooks/useHospitalizationForm'

const HospitalizationForm = (props) => {
  const {loading, validateForm, dataDischarge, validateFrequencyError, isValidatedHospitalizationForm, goToAdmissionList, dataDischargeEdit, setValidateFrequencyError, setDataDischargeForm, setIsValidatedDischargeForm, showDischargeForm, dataHospitalizationEdit, setIsValidatedHospitalizationForm, setDataHospitalizationtForm, id, validationShowDischargeForm,personId, setIsValidatedFinanceForm, setDataFinanceForm, colorSnackBar, messageSnackBar, showSnackBar, save,dataPatientEdit,setPersonId,setIsValidatedPatientForm,setDataPatientForm, readOnly} = useHospitalizationForm(props)
  return (
    <form>
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <FormPatients
            setDataPatientForm={setDataPatientForm}
            setIsValidatedPatientForm={setIsValidatedPatientForm}
            setPersonId={setPersonId}
            editData={dataPatientEdit}
            save={save}
            onlyView={readOnly}
            id={id}
            variant="hospitalization"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormFinancial
            setDataFinanceForm={setDataFinanceForm}
            setIsValidatedFinanceForm={setIsValidatedFinanceForm}
            personId={personId}
            onlyView={readOnly}
            type={"Hospitalization"}
          ></FormFinancial>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormHospitalization
            validationShowDischargeForm={validationShowDischargeForm}
            setDataHospitalizationForm={setDataHospitalizationtForm}
            setIsValidatedHospitalizationForm={
              setIsValidatedHospitalizationForm
            }
            editData={dataHospitalizationEdit}
            save={save}
            onlyView={readOnly}
          ></FormHospitalization>
        </GridItem>
        {showDischargeForm && (
          <GridItem xs={12} sm={12} md={12}>
            <FormDischarge
              setIsValidatedDischargeForm={setIsValidatedDischargeForm}
              SetDataDischargeForm={setDataDischargeForm}
              setFrequencyError={setValidateFrequencyError}
              editData={dataDischargeEdit}
              save={save}
              onlyView={readOnly}
            ></FormDischarge>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer xs={12} sm={12} md={12}>
        <div className="buttons-container">
          <div>
            <RegularButton onClick={goToAdmissionList}>CANCELAR</RegularButton>
          </div>
          <div className="button-submit-container">
            {(id && !readOnly) && (
              <RegularButton
                disabled={
                  !isValidatedHospitalizationForm ||
                  !showDischargeForm ||
                  (showDischargeForm &&
                    (!validateFrequencyError ||
                      !dataDischarge.internmentKatz ||
                      !dataDischarge.barthel_index))
                }
                color="success"
                onClick={() => validateForm('register')}
              >
                DAR DE ALTA
              </RegularButton>
            )}            
              <RegularButton onClick={() => validateForm('save')} color="info">
                {loading ? (
                  <>
                    <CircularProgress size={18} />
                    <span>GUARDANDO</span>
                  </>
                ) : (
                  <span>GUARDAR CAMBIOS</span>
                )}
              </RegularButton>           
          </div>
        </div>
      </GridContainer>
    </form>
  )
}

export default HospitalizationForm
