import BaseInvoker from "./BaseInvoker";
import { Component } from 'react';

const urlInvoke = "/schedule";
const baseInvoker = new BaseInvoker();

export default class SchedulerInvoker extends Component {

	static postBookings(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/bookings`, body, callback, callerror);
	}

	/**
	 * API Call used to create an appointment without any booking's agenda
	 * @param {*} body 
	 * @param {*} callback 
	 * @param {*} callerror 
	 */

	static postAppointment(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/new-appointment`, body, callback, callerror);
	}

	static assignAgenda(appointmentId, selected, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/new-schedule-to-appointment/${appointmentId}/items/${selected}`, null, callback, callerror)
	}

	static postSaveBookings(appointmentBookingId, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/save-bookings/${appointmentBookingId}`, null, callback, callerror);
	}

	static postSaveBookingsItems(appointmentBookingId,items, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/save-bookings/${appointmentBookingId}/items/${items}`, null, callback, callerror);
	}

	static postDynamicContent(type, id, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/${type}/${id}`, body, callback, callerror);	
	}

	static postDynamicContentOnlyCheck(type, id, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/${type}/${id}?only-check=true`, body, callback, callerror);	
	}

	static deleteBookings(appointmentBookingId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/delete-bookings/${appointmentBookingId}`, callback, callerror);
	}

	static cancelSchedule(scheduleId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/cancel-schedule/${scheduleId}`, body, callback, callerror);
	}

	static deleteBookingsByArray(body, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/delete-bookings/`, body, callback, callerror);
	}

	static getAppointmentScheduleItems(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/${appointmentId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static scheduleReasign(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/schedules-reasign/`, body, callback, callerror);
	}

	static putValidateSchedule(scheduleId, body, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/schedule/${scheduleId}/validate`, body, callback, callerror);	
	}

	static getScheduleAppointmentByMonth(appointmentId, firstDayOfMonth, callback, callerror) {
		const url = `${urlInvoke}/bookings-for-calendar/${appointmentId}/date-to-query/${firstDayOfMonth}`;
		baseInvoker.get(url, callback, callerror);
	}
}