import '../../../../assets/plugins/react-charts.css';
import './integralDashboard.css';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { FolderShared } from '@material-ui/icons';
import React, { Component } from 'react';
import Assignment from '@material-ui/icons/Assignment';
import Button from '../../../CustomButtons/Button';
import Card from '../../../Card/Card';
import CardBody from '../../../Card/CardBody';
import CardFooter from '../../../Card/CardFooter';
import CardHeader from '../../../Card/CardHeader';
import CardIcon from '../../../Card/CardIcon';
import ClinicalHistory from '../../../ClinicalHistory/ClinicalHistory';
import CustomDialog from '../../../CustomDialog/index';
import { Edit } from '@material-ui/icons';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import HistoriaClinicaApi from '../../../../api/HistoriaClinicaApi';
import { IntegralFiltersService } from '../../../../containers/IntegralDashboardCustomerService';
import PropTypes from 'prop-types';
import Snackbar from '../../../Snackbar/Snackbar';
import Table from '../../../Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import VitalSignsModal from './VitalSignsModal/VitalSignsModal';
import { browserHistory } from 'react-router';
import config from '../../../../config/config';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Stethoscope from '../../../../icons/Stethoscope/index'
import { parseISO, differenceInDays, format, parse } from 'date-fns'

const dateFormat = config.getDateFormat();
const dateTimeFormat = config.getDateTimeFormat();
const dateToServer = config.getDateToServer();

class IntegralDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appointments: [],
            scheduleSelected: null,
            originalquery: [],
            professionals: [],
            employeesType: [],
            oemployeesType: [],
            vitalsigns: [],
            dateFrom: '',
            dateTo: '',
            employeeId: '',
            graphics: false,
            open: false,
            clear: false,
            employees: [],
            chips: [],
            alertOpen: false,
            alertColor: 'warning',
            alertMessage: '',
            openClinicalHistory: false,
            action: "",
            scheduleId: "",
            refresh: false
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees) {
            this.setState({
                employees: next.employees,
                professionals: next.employees,
            });
        }
        if (next.employeesType) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: next.employeesType,
            });
        }
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
            scheduleSelected: null,
        });
    }

  transformDateFormat (date) {
        const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
        return format(parsedDate, 'yyyy-MM-dd 00:00:00.000');
      };

    closeClinicalHistory() {
        let shouldRefresh = this.state.action === "create"
        this.setState({
            openClinicalHistory: false,
        });
        if(shouldRefresh){
            let startDate = this.transformDateFormat(this.state.start_date)
            let endDate = this.transformDateFormat(this.state.end_date)
            this.setFilters({ start_date: startDate, end_date: endDate })}
    }

    createExcelFields = () => {
        const { t } = this.props;
        return {
            'appointmentId': t('integralDashboardClinical.table.appointmentId'),
            'scheduleId': t('integralDashboardClinical.table.scheduleId'),
            'scheduleDate': t('integralDashboardClinical.table.scheduleDate'),
            'employeesType': t('integralDashboardClinical.table.practiceTypeName'),
            'employees': t('integralDashboardClinical.table.employee'),
            'note': t('integralDashboardClinical.table.note'),
            'timeRegistry': t('integralDashboardClinical.modal.register.timeRegistry'),
            'temperature': `${t('integralDashboardClinical.modal.table.temperature')} (°C)`,
            'breathing': `${t('integralDashboardClinical.modal.table.breathing')} (%)`,
            'pulse': `${t('integralDashboardClinical.modal.table.pulse')} (ppm)`,
            'pressureMax': `${t('integralDashboardClinical.modal.table.pressureMax')} (mmHg)`,
            'pressureMin': `${t('integralDashboardClinical.modal.table.pressureMin')} (mmHg)`,
        };
    }

    createExcelDataRow = (ap) => {
        const data = {
            appointmentId: ap.appointmentId,
            scheduleId: ap.scheduleId,
            scheduleDate: ap.scheduleDate,
            employeesType: ap.employeesType,
            employees: ap.employees,
            note: ap.metadata?.note || '',
        }
        const timeRegistry = moment(ap.scheduleDateTime).format(dateTimeFormat);
        if (ap.metadata?.vitalSignsList?.length > 0) {
            return ap.metadata.vitalSignsList.map(e => ({
                ...data,
                timeRegistry: moment(e.timeRegistry).format(dateTimeFormat) || timeRegistry,
                temperature: e.temperature || '',
                breathing: e.breathing || '',
                pulse: e.pulse || '',
                pressureMax: e.pressure.max || '',
                pressureMin:e.pressure.min || '',
            }));
        }
        return {
            ...data,
            timeRegistry,
            temperature: '',
            breathing: '',
            pulse: '',
            pressureMax: '',
            pressureMin: '' ,
        };
    }

    createExcelDataRows = () => {
        const fields = this.createExcelFields();
        const vitalsigns = this.state.vitalsigns.map(ap => this.createExcelDataRow(ap));
        const dataRows = [fields, ...vitalsigns];
        return dataRows.flat();
    }

    exportToExcel = () => {
        const { customer } = this.props;
        const dataExcel = this.createExcelDataRows();
        const fileName = `HC-${customer.id}-${customer.lastName}${customer.firstName}-${moment().format('YYYYMMDD')}`;
        const fileType = 'xlsx';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(dataExcel, { skipHeader: 1 });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data2 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data2, fileName + fileExtension);
    }

    openAlert(color, message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
            alertColor: color,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    isWithinThreeDays(endDateTime){
        if (!endDateTime) return false
        const endDate = parseISO(endDateTime)
        const currentDate = new Date()
        const daysDifference = differenceInDays(currentDate, endDate)
        return daysDifference <= 3
      }


    setFilters(filters) {
        if (filters.start_date.length > 0 && filters.end_date.length > 0) {
            this.setState({
                vitalsigns: [],
                start_date: moment(filters.start_date).format(dateFormat),
                end_date: moment(filters.end_date).format(dateFormat),
                loadingEmployeesTable: true,
            })
            HistoriaClinicaApi.getIntegralClinicalHistoryIntegral(
                this.props.patientId,
                moment(filters.start_date).format(dateToServer),
                moment(filters.end_date).format(dateToServer),
            data => {
                this.formatTableData(data);
                this.setState({loadingEmployeesTable: false});
            }, err => {
                console.error(err.message);
                this.setState({loadingEmployeesTable: false});
                const message = err.message ?? err;
                this.openAlert('danger', message + '');
            });
        }
    }

    getArrayOf = (filters, type) => {
        const ret = [];
        filters.chips.map(e => {
            if (e.code === type) {
                ret.push(e.name);
            }
            return ret;
        });
        return ret;
    }
    
    containsAny = (source, target) => {
        const result = source.filter(function(item){ return target.indexOf(item) > -1});   
        return (result.length > 0);  
    } 

    softFilter = (filters) => {
        const { originalquery } = this.state;
        let result = originalquery;
        const arra = this.getArrayOf(filters, 'employeesType');
        let profArray = this.getArrayOf(filters,'employees');
        
        filters.chips.map(e => {
            result =  result.filter(item => {
                if (e.code === 'employeesType') {
                    return this.containsAny(item[e.code].split(','),arra);
                } else {
                    return this.containsAny(item[e.code].split(','),profArray);
                }
            });
        })
        this.setState({ vitalsigns: result });
    }

    formatTableData(data) {
        const { t } = this.props;
        const userId = window.localStorage.getItem('user_id')
        if (data?.length) {
            const result = data.map(d => {
                const tooltip = t('integralDashboardClinical.table.viewVitalSignsActived');
                return ({
                    scheduleDateTime: d.scheduleDateTime,
                    appointmentId: d.appointmentId,
                    scheduleId: d.scheduleId,
                    scheduleDate: moment(d.scheduleDateTime).format(dateFormat),
                    employeesType: d.practiceTypeName,
                    practiceTypeId: d.practiceTypeId,
                    employees: `${d.employeeLastName} ${d.employeeFirstName}`,
                    employeeId: d.employeeId,
                    financier: d.financierName,
                    note: d.metadata?.note ?? '',
                    actions: (
                     <div className="appointments-actions">
                        <Tooltip title={tooltip}>
                            <span>
                                <Button
                                    id={`button-view-${d.scheduleId}`}
                                    simple
                                    justIcon
                                    color="primary"
                                    authority="appointment_view,appointment_view_related"
                                    onClick={() => 
                                        browserHistory.push({
                                            state: { mode: 'view', action: "finished_system" },
                                            pathname: `/patient/${this.props.patientId}/medical-record/sign-vitals/${d.scheduleId}`,
                                        })                                        
                                    }
                                ><Assignment />
                                </Button>
                            </span>
                        </Tooltip>
                        {d.hasMedicalHistory && 
                        <Tooltip title={t('clinicalHistory.view')}>
                            <span>
                                <Button
                                    id={`button-view-${d.scheduleId}`}
                                    simple justIcon 
                                    color="primary" 
                                    authority="customers_view,customers_view_related"
                                    onClick={() =>  this.setState({
                                    action:"view",
                                    scheduleId: d.scheduleId,
                                    openClinicalHistory: true,
                                })}>
                                    <Stethoscope />
                                </Button>
                            </span>
                     
                        </Tooltip> 
                         }
                        {!d.hasMedicalHistory && d.status === "DONE" && parseInt(userId) === d.employeeId && this.isWithinThreeDays(d.scheduleEndDateTime) &&
                        <Tooltip title={t('clinicalHistory.edit')}>
                            <span>
                                <Button
                                    id={`button-view-${d.scheduleId}`}
                                    simple justIcon 
                                    color="primary" 
                                    authority="customers_view,customers_view_related"
                                    onClick={() =>  this.setState({
                                    action:"create",
                                    scheduleId: d.scheduleId,
                                    openClinicalHistory: true,
                                })}>
                                    <Edit />
                                </Button>
                            </span>
                        </Tooltip> 
                         }
                        </div>
                     
                    ),
                    metadata: d.metadata,
                });
            });
            this.setState({
                vitalsigns: result,
                originalquery: result,
                loadingEmployeesTable: false,
            });
            this.softFilter(this.state);
        }
    }

    clearInformation() {
        this.setState({
            employees: [],
            employeeId: '',
            dateFrom: '',
            dateTo: '',
            clear: true,
            chips: [],
            vitalsigns:[],
            originalquery: [],
            scheduleSelected: null,
        });
    }

    sortDate = (a, b) => {
        const a1 = moment(a, dateFormat).valueOf();
        const b1 = moment(b, dateFormat).valueOf();
        if (a1<b1) {
            return -1;
        }
        if (a1>b1) {
            return 1;
        }
        return 0;
    }
    
    render() {
        const { classes, t } = this.props;
        const {
            chips, scheduleId, open, alertColor, alertMessage, action, alertOpen, loadingEmployeesTable, vitalsigns, scheduleSelected, openClinicalHistory, refresh
        } = this.state;
        return (
            <GridContainer className="integral-medical-records schedules-list">    
                <GridItem xs={12} className={[`${classes.integralDashboard} table-container`]}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <FolderShared />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('medical.record.schedules.title')}</h3>
                            <IntegralFiltersService
                                chips={chips}
                                onChangeChips={(filters) => this.softFilter(filters)}
                                onChange={(filters) => this.setFilters(filters)}
                                onClearFilters={() => this.clearInformation()}
                            />
                        </CardHeader>
                        <CardBody className="dashboard-content-body filtrable-table">
                            <Snackbar
                                place="tr"
                                color={alertColor}
                                message={alertMessage}
                                open={alertOpen}
                            />
                            <Table
                                loading={loadingEmployeesTable}
                                tableHeaderColor="primary"
                                sortable
                                filtrable
                                tableHead={[
                                    { Header: t('integralDashboardClinical.table.appointmentId'), accessor: 'appointmentId', filterable: true },
                                    { Header: t('integralDashboardClinical.table.financier'), accessor: 'financier', filterable: true },
                                    { Header: t('integralDashboardClinical.table.scheduleId'), accessor: 'scheduleId', filterable: true },
                                    { Header: t('integralDashboardClinical.table.scheduleDate'), accessor: 'scheduleDate', filterable: true, sortMethod: (a, b) => this.sortDate(a, b) },
                                    { Header: t('integralDashboardClinical.table.practiceTypeName'), accessor: 'employeesType', filterable: true },
                                    { Header: t('integralDashboardClinical.table.employee'), accessor: 'employees', filterable: true },
                                    { Header: t('integralDashboardClinical.table.note'), accessor: 'note', sortable: false, filterable: true },
                                    { Header: t('integralDashboardClinical.table.actions'), accessor: 'actions', sortable: false },
                                ]}
                                tableData={vitalsigns}
                                defaultPageSize={vitalsigns.length > 5 ? 20 : 5}
                                colorsColls={['primary']}
                            />
                        </CardBody>
                        <CardFooter className="footer-buttons">
                            <p className="legend-footer">{t('integralDashboardClinical.table.total')}: {vitalsigns.length}</p>
                            <Button id="button-export" data-testid="button-export" onClick={() => this.exportToExcel()} color="primary" disabled={vitalsigns.length < 1}>
                                {t('common.exportExcel')}
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>

                <CustomDialog
                    title={t('integralDashboardClinical.modal.tittle')}
                    open={open}
                    onClose={() => this.closeModal()}
                >
                    <VitalSignsModal closetheModal={() => this.closeModal()} scheduleSelected={scheduleSelected} scheduleVitalSigns={vitalsigns}/>
                </CustomDialog>
                <CustomDialog
                    title={t('clinic_history.title')}
                    open={openClinicalHistory}
                    onClose={() => this.closeClinicalHistory()}
                >
                    <ClinicalHistory closeModal={() => this.closeClinicalHistory()} scheduleId={scheduleId} action={action}/>
                </CustomDialog>
            </GridContainer>
        )
    }
}

const styles = {
    'integralDashboard': {
        'padding': '0 !important'
    }
}

IntegralDashboard.propTypes = {
    t: PropTypes.func,
    customer: PropTypes.object,
    patientId: PropTypes.number,
    classes: PropTypes.object,
}

export default withStyles(styles)(withTranslation()(IntegralDashboard));
