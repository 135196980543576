import React, { useEffect, useMemo, useState } from 'react'
import BusinessApiInvoker from '../../api/BusinessApiInvoker'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import Card from '../Card/Card.jsx'
import CardBody from '../Card/CardBody.jsx'
import CardHeader from '../Card/CardHeader.jsx'
import CardIcon from '../Card/CardIcon.jsx'
import CustomInput from '../CustomInput/CustomInput.jsx'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

type FormFinancialProps = {
  setDataFinanceForm: (data: any) => void
  setIsValidatedFinanceForm: React.Dispatch<React.SetStateAction<boolean>>
  personId: number
  save: () => void
  type: 'Diabetic' | 'Hospitalization'
}

export const FormFinancial = ({
  setDataFinanceForm,
  setIsValidatedFinanceForm,
  personId,
  save,
  type,
}: FormFinancialProps) => {
  const [axa, setAxa] = useState<string | null>(null)
  const [axaID, setAxaID] = useState<string | null>(null)
  const [isValidated, setIsValidated] = useState(false)

  let data = {
    axa: axa,
    axaID: axaID,
  }

  const isValidateData = (personId: number) => {
    BusinessApiInvoker.getCustomersFinanciers(personId, (data: any) => {
      setAxa(data[0].financier.businessName)
      setAxaID(data[0].affiliateId)
      validateForm()
    })
  }
  const validateForm = () => {
    if (axaID || personId) {
      setIsValidated(true)
    } else {
      setIsValidated(false)
    }
  }
  useEffect(() => {
    validateForm()
    if (personId) {
      isValidateData(personId)
    }
    setDataFinanceForm(data)
    setIsValidatedFinanceForm(isValidated)
  }, [axa, axaID, personId, isValidated])

  const typeData = useMemo(() => {
    if (type === 'Diabetic') {
      return { axaId: 'axaID', error: !axaID && save, idDisabled: axa }
    } else {
      return { axaId: 'axa_id', error: null, idDisabled: axa === null }
    }
  }, [type, axaID, axa, save])

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <BusinessCenterIcon />
        </CardIcon>
        <h4>Datos financieros</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <CustomInput
              id="axa"
              data-testid="axaInput"
              value={axa ?? ''}
              labelText={'Financiador'}
              formControlProps={{
                fullWidth: true,
                disabled: true,
              }}
              inputProps={{
                type: 'text',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAxa(e.target.value)
              }
            />
          </GridItem>
          <GridItem md={12}>
            <CustomInput
              id={typeData.axaId}
              data-testid="beneficiaryInput"
              value={axaID ?? ''}
              labelText={'Nº de beneficiario'}
              formControlProps={{
                fullWidth: true,
                disabled: true,
              }}
              inputProps={{
                type: 'text',
              }}
              disabled={typeData.idDisabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAxaID(e.target.value)
              }
              error={typeData.error}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormFinancial
