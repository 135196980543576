import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import HotelIcon from '@material-ui/icons/Hotel';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import CardIcon from '../../Card/CardIcon';
import GridItem from '../../Grid/GridItem';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import IntegralDashboard from './IntegralDashboard/index';
import '../IntegralTechnicalFile.css';

const IntegralTechnicalFileForm = (props) => {
  const { t } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState({
    personId: null,
    firstName: '',
    lastName: '',
    idType: {
      companyId: null,
      idTypeId: null,
      name: '',
      type: '' 
    },
    id: '',
    email: '',
    sex: '',
    phone: [],
    address: [],
    nationality: {
      nationalityId: null,
      name: '',
    },
    companyId: null,
    metadata: {},
  });

  // Component Did Mount
  // Customer file from API
  useEffect(() => {
    fetchCustomer(props.patientId);
  }, []);

  const fetchCustomer = async (patientId) => {
    PersonApiInvoker.getCustomer(patientId, data => {
      setIsLoading(false);
      if (!data) {
        return console.error('** No data getCustomer!!');
      } else {
        setCustomer(data.customer);
      }
    }, (error) => {
      console.error('** error getCustomer', error);
    });
  }

  return (
    isLoading 
      ? <GridItem xs={12}>
          <Card>
            <CardBody>
              <div className="progressContent">
                <CircularProgress />
              </div> 
            </CardBody>
          </Card>
        </GridItem>
        : <>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="primary">
                  <HotelIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      classes={{ input: "input-technical-file" }}
                      labelText={t('technicalfile.lastNameAndName')}
                      value={`${customer?.lastName} ${customer?.firstName}`}
                      inputProps={{ shrink: !!(customer?.firstName), }}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6}>
                    <CustomInput
                      classes={{ input: "input-technical-file" }}
                      labelText={t('technicalfile.docType')}
                      value={customer?.idType?.name}
                      inputProps={{ shrink: !!(customer?.idType?.name), }}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6}>
                    <CustomInput
                      classes={{ input: "input-technical-file" }}
                      labelText={t('technicalfile.docNumber')}
                      value={customer?.id} 
                      inputProps={{ shrink: !!(customer?.id), }}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }} />
                  </GridItem>
                  <GridItem xs={6} sm={6}>
                    <CustomInput
                      classes={{ input: "input-technical-file" }}
                      labelText={t('technicalfile.age')}
                      value={customer?.birthdate && moment().diff(moment(customer?.birthdate), 'years')} 
                      inputProps={{ shrink: !!(customer?.birthdate && moment().diff(moment(customer?.birthdate), 'years')), }}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }} />
                  </GridItem>
                  <GridItem xs={6} sm={6}>
                    <CustomInput
                      classes={{ input: "input-technical-file" }} 
                      labelText={t('technicalfile.nationality')} 
                      value={customer?.nationality?.name} 
                      inputProps={{ shrink: !!(customer?.nationality?.name), }}
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card> 
        </GridItem>
        {isLoading 
          ? <Card>
            <CardBody>
              <div style={{
                display: 'flex',
                height: '200px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <CircularProgress />
              </div> 
            </CardBody>
          </Card>
          : <GridItem xs={12}>
            <IntegralDashboard patientId={props.patientId} customer={customer} />
        </GridItem>
      }
    </>
  )
}

IntegralTechnicalFileForm.propTypes = {
  t: PropTypes.func,
  patientId: PropTypes.number,
};

export default withTranslation()(IntegralTechnicalFileForm);
