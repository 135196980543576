import React, { useEffect } from 'react'

import { ClinicalHistoryData } from '../interfaces'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import SelectInput from '../../SelectInput'
import { Typography } from '@material-ui/core'
import ValidatedTextField from '../components/ValidatedInput'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import { useTranslation } from 'react-i18next'

interface Props {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const Diagnostic: React.FC<Props> = ({ data, handleFieldChange, disabled }) => {
  const { t } = useTranslation()
  const { getPathologiesData, pathologyOptions } = useClinicalHistory()

  useEffect(() => {
    getPathologiesData()
  }, [])

  const translations = {
    labelDiagnostic: t('technicalfile.diagnostic'),
  }

  return (
    <GridContainer>
      <GridItem md={12} className="step-title">
        <Typography variant="h6">{translations?.labelDiagnostic}</Typography>
      </GridItem>

      <GridItem
        md={12}
        xs={12}
        style={{ marginBottom: 24, padding: '0px !important' }}
        className="no-padding-select-input"
      >
        <SelectInput
          label={translations?.labelDiagnostic}
          elements={pathologyOptions ?? []}
          value={data?.diagnosticId?.recordId ?? ''}
          onSelectedValue={(value: any) =>
            handleFieldChange('diagnosticId', { recordId: value })
          }
          freeSolo={true}
          getOptionSelected={(option: any) => option?.id || null}
          id="select-diagnosticId"
          disabled={disabled}
          hasBoxStyle={true}
        />
      </GridItem>

      <GridItem xs={12} style={{ marginBottom: 24 }}>
        <ValidatedTextField
          label={t('clinicalHistory.treatmentLabel')}
          placeholder={t('clinicalHistory.treatmentDescription')}
          value={data?.treatment ?? ''}
          onChange={(value) => handleFieldChange('treatment', value)}
          type="string"
          maxLength={200}
          minRows={4}
          multiline
          variant="outlined"
          disabled={disabled}
        />
      </GridItem>
      <GridItem xs={12} style={{ marginBottom: 24 }}>
        <ValidatedTextField
          variant="outlined"
          label={t('clinicalHistory.requestedStudies')}
          placeholder={t('clinicalHistory.requestedStudiesDescription')}
          minRows={4}
          value={data?.requestedStudies ?? ''}
          onChange={(value) => handleFieldChange('requestedStudies', value)}
          type="string"
          maxLength={200}
          multiline
          disabled={disabled}
        />
      </GridItem>
    </GridContainer>
  )
}

export default Diagnostic
