import { InfoRounded } from '@material-ui/icons'
import React from 'react'
import { CustomNotificationBadgeProps } from './types'
import { colorMap } from './constants';
import useStyles from './useStyle';


export const CustomNotificationBadge = ({
  notification,
  type,
}: CustomNotificationBadgeProps) => {

  const color = colorMap[type]; 
  const styles = useStyles({ color }); 

  return (
    <div
    className={styles.container}     
    >
      <InfoRounded />
      {notification}
    </div>
  )
}
