import './Routes.css';

import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import CustomDialog from '../CustomDialog';
import CustomInput from '../CustomInput/CustomInput';
import DateInput from '../DateInput';
import FormControl from '@material-ui/core/FormControl';
import { GetGeoLocation } from '../../handlers/get-geolocation';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Itinerary } from '../../icons';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import RegularMap from './RegularMap';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateToServer = config.getDateToServer();

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routes: [],
            currentLocation: {},
            markers: [],
            directions: [],
            customers: [],
            employees: [],
            schedules: [],
            waypoints: [],
            selectedCustomer: '',
            selectedEmployee: '',
            alertOpen: false,
            alertColor: 'info',
            alertMessage: '',
            alert: null,
            loading: false,
            openDetail: false,
            waypoint: {},
        }
    }

    async getCurrentLocation() {
        const { t } = this.props;
        const { latitude, longitude, hasPermission, isBrowserUnsupported } = await GetGeoLocation();

        if (!isBrowserUnsupported) {
            return this.openAlert('danger', t('geolocation.browserUnsupported'));
        }
        if (!hasPermission) {
            return this.openAlert('warning', t('geolocation.permissionDenied'));
        }
        if (latitude && longitude) {
            this.setState({ currentLocation: { lat: latitude, lng: longitude } });
        }
    }

    componentWillMount() {
        this.getCurrentLocation();

        if (this.props.customers) {
            this.setState({ customers: this.props.customers });
        } else {
            this.getCustomers();
        }

        if (this.props.employees) {
            this.setState({ employees: this.props.employees });
        } else {
            this.getEmployees();
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.setState({ customers: next.customers });
        }

        if (next.employees) {
            this.setState({ employees: next.employees });
        }
    }

    showWaypointDetail(waypoint) {
        this.setState({ waypoint: waypoint, openDetail: true });
    }

    getCustomers() {
        PersonApiInvoker.getCustomers(false, data => {
            if (data?.length) {
                this.props.onFetchCustomers(data);
            }
        }, null);
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(data => {
            if (data?.length) {
                this.props.onFetchEmployees(data);
            }
        }, null);
    }

    getSchedules(personId, selectedDate) {
        this.setState({ loading: true });
        const date = moment(selectedDate).format(dateToServer)
        PersonApiInvoker.postEmployeeRoadmap({ personId, date }, (data) => {
            this.paintSchedules(data);
            this.setState({ loading: false });
        }, (error) => {
            console.error('** error postEmployeeRoadmap', error);
            this.setState({ loading: false });
        });
    }

    setDirection(schedules) {
        const DirectionsService = new window.google.maps.DirectionsService();
        console.log(DirectionsService);
        if (schedules.length > 0) {
            DirectionsService.route({
                origin: schedules[0].location,
                destination: schedules[schedules.length - 1].location,
                waypoints: schedules,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                console.log(status);
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({ directions: result });
                } else {
                    console.error(`** error fetching directions ${result}`)
                }
            });
        } else {
            const date = moment(this.state.selectedDate).format(dateFormat);
            const message = this.props.t('routes.messageNoShifts', { date });
            this.openAlert('danger', message);
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    paintSchedules(schedules) {
        let daySchedules = [];
        const waypoints = [];

        schedules.map(s => {
            daySchedules.push({
                location: new window.google.maps.LatLng(
                    s.address.latitude,
                    s.address.longitude
                ),
                stopover: true,
            })
            waypoints.push({
                location: new window.google.maps.LatLng(s.address.latitude, s.address.longitude),
                description: s.appointmentDescription,
                status: s.scheduleStatus,
                scheduleDateTime: moment(s.scheduleDateTime).format('hh:mm a'),
                customer: `${s.customer.lastName} ${s.customer.firstName}`,
                appointmentId: s.appointmentId,
                practiceName: s.practiceName,
                scheduleId: s.scheduleId,
                address: s.addressFull,
            });
        });

        daySchedules = _.orderBy(daySchedules, 'startDate', 'asc')
        this.setState({ waypoints });
        this.setDirection(daySchedules);
    }

    handleValueDate = (value) => {
        const date = value && moment(value).format(dateToServer);
        this.setState({ selectedDate: date });
    }

    handleFilter() {
        this.getSchedules(this.state.selectedEmployee, this.state.selectedDate);
    }

    closeModal = () => {
        this.setState({ openDetail: false });
    }

    sortEmployees(employees) {
        if (!employees?.length) {
            return [];
        }
        let sort = employees.sort((a, b) => a.lastName.localeCompare(b.lastName));
        const result = sort.map(e => ({
            id: e.personId,
            value: `${e.lastName} ${e.firstName}`,
        }));
        return result;
    }

    render() {
        const { t } = this.props;
        const {
            alert, alertColor, alertMessage, alertOpen, currentLocation, employees, directions, loading, selectedDate, selectedEmployee, waypoints
        } = this.state;
        const disabledButton = !(selectedDate && selectedEmployee);
        const optionsEmployees = this.sortEmployees(employees);
        return (
            <GridContainer className="routes">
                {alert}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <Itinerary />
                            </CardIcon>
                            <GridContainer className="routes-filters">
                                <GridItem xs={12} sm={6}>
                                    <FormControl className="routes-dropdown">
                                        <SelectInput
                                            onSelectedValue={(value) => this.setState({ selectedEmployee: value })}
                                            valueAccessor="id"
                                            elements={optionsEmployees}
                                            value={selectedEmployee}
                                            label={`${t('routes.input.professionals')} *`}
                                        >
                                        </SelectInput>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={4}>
                                    <DateInput
                                        className="date-filter"
                                        text={`${t('common.date')} *`}
                                        value={selectedDate}
                                        onChangeValue={(value) => this.handleValueDate(value)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={2}>
                                    <ComponentDisabledWithTooltip
                                        tooltipText={t('routes.buttonTooltip')}
                                        disabled={disabledButton}
                                        loading={loading}
                                        component={
                                            <ButtonSpinner
                                                id="button-filter"
                                                block
                                                disabled={disabledButton || loading}
                                                color="primary"
                                                onClick={() => this.handleFilter()}
                                                label={t('routes.input.filter')}
                                                labelLoading={t('routes.input.filtering')}
                                                loading={loading}
                                                typeButton="submit"
                                            />
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <RegularMap
                                location={currentLocation}
                                directions={directions}
                                waypoints={waypoints}
                                showWaypointDetail={this.showWaypointDetail.bind(this)}
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: '100%' }} />}
                                containerElement={
                                    <div className="regular-map" />
                                }
                                mapElement={<div style={{ height: '100%' }} />}
                            />
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />
                </GridItem>

                <CustomDialog
                    maxWidth="sm"
                    title={t('route.detail.title')}
                    open={this.state.openDetail}
                    onClose={() => this.closeModal()}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.scheduleId')}
                                value={this.state.waypoint.scheduleId}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.customerName')}
                                value={this.state.waypoint.customer}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.address')}
                                value={this.state.waypoint.address}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.time')}
                                value={this.state.waypoint.scheduleDateTime}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.practiceName')}
                                value={this.state.waypoint.practiceName}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <CustomInput
                                disabled
                                labelText={t('route.detail.scheduleStatus')}
                                value={
                                    t(`schedule.booking.state.${this.state.waypoint.status}`)}
                                formControlProps={{
                                    disabled: true,
                                    fullWidth: true,
                                }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} className="filters-actions-appointments">
                            <p className="label-link">
                                {t('route.detail.appointmentId')}
                            </p>
                            <p className="value-link">
                                <Button simple color="transparent" onClick={() => {
                                    browserHistory.push({
                                    state: { mode: 'view', action: "finished_system" },
                                    pathname: `/solicitudes/${this.state.waypoint.appointmentId}`,
                                    });
                                }}>{this.state.waypoint.appointmentId}</Button>
                            </p>
                        </GridItem>
                    </GridContainer>
                </CustomDialog>
            </GridContainer>
        )
    }
}

Routes.propTypes = {
    t: PropTypes.func,
    onFetchCustomers: PropTypes.func.isRequired,
    onFetchEmployees: PropTypes.func.isRequired,
    customers: PropTypes.array,
    employees: PropTypes.array,
}

export default withTranslation()(Routes);
