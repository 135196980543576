import { Add, Assignment } from '@material-ui/icons';
import { Grid, Tooltip } from '@material-ui/core';

import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip';
import DateInput from '../DateInput';
import React from 'react';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import ValidationInput from '../ValidationInput';
import { useHospitalizationList } from './hooks/useHospitalizationList';
import { useTranslation } from 'react-i18next';

const HospitalizationList = () => {
  const { t } = useTranslation();

  const {filterSearchClear,filterSearch,setEgressDate, data,loading,filterable, egressDate,setAdmissionDate,admissionDate,setState, state, optionState,setAxaId,axaId, colorSnackBar, messageSnackBar, showSnackBar, setAxaInternmentId,axaInternmentId,addInternment} = useHospitalizationList()

  return (
    <Grid container className="appointments appointments-list">
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <Grid item xs={12}>
        <Card>
          <CardHeader
            icon
            className="filters-header"
            style={{ paddingBottom: 20 }}
          >
            <div className="header-internment-table">
              <div className="header-icon">
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </div>
              <div className="header-buttons">
                <Tooltip title={t('appointment.newHospitalization.title')}>
                  <span>
                    <Button
                      className="button-new-int"
                      round
                      color="primary"
                      authority="hospitalization_new"
                      onClick={addInternment}
                    >
                      <Add className="appointments-button-icon" />{' '}
                      {t('appointment.newHospitalization.title')}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {
              <Grid container className="appointments-filters">
                <Grid
                  container
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  className="appointments-filters-row"
                >
                  <Grid container item xs={12} className="filters-row">
                    <Grid container item xs={12} sm={3}>
                      <ValidationInput
                        className="filter-full no-padding"
                        text={t('appointment.hospitalization.number')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="number"
                        value={axaInternmentId}
                        onChangeValue={(e) => setAxaInternmentId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ValidationInput
                        className="filter-full no-padding"
                        text={t('common.beneficiaryNumber')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={axaId}
                        onChangeValue={(e) => setAxaId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        label={t('status')}
                        elements={optionState}
                        value={state}
                        onSelectedValue={(value) => setState(value)}
                      />
                    </Grid>

                    <Grid container item xs={12} sm={12}>
                      <Grid
                        container
                        item
                        xs={6}
                        className="date-container startDate"
                      >
                        <DateInput
                          text={t('common.admissionDate')}
                          value={admissionDate}
                          onChangeValue={(e) => {
                            const onlyDate = e.substring(0, 10); 
                            setAdmissionDate(onlyDate);
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        className="date-container endDate"
                      >
                        <DateInput
                          text={t('clinicalHistory.dischargeDate')}
                          value={egressDate}
                          onChangeValue={(e) => {const onlyDate = e.substring(0, 10); setEgressDate(onlyDate)}}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  className="filters-actions-appointments"
                >
                  <Grid
                    container
                    item
                    className="filters-row"
                  >
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={t('dashboard.filters.search')}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            onClick={filterSearch}
                            label={t('dashboard.filters.search')}
                            labelLoading={t('dashboard.filters.search')}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button block onClick={filterSearchClear} color="danger">
                        {t('dashboard.filters.clean')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </CardHeader>

          <CardBody className="appointments-content-body not-scroll-table filtrable-table">
            <Table
              filterable={filterable}
              striped
              loading={loading}
              tableHeaderColor="primary"
              sortable
              tableHead={[
                {
                  Header: t('appointment.hospitalization.number'),
                  accessor: 'axa_internment_id',
                  width: 130,
                },
                {
                  Header: t('common.beneficiaryNumber'),
                  accessor: 'axa_id',
                },
                {
                  Header: t('common.admissionDate'),
                  accessor: 'admission_date',
                },
                {
                  Header: t('common.patient'),
                  accessor: 'patient',
                },
                { Header: t('clinicalHistory.dischargeDate'), accessor: 'egress_date' },
                { Header: t('status'), accessor: 'state' },
                {
                  Header: t('actions'),
                  accessor: 'actions',
                  width: 200,
                  sortable: false,
                  filterable: false,
                },
              ]}
              tableData={data}
              colorsColls={['primary']}
              className="-striped -highlight filtrable sticky"
              defaultPageSize={data.length <= 25 ? 25 : data.length}
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default HospitalizationList;
