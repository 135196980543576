import { useMemo } from 'react'


type InternmentKatzDataType = { dress: number | null,
    bathing: number | null,
    usingTheToilet: number | null,
    mobility: number | null,
    continence: number | null,
    feeding: number | null}

export const useHospitalizationUtils = ({
  bath,
  dressed,
  toilet,
  mobility,
  continence,
  feeding,
}: any) => {

  const internmentKatz = useMemo(
    () => ({
      bath: bath === 2 ? 0 : 1,
      dressed: dressed === 2 ? 0 : 1,
      toilet: toilet === 2 ? 0 : 1,
      mobility: mobility === 2 ? 0 : 1,
      continence: continence === 2 ? 0 : 1,
      feeding: feeding === 2 ? 0 : 1,
    }),

    [bath, dressed, toilet, mobility, continence, feeding]
  )

  const generateEditedData = (data:{internmentKatz:InternmentKatzDataType}) => {   
    let baseData: InternmentKatzDataType =
     { dress: null,
      bathing: null,
      usingTheToilet: null,
      mobility: null,
      continence: null,
      feeding: null}

    if (data?.internmentKatz) {
      Object.entries(baseData).forEach(([key]) => {
        const typedKey = key as keyof InternmentKatzDataType;
        if(data.internmentKatz[typedKey] === 0){
          baseData = {...baseData, [typedKey]: 2}
        }else if(data.internmentKatz[typedKey] === 1){
          baseData = {...baseData, [typedKey]: 1}
        }else{
          baseData = {...baseData, [typedKey]: null}
        }
      })
    }
    return baseData   

  
}

  return {internmentKatz, generateEditedData}
}
