export interface ClinicalHistoryData {
  patientClinicalHistoryId?: number;
  customer?: any
  registerDate?: string;
  temperature?: number | null;
  pulse?: number | null;
  oxigenSaturation?: number | null;
  bloodPressureMin?: number | null;
  bloodPressureMax?: number | null;
  unitOfMeasurement?: string | null;
  valueGlucose?: number | null;
  clasification?: string | null;
  weightUnit?: string  | null;
  weightValue?: number | null;
  breatheRate?: number | null;
  stress?: number | null;
  hrvsdnn?: number | null;
  scheduleId?: number;
  source?: string;
  heightValue?: number | null;
  imc?: number | string  | null;
  glycemiaId?: LaboratoryResult | null;
  glycosylatedHemoglobinId?: LaboratoryResult | null;
  cholesterolId?: LaboratoryResult | null;
  triglyceridesId?: LaboratoryResult | null;
  diagnosticId?: Diagnostic | null;
  treatment?: string | null;
  requestedStudies?: string | null;
  nextConsultationDate?: string  | null;
  dischargeDate?: string  | null;
  specialty1?: Specialty  | null;
  specialty2?: Specialty  | null;
  specialty3?: Specialty  | null;
  others?: string | null;
  observations?: string  | null;
}

interface LaboratoryResult {
  laboratoryResultsId: number;
  typeStudy: string;
  description: string;
}

interface Specialty {
  companyId: number;
  companyPracticeTypeId: number;
  practiceType: number;
  practiceTypeGroup: number;
  practiceTypeCode: string;
  practiceTypeName: string;
  active: boolean;
}

export interface Medicine {
  scheduleMedicineId: number;
  medicineType?: MedicineType | null;
  medicineUnitType?: MedicineUnitType | null;
  medicinePresentationType?: MedicinePresentationType | null;
  medicineQuantity?: number | null;
  medicineDose?: number | null;
  medicineViaType?: MedicineViaType | null;
  medicineLotNumber?: string;
  medicineExpireDate?: string;
  medicineDateTime?: string;
}


export interface RootClinicalHistory {
  patientClinicalHistory: ClinicalHistoryData;
  medicineList: Medicine[];
}
  
  export interface OptionSelectors {
    id: number;
    value: string;
    label: string;
  }

  export interface MedicineType {
    medicineTypeId?: number | null;
    companyId?: number | null;
    name: string | null;
  }
  
  export interface MedicineViaType {
    medicineViaTypeId?: number | null;
    companyId?: number;
    name: string;
  }
  
  export interface MedicinePresentationType {
    medicinePresentationTypeId: number | null;
    companyId?: number;
    name: string;
  }
  
  export interface MedicineUnitType {
    medicineUnitTypeId: number |null;
    companyId?: number;
    name: string;
  }
  
  export interface CapillaryGlucoseUnitType {
    capillaryGlucoseUnitTypeId: number;
    companyId: number;
    name: string;
    minimumValue: number;
    maximumValue: number;
    valueNumberDecimal: boolean;
  }
  
  export interface MedicineDataResponse {
    medicineType: MedicineType[];
    medicineViaType: MedicineViaType[];
    medicinePresentationType: MedicinePresentationType[];
    medicineUnitType: MedicineUnitType[];
    capillaryGlucoseUnitType: CapillaryGlucoseUnitType[];
  }
  
  export enum ActionTypes {
    CREATE = "create",
    VIEW = "view"
  }

  interface Diagnostic {
    companyId?: number;
    recordId?: number;
    recordCategoryId?: number;
    recordCode?: string;
    name?: string;
    metadata?: any | null;
    parent?: any; 
  }

  export interface PreloadData {
    scheduleId?: number,
    employeeId: number,
    customerId: number,
    weightValue?: number | null;
    weightUnit?: string  | null;
    pulse?: number | null;
    bloodPressureMin?: number | null;
    bloodPressureMax?: number | null;
    diagnosticId?: Diagnostic | null;
    medicineList: Medicine[]
  }