/* eslint-disable no-unused-expressions */
import './DiabeticProgramStyles.css'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import FormComplementaryStudies from './Forms/ComplementaryStudies/FormComplementaryStudies'
import FormFinancial from '../FormFinancial/FormFinancial'
import FormPatientData from './Forms/PatientData/FormPatientData'
import FormPatients from '../FormPatients'
import FormPhysicalExam from './Forms/PhysicalExam/FormPhysicalExam'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import RegularButton from '../CustomButtons/Button.jsx'
import Snackbar from '../Snackbar/Snackbar'
import { useDiabeticProgramForm } from './hooks/useDiabeticProgramForm'

const DiabeticProgramForm = (props) => {
  const {loading, onSubmit, goToAdmissionList, complementaryStudiesEdit, setValidateComplementaryStudiesForm, setDataComplementaryStudies, physicalExamEdit, setValidatePhysicalExamForm, setDataPhysicalExam, patientDataEdit, setValidateFormPatientData, setDataPatientPhysical, setPersonId, personId, setIsValidatedPatientForm,setDataPatientForm,showSnackBar,messageSnackBar,colorSnackBar, setIsValidatedFinanceForm, setDataFinanceForm, save, dataPatientEdit, readOnly} = useDiabeticProgramForm(props)


  return (
    <form>
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <FormPatients
            setDataPatientForm={setDataPatientForm}
            setIsValidatedPatientForm={setIsValidatedPatientForm}
            setPersonId={setPersonId}
            editData={dataPatientEdit}
            save={save}
            onlyView={readOnly}
          ></FormPatients>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormFinancial
            setDataFinanceForm={setDataFinanceForm}
            setIsValidatedFinanceForm={setIsValidatedFinanceForm}
            personId={personId}
            save={save}
            onlyView={readOnly}
            type={"Diabetic"}
          ></FormFinancial>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormPatientData
            setDataPatient={setDataPatientPhysical}
            save={save}
            validateFormPatientData={setValidateFormPatientData}
            editData={patientDataEdit}
            onlyView={readOnly}
          ></FormPatientData>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormPhysicalExam
            setDataPhysicalExam={setDataPhysicalExam}
            save={save}
            setvalidatePhysicalExamForm={setValidatePhysicalExamForm}
            onlyView={readOnly}
            editData={physicalExamEdit}
          ></FormPhysicalExam>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormComplementaryStudies
            setDataComplementaryStudies={setDataComplementaryStudies}
            save={save}
            setValidateComplementaryStudiesForm={
              setValidateComplementaryStudiesForm
            }
            onlyView={readOnly}
            editData={complementaryStudiesEdit}
          ></FormComplementaryStudies>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={12}>
        <div className="buttons-container">
          <div>
            <RegularButton onClick={goToAdmissionList}>CANCELAR</RegularButton>
          </div>
          <div className="button-submit-container">
            {!readOnly && (
              <RegularButton
                authority="diabetic_new"
                onClick={onSubmit}
                color="info"
              >
                {loading ? (
                  <>
                    <CircularProgress size={18} />
                    <span>GUARDANDO</span>
                  </>
                ) : (
                  <span>GUARDAR CAMBIOS</span>
                )}
              </RegularButton>
            )}
          </div>
        </div>
      </GridContainer>
    </form>
  )
}

export default DiabeticProgramForm
