import {
  CustomerFinancier,
  DeleteCustomerFinancierBadRequestError,
  DeleteCustomerFinancierConnectionError,
  DeleteCustomerFinancierError,
  DeleteCustomerFinancierNotFoundError,
  DeleteCustomerFinancierUsecase,
  GetCustomerFinanciersConnectionError,
  GetCustomerFinanciersError,
  GetCustomerFinanciersNotFoundError,
  GetCustomerFinanciersUsecase,
} from '../../../core'
import { Delete, Edit } from '@material-ui/icons'
import { FormAction, SnackbarColor } from '../../../util'
import React, { useEffect, useState } from 'react'

import Button from '../../CustomButtons/Button'
import { DIContainer } from '../../../Root'
import { Tooltip } from '@material-ui/core'
import config from '../../../config/config'
import { initialValueSnackbar } from '../../../util/constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface CustomerFinancierData extends CustomerFinancier {
  actions?: JSX.Element;
  activeText: string;
  dueDateFormat?: string;
  homeCareDateFormat?: string;
}
const dateFormat = config.getDateFormat();

export const useFinanciers = (personId: number, componentMode: string) => {
  const { t } = useTranslation();

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [financiers, setFinanciers] = useState<CustomerFinancier[] | []>([]);
  const [financier, setFinancier] = useState<CustomerFinancier | undefined>(undefined);
  const [selectedFinancier, setSelectedFinancier] = useState<number | null>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formAction, setFormAction] = useState<FormAction>(FormAction.CREATE);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [snackbar, setSnackbar] = useState(initialValueSnackbar);

  const getUsecase = DIContainer.get<GetCustomerFinanciersUsecase>(
    GetCustomerFinanciersUsecase
  );

  const deleteUsecase = DIContainer.get<DeleteCustomerFinancierUsecase>(
    DeleteCustomerFinancierUsecase
  );

  useEffect(() => {
    getFinanciers();
    return () => {
      setFinanciers([]);
    }
  }, [personId])

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const getFinanciersErrors = (dataError: GetCustomerFinanciersError): void => {
    let message = t('common.errorService');
    if (dataError instanceof GetCustomerFinanciersNotFoundError) {
      message = t('financers.customer.notFoundMessage');
    }
    if (dataError instanceof GetCustomerFinanciersConnectionError) {
      message = t('confirm.error');
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const getFinanciers = async () => {
    if (!personId) {
      return;
    }
    setLoading(true)
    const result = await getUsecase.execute(personId);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      getFinanciersErrors(dataError);
      setLoading(false);
      return;
    }

    const resultData = result.getRight();
    const financersData = formatFinanciers(resultData);
    setFinanciers(financersData);
    setLoading(false);
  }
  const formatFinanciers = (
    data: CustomerFinancier[]
  ): CustomerFinancierData[] => {
    return data.map((f) => buildFinancier(f));
  }

  const onCreateFinancier = () => {
    setFormAction(FormAction.CREATE);
    setOpenForm(true);
  }

  const onEditFinancier = (financier: CustomerFinancier) => {
    setOpenForm(true);
    setFormAction(FormAction.EDIT);
    setFinancier(financier);
  }

  const deleteFinancier = (financierId: number) => {
    setDeleteAlert(true);
    setSelectedFinancier(financierId);
  }

  const rebuildFinanciersOnDelete = (financierId: number) => {
    const updatelist = financiers.filter((f) => f.id !== financierId);
    setSelectedFinancier(null);
    setFinanciers(updatelist);
  }

  const rebuildFinanciersOnUpdate = (financerData: CustomerFinancier) => {
    let updatelist = [...financiers];
    const index = updatelist.findIndex((f) => f.id === financerData.id);
    const isExist = index !== -1;
    if (isExist) {
      updatelist[index] = financerData;
    } else {
      updatelist = [...updatelist, financerData];
    }
    setFinancier(undefined);
    setFinanciers(updatelist);
  }

  const getDeleteErrors = (dataError: DeleteCustomerFinancierError): void => {
    let message = t('common.errorService');
    if (dataError instanceof DeleteCustomerFinancierNotFoundError) {
      message = t('financers.customer.notFoundMessage');
    }
    if (dataError instanceof DeleteCustomerFinancierConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof DeleteCustomerFinancierBadRequestError) {
      message = dataError.error;
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const onDeleteFinancier = async () => {
    if (!selectedFinancier) {
      return;
    }
    const result = await deleteUsecase.execute(personId, selectedFinancier);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      getDeleteErrors(dataError);
      setDeleteAlert(false);
      setLoading(false);
      return;
    }
    result.getRight();
    rebuildFinanciersOnDelete(selectedFinancier);
    setSuccess(true);
    setLoading(false);
  }

  const closeAlert = () => {
    setSnackbar(initialValueSnackbar);
    setAlertMessage('');
    setSuccess(false);
    setDeleteAlert(false);
  }

  const getHeaderTable = () => {
    const headerTable = [
      { Header: t('common.financier'), accessor: 'name' },
      { Header: t('affiliate.number'), accessor: 'affiliateId' },
      { Header: t('common.dueDate'), accessor: 'dueDateFormat' },
      { Header: t('status'), accessor: 'activeText' },
      { Header: t('common.homecare.entry.date'), accessor: 'homeCareDateFormat' },
    ];
    if (componentMode !== 'view') {
      headerTable.push({
        Header: t('actions'),
        accessor: 'actions',
      });
    }
    return headerTable;
  };

  const tableData = {
    loading,
    pageSizeOptions: [5, 10, 25, 50, 100],
    defaultPageSize: 5,
    colorsColls: ['primary'],
    sortable: true,
    tableHeaderColor: 'primary',
    showPaginationTop: false,
    showPaginationBottom: true,
    tableHead: getHeaderTable(),
    tableData: financiers,
  };

  const title = t('customers.new.financers')
  const buildFinancier = (data: CustomerFinancier): CustomerFinancierData => {
    const customerFinancier: CustomerFinancierData = {
      ...data,
      activeText: data.isActive ? t('active') : t('inactive'),
      dueDateFormat: data.dueDate
        ? moment(data.dueDate).format(dateFormat)
        : '-',
      homeCareDateFormat: data.homeCareEntryDate
        ? moment(data.homeCareEntryDate).format(dateFormat)
        : '-',
    };

    if (componentMode !== 'view') {
      const titleEdit = t('common.edit');
      const titleDelete = t('remove');
      customerFinancier.actions = (
        <>
          <Tooltip className="tooltip-edit" title={titleEdit}>
            <span>
              <Button
                id={`button-edit-${data.id}`}
                simple
                justIcon
                color="success"
                onClick={() => onEditFinancier(data)}
              >
                <Edit />
              </Button>
            </span>
          </Tooltip>
          <Tooltip className="tooltip-delete" title={titleDelete}>
            <span>
              <Button
                id={`button-delete-${data.id}`}
                simple
                justIcon
                color="danger"
                onClick={() => deleteFinancier(data.id)}
              >
                <Delete />
              </Button>
            </span>
          </Tooltip>
        </>
      );
    }
    return customerFinancier;
  }
  return {
    t,
    openSnackbar,
    rebuildFinanciersOnUpdate,
    buildFinancier,
    onCreateFinancier,
    closeAlert,
    onDeleteFinancier,
    setOpenForm,
    title,
    tableData,
    deleteAlert,
    success,
    alertMessage,
    openForm,
    formAction,
    financier,
    snackbar,
  }
}
