import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'
import { DIContainer } from '../../../../Root'
import { ReasonRejected } from '../../../../core/reasons-rejected/domain/rejected-reason'
import {
  GetReasonRejectedError,
  GetReasonsRejectedConnectionError,
  GetReasonsRejectedUsecase,
} from '../../../../core/reasons-rejected/application/usecases/get-reasons-rejected.usecase'
import { SnackbarColor } from '.'

const useStyles = makeStyles(() => ({
  loading: { padding: '1.5rem 0' },
  rejectedReasonsTitle: { marginTop: 20 },
  reasonsRejected: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: 'calc(100% - 2rem)',
    '& .MuiFormLabel-root': {
      fontSize: '.875rem',
      color: 'rgba(0, 0, 0, 0.54) !important',
      paddingLeft: 20,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
    },
  },
}))

interface ReasonsRejectedOptionsProps {
  reasonRejectedId: number | null
  setReasonRejectedId: (reasonRejectedId: number) => void
  reasonRejectedLabel: string | null
  setReasonRejectedLabel: (reasonRejectedId: string) => void
  openSnackbar: (color: SnackbarColor, message: string) => void
}

const ReasonsRejectedOption = ({
  reasonRejectedId,
  setReasonRejectedId,
  reasonRejectedLabel,
  setReasonRejectedLabel,
  openSnackbar,
}: ReasonsRejectedOptionsProps) => {
  const getUsecase = DIContainer.get<GetReasonsRejectedUsecase>(
    GetReasonsRejectedUsecase
  )

  const { t } = useTranslation()
  const classes = useStyles()

  const [reasonsRejected, setReasonsRejected] = useState<ReasonRejected[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getDataError = async (
    dataError: GetReasonRejectedError
  ): Promise<void> => {
    let message = t('common.errorService')
    if (dataError instanceof GetReasonsRejectedConnectionError) {
      message = t('confirm.error')
    }
    return openSnackbar(SnackbarColor.danger, message)
  }

  const getRejectedReason = async (): Promise<void> => {
    setLoading(true)
    const result = await getUsecase.execute()

    if (result.isLeft()) {
      const dataError = result.getLeft()
      await getDataError(dataError)
      setLoading(false)
      return
    }

    const reasons = result.getRight()
    const reasonsActive = Array.isArray(reasons)
      ? reasons.filter((r) => r.isActive)
      : []
    setReasonsRejected(reasonsActive)
    setLoading(false)
  }

  useEffect(() => {
    getRejectedReason()
    return () => {
      setReasonsRejected([])
      setLoading(false)
    }
  }, [])

  const handleSelectChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedReason = reasonsRejected.find((r) => r.id === selectedId)

    if (selectedReason) {
      setReasonRejectedId(selectedId)
      setReasonRejectedLabel(selectedReason.detail)
    }
  }

  const menuItems = reasonsRejected.map((r) => (
    <MenuItem key={r.id} value={r.id}>
      {r.detail}
    </MenuItem>
  ))

  const selectLabel = t('appointment.new.schedule.selected')
  const titleComp = t('rejectedReason.title')

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress className="circular-progress" />
    </div>
  ) : (
    reasonsRejected && (
      <>
        <p className={classes.rejectedReasonsTitle}>{titleComp}:</p>
        <FormControl fullWidth className={classes.reasonsRejected}>
          <InputLabel htmlFor="reasons-select">{selectLabel}</InputLabel>
          <Select
            id="reasons-select"
            labelId="reasons-select"
            label={selectLabel}
            value={reasonRejectedId ?? ''}
            onChange={handleSelectChange}
            inputProps={{
              name: 'reasons-select',
              id: 'reasons-select',
            }}
          >
            {menuItems}
          </Select>
        </FormControl>
      </>
    )
  )
}

ReasonsRejectedOption.defaultProps = {
  reasonRejectedId: null,
}

ReasonsRejectedOption.propTypes = {
  reasonRejectedId: PropTypes.number,
  setReasonRejectedId: PropTypes.func.isRequired,
  setReasonRejectedLabel: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
}

export default ReasonsRejectedOption
