import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import ButtonSpinner from '../../ButtonSpinner/ButtonSpinner';
import { Card, CardBody, CardHeader, CardIcon } from '../../Card';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip';
import Button from '../../CustomButtons/Button';
import DateInput from '../../DateInput';
import SelectInputDebounced from '../SelectInputDebounced';
import Snackbar from '../../Snackbar/Snackbar';
import { useDoneAppointmentsReport, useStylesDoneAppoReport } from './useDoneAppointmentsReport';

const DoneAppointmentsReport = (props) => {
  const { t } = props;
  const classes = useStylesDoneAppoReport();
  const {
    filters,
    options,
    loading,
    disabled,
    alertBar,
    refStartDate, refEndDate, refCustomerId, refEmployeeId, refPracticeId, refSpecialityId, refFinancerId,
    handleValue,
    handleClearOptions,
    getCustomerstAPI,
    getEmployeesAPI,
    getPracticeTypesAPI,
    getSpecialitiesAPI,
    getFinancersAPI,
    handleReport,
    cleanSearch,
  } = useDoneAppointmentsReport(t);
  
  return (
    <Grid container id="done-appointments-report" className="appointments-list">
      <Grid item xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <PollOutlinedIcon />
            </CardIcon>
            <h4>{t('routes.doneAppointmentsReport')}</h4>
          </CardHeader>
          <CardBody className={classes.appoReportContent}>
            <Snackbar
              color={alertBar.type}
              message={alertBar.message}
              open={alertBar.open}
              place="tr"
            />
            <Grid container className={classes.appointmentsReport}>
              <Grid container item xs={12} sm={9} md={9} lg={10} className="filters-row">
                <Grid container item xs={12}>
                  <Grid container item xs={12} sm={12} md={6} lg={4}>
                    <Grid item xs={6} className="no-padding">
                      <DateInput
                        id="startDate"
                        ref={refStartDate}
                        text={t('dashboard.filters.from')}
                        value={filters.startDate}
                        onChangeValue={(value) => handleValue(value, 'startDate')}
                      />
                    </Grid>
                    <Grid item xs={6} className="no-padding">
                      <DateInput
                        id="endDate"
                        ref={refEndDate}
                        text={t('dashboard.filters.to')}
                        value={filters.endDate}
                        onChangeValue={(value) => handleValue(value, 'endDate')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <SelectInputDebounced
                      id="customerId"
                      handleValue={(value) => handleValue(value, 'customerId')}
                      handleClearOptions={() => handleClearOptions('customers')}
                      getOptionsAPI={(text) => getCustomerstAPI(text)}
                      data={{
                        ref: refCustomerId,
                        label: t('common.patient'),
                        filters,
                        value: filters.customerId,
                        options: options.customers,
                        field: 'customerId',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <SelectInputDebounced
                      id="employeeId"
                      handleValue={(value) => handleValue(value, 'employeeId')}
                      handleClearOptions={() => handleClearOptions('employees')}
                      getOptionsAPI={(text) => getEmployeesAPI(text)}
                      data={{
                        ref: refEmployeeId,
                        label: t('common.professional'),
                        filters,
                        value: filters.employeeId,
                        options: options.employees,
                        field: 'employeeId',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <SelectInputDebounced
                      id="practiceId"
                      handleValue={(value) => handleValue(value, 'practiceId')}
                      handleClearOptions={() => handleClearOptions('practices')}
                      getOptionsAPI={(text) => getPracticeTypesAPI(text)}
                      data={{
                        ref: refPracticeId,
                        label: t('label.practice'),
                        filters,
                        value: filters.practiceId,
                        options: options.practices,
                        field: 'practiceId',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <SelectInputDebounced
                      id="specialityId"
                      handleValue={(value) => handleValue(value, 'specialityId')}
                      handleClearOptions={() => handleClearOptions('specialities')}
                      getOptionsAPI={(text) => getSpecialitiesAPI(text)}
                      data={{
                        ref: refSpecialityId,
                        label: t('common.speciality'),
                        filters,
                        value: filters.specialityId,
                        options: options.specialities,
                        field: 'specialityId',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <SelectInputDebounced
                      id="financerId"
                      handleValue={(value) => handleValue(value, 'financerId')}
                      handleClearOptions={() => handleClearOptions('financers')}
                      getOptionsAPI={(text) => getFinancersAPI(text)}
                      data={{
                        ref: refFinancerId,
                        label: t('label.financer'),
                        filters,
                        value: filters.financerId,
                        options: options.financers,
                        field: 'financerId',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={3} md={3} lg={2} className="filters-actions">
                <Grid container item justifyContent="flex-end" className="filters-row">
                  <Grid item className="filters-actions" xs={12}>
                    <ComponentDisabledWithTooltip
                      tooltipText={t('generateReport.requiredFields')}
                      disabled={disabled}
                      loading={false}
                      component={
                        <ButtonSpinner
                          id="button-search"
                          onClick={() => handleReport()}
                          label={t('label.generateReport')}
                          labelLoading={t('label.generatingReport')}
                          loading={loading}
                          disabled={disabled || loading}
                          typeButton="submit"
                          color="primary"
                          block
                        />
                      }
                    />
                  </Grid>
                  <Grid item className="filters-actions" xs={12}>
                    <Button id="button-clear" block onClick={() => cleanSearch()} color="danger">
                      {t('appointment.find.clean')}
                    </Button>
                  </Grid> 
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}
DoneAppointmentsReport.propTypes = {
  t: PropTypes.func,
}


export default withTranslation()(DoneAppointmentsReport);
