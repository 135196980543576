export interface ProfileDto {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  companies: CompanyProfile[];
  companyPartnershipId?: number | null;
  companyCurrencyId: number;
  isPatient: boolean;
  isProfessional: boolean;
  requiereTermsAccept: boolean;
  requestTermsVersion: string;
  isActive: boolean;
  appRelaseVersion: string;
  appRelaseDate: string;
}

export interface CompanyProfile {
  authorities: string;
  companyId: number;
  name: string;
  companyPartnership_id: number,
  companyPartnership_name: string;
}

export class Profile {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  companies: CompanyProfile[];
  companyPartnershipId?: number | null;
  companyCurrencyId: number;
  isPatient: boolean;
  isProfessional: boolean;
  requiereTermsAccept: boolean;
  requestTermsVersion: string;
  isActive: boolean;
  appRelaseVersion: string;
  appRelaseDate: string;

  constructor({ userId, firstName, lastName, email, requiereTermsAccept, requestTermsVersion, isActive, companies, companyCurrencyId, companyPartnershipId, isPatient, isProfessional, appRelaseVersion, appRelaseDate }: ProfileDto) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.companies = companies;
    this.companyPartnershipId = companyPartnershipId;
    this.companyCurrencyId = companyCurrencyId;
    this.isPatient = isPatient;
    this.isProfessional = isProfessional;
    this.requiereTermsAccept = requiereTermsAccept;
    this.requestTermsVersion = requestTermsVersion;
    this.isActive = isActive;
    this.appRelaseVersion = appRelaseVersion;
    this.appRelaseDate = appRelaseDate;
  }
}
