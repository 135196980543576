import '../NewSchedule.css';

import { Checkbox, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import _, { isArray } from 'lodash';

import Add from '@material-ui/icons/Add';
import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Authority from '../../../util/Authority';
import Button from '../../CustomButtons/Button';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import { Check } from '@material-ui/icons';
import ConfirmAlert from '../../ConfirmAlert';
import EmployeeApiInvoker from '../../../api/EmployeeApiInvoker';
import FormHelperText from '@material-ui/core/FormHelperText';
import GridItem from '../../Grid/GridItem';
import LabelWidthInfo from '../../LabelWithInfo';
import PropTypes from 'prop-types';
import ScheduleDetailModal from './ScheduleDetailModal';
import ScheduleItemActions from './ScheduleItemActions/ScheduleItemActions';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import ViewList from '@material-ui/icons/ViewList';
import config from '../../../config/config';
import moment from 'moment';
import { useQuantityRequests as setQuantityRequests } from '../../../hooks/useQuantityRequests';
import styles from '../../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
class ScheduleDetail extends Component {
  constructor(props) {
    super(props)

    const { 
      t 
    } = this.props;

    this.state = {
      open: false,
      loading: false,
      dataTable: [],
      appointmentSchedules: [],
      modules: [],
      plans: [],
      deleteAlert: false,
      deleteAlertText: t('Package'),
      remove: '',
      error: false,
      current: null,
      id: 1,
      action: '',
      thirdParties: [],
      alert: {
        color: 'warning',
        message: '',
        open: false,
      },
      isPatient: false,
      selectedItem:[],
      selectedNames: [],
      file:{}
    }
  }

  componentWillReceiveProps(next) {
    const { 
      modules, 
      practiceTypes, 
      viewMode 
    } = this.props;

    if (next.practiceTypes || next.modules) {
      this.formatDataTable(next.practiceTypes || practiceTypes, next.modules || modules);
    }
    if (next.value?.length && !this.state.setAction) {
      this.formatValue(next.value);
    }
    if (next.dates) {
      this.setState({ dates: next.dates })
      this.formatDataTable(practiceTypes, modules);
    }
    if (next.items) {
      if (viewMode === 'copy') {
        this.setState({ appointmentSchedules: next.items, loading: false },
          () => {
            this.formatDataTable(practiceTypes, modules);
          });
      }
    }
  }

  getPartner = () => {
		this.setState({ thirdParties: [] });
		AppointmentApiInvoker.getCompanyPartnershipAll(data => {
			this.setState({ thirdParties: data });
		}, (e) => {
			this.setState({ thirdParties: [] });
		});
	}

  componentDidMount() {
    const { appointmentId, provider } = this.props;
    if (provider?.applicationClient) {
      const isPatient = provider.applicationClient  === 'appmobilepatient';
      this.setState({ isPatient });
    }
 
    this.getPartner();
    if (appointmentId) { this.onAppointment();}
  }

  onAppointment = () => {
    const { appointmentId, modules, practiceTypes, setProvider, viewMode, type } = this.props;
    this.getPracticeTypes();
    this.getModuleTypes();
    this.setState({ loading: true });
    if (viewMode !== 'copy') {
      
      if(type !== "transfer"){
        
        AppointmentApiInvoker.getAppointmentItems(appointmentId, async(res) => {
          await res.map(item =>{
            AppointmentApiInvoker.getFile(item.appointmentItemRequestId, (data) => {
              if(data.length>0){
                item.file = data;
              }
            });
          })
          setProvider(res ? res[0].serviceProvider : {});
          this.setState({ appointmentSchedules: res, loading: false }, () => {
            this.formatDataTable(practiceTypes, modules, true);
          });
        }, (error) => {
          console.error('** error getAppointmentItems', error);
        });
      }else{
        AppointmentApiInvoker.getAppointmentById(appointmentId, (res) => {
          setProvider(res.items ? res.items[0].serviceProvider : {});
          this.setState({ appointmentSchedules: res, loading: false }, () => {
            this.formatDataTable(practiceTypes, modules, true);
          });
        }, (error) => {
          console.error('** error getAppointmentItems', error);
        });
      }
    }
  }

  showInfoUser = (companyId) => {
    const { isHeader, companyPartnerShip } = this.getCompanyPartnership(); 
    const isNotHeader = companyId == companyPartnerShip;
    return isHeader || isNotHeader;
  }

  isAvailableRejected = (data) => {
    if (!data.rejectedDetail) {
      return false;
    }
    return this.showInfoUser(data.companyIdPartnership.companyId);
  }

  isAvailableObservations = (data) => {
    
    const isModule = data.packageId;
    if (isModule) {
      let v = data.itemRequestPackageDetails.filter(f => f.observations != null && f.observations.length > 0).length > 0;
      return v;
    }
    // manuel || (data.file != null && data.file.name != null)
    // se quita porque no se muestra luego en el popup
    return (data.observations != null) && this.showInfoUser(data.companyIdPartnership.companyId) ;
  }

  getPMObservations = (data) => {
    const isModule = data.packageId;
    if (isModule) {
      
      const observationsModule = data.itemRequestPackageDetails.filter(f => f.observations || f.file).map(m => ({ key: m.appointmentItemRequestPackageDetailId, label: m.observations, practice: m.practiceName }));
      if(data.file){
        const observationsModule = data.itemRequestPackageDetails.map(m => ({ key: m.appointmentItemRequestPackageDetailId, label: m.observations, practice: m.practiceName }));
        observationsModule[0].fileName = data.file?.[0]?.name || null;
        observationsModule[0].file = data.file?.[0]?.file || null;
        return observationsModule
      }
      
      else if (!observationsModule.length) {
        return [];
      }
      
      return observationsModule;
    }
    return [{ key: data.appointmentItemRequestId, label: data.observations, fileName:data.file?.[0]?.name, file:data.file?.[0]?.file }];
  }

  markAction = (value, index, specialty) => {
    let selected = this.state.selectedItem;
    let selectedNames = this.state.selectedNames;
    if (value === false) {
      const idx = selected.indexOf(index);
      if (idx !== -1) {
        selected.splice(idx, 1);
        selectedNames.splice(idx, 1);
      }
    } else {
      selected.push(index);
      selectedNames.push(specialty);
    }
    this.props.onSelectedItems(selected, selectedNames);
  }

  dataBuildPlanModule(r, startDate, lenderName, index, specialty, type) {
    const { t } = this.props;
    const dataInfoObs = {
      titleModal: t('modal.observations.title', { name: specialty }), 
      label: type,
      tooltip: t('view.observations'),
      observations: this.getPMObservations(r),
    };

    const isObservations = this.isAvailableObservations(r);
    const name = <LabelWidthInfo showInfo={isObservations} data={dataInfoObs} />;

    const iStatus = r.appointmentItemRequestStatus ?? 'CREATED';
    const isRejectedReason = this.isAvailableRejected(r);
    const statusText = t(`status.appointmentItemRequest.${iStatus}`);
    const dataInfoRej = {
      titleModal: t('modal.reasonRejected.title', { name: specialty }), 
      label: statusText,
      tooltip: t('view.reasonRejected'),
      observations: [{ key: 1, label: r.rejectedDetail }],
    };
    const status = <LabelWidthInfo showInfo={isRejectedReason} data={dataInfoRej} disableTextWrap={true} />;

    return {
      name,
      specialty,
      startTime: r.startTime,
      endTime: r.endTime,
      appointmentItemRequestStatus: r.appointmentItemRequestStatus,
      start_date: (startDate && startDate !== '' ? startDate.format(dateFormat) : ''),
      endDate: this.getPlanModuleEnDate(r),
      itemRequestDetails: r.itemRequestDetails,
      companyIdPartnership: r.companyIdPartnership,
      status,
      lenderName,
      lender: lenderName,
      id: index + 1,
      isObservations,
      isRejectedReason,
    }
  }

  showActionsPlan(action, isMePM, isPlan, isHeader, r) {
    const isActions = (action !== 'view')
      && (isMePM || action === 'create' || (isMePM && action === 'assign_agenda') || isHeader)
      && isPlan
      && (((isHeader && r.appointmentItemRequestStatus === 'CREATED') || (isHeader && r.appointmentItemRequestStatus === 'REJECT') || (isMePM && r.appointmentItemRequestStatus === 'APROVEED')) || r.appointmentItemRequestStatus === undefined);
    return isActions;
  }

  getPlanModuleEnDate = (r) => {
    let endate = this.props.dates.endDate;
    if (r.providerEndDate) {
      endate = r.providerEndDate;
    }
    if (r.endDate) {
      endate = r.endDate;
    }
    return moment(endate).format(dateFormat);
  }

  buildPlan({ r, foundPlan, startDate, lenderName, isHeader, isMePM, isPlan, disabled, index }) {
    const { action, viewMode, t, classes } = this.props;
    const startDatePlan = (r.startDate && r.startDate !== '' ? moment(r.startDate).format(dateFormat) : '');
    const data = this.dataBuildPlanModule(r, startDate, lenderName, index, foundPlan.name, t('Practice'));
    let hiddenDelete = false;
    if (!isHeader) {
      hiddenDelete = true;
    }
    const isActions = this.showActionsPlan(action, isMePM, isPlan, isHeader, r);
    const isSelected = isMePM &&  action === 'assign_agenda' && r.appointmentItemRequestStatus == 'APROVEED';
    const selected = isSelected ?
      <Checkbox
        id={`check-${r.appointmentItemRequestId}`}
        tabIndex={index}
        onChange={(e) => this.markAction(e.target.checked, r.appointmentItemRequestId, foundPlan.name)}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{ checked: classes.checked, root: classes.checkRoot }}
      /> : <div />;

    const actions = isActions && <ScheduleItemActions
      hideRemove={(hiddenDelete)}
      disabled={disabled}
      onEdit={() => { this.setState({ open: true, current: this.getCurrentEdit(r, 'plan', r.appointmentItemRequestId), action: 'edit' }) }}
      onDelete={() => {
        const orderItem = (viewMode == null || viewMode === 'copy') ? r.order : r.appointmentItemRequestId;
        this.selectToBeRemoved(index + 1, 'practice', orderItem);
      }}
      id={r.order}
    />;

    return {
      ...data,
      practiceTypeId: r.practiceTypeId,
      startDate: startDatePlan,
      endDate: this.getPlanModuleEnDate(r),
      component: 'plan',
      quantity: r.quantityBefore,
      plan: foundPlan,
      recurrence: foundPlan.recurrence,
      idserver: foundPlan.order,
      lender: data.lenderName,
      order: foundPlan.order ? foundPlan.order : index + 1,
      selected,
      actions,
    }
  }

  formatPlan = (r, foundPlan, startDate, index, disabled) => {
    const { viewMode, t } = this.props;
    let isMePM = false;
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    
    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    if (r.companyIdPartnership && r.companyIdPartnership.companyId == companyPartnerShip) {
      isMePM = true;
    }
    const isPlan = true;
    let lenderName = null;
    if (viewMode != undefined) {
      if (isHeader) {
        if (r.companyIdPartnership.name) {
          lenderName = r.companyIdPartnership.name;
        } else {
          let found = [];
          if (this.state.thirdParties != null) {
            if (r.companyIdPartnership?.companyId) {
              found = this.state.thirdParties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership.companyId);
            } else {
              found = this.state.thirdParties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership);
            }
          }
          if (found.length > 0) {
            lenderName = found[0].companyIdPartnership.name;
          } else {
            lenderName =localStorage.getItem('itlg_default_company_name');
          }
        }
      } else {
        lenderName = r.companyIdPartnership && (r.companyIdPartnership.companyId == companyPartnerShip) ? r.companyIdPartnership.name  : t('common.other');
      }
    } else {
      const found = this.state.thirdParties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership);
      if (found.length > 0) {
        lenderName = found[0].companyIdPartnership.name;
      } else {
        lenderName =localStorage.getItem('itlg_default_company_name');
      }
    }
    const params = { r, foundPlan, startDate, lenderName, isHeader, isMePM, isPlan, disabled, index };
    const plan = this.buildPlan(params);
    return plan;
  }

  buildModule({ r, foundModule, startDate, lenderName, isHeader, isMePM, isModule, disabled, index }) {
    const { action, viewMode, t, classes } = this.props;
    const isActions = (action !== 'view') && (isMePM || action === 'create' || (isMePM && action === 'assign_agenda') || isHeader)
      && isModule
      && (((r.appointmentItemRequestStatus === 'CREATED') || ( r.appointmentItemRequestStatus === 'REJECT')  || (isMePM && r.appointmentItemRequestStatus === 'APROVEED')) || r.appointmentItemRequestStatus === undefined);
    const data = this.dataBuildPlanModule(r, startDate, lenderName, index, foundModule.name, t('Package'));
    
    const isSelected = isMePM &&  action === 'assign_agenda' && r.appointmentItemRequestStatus == 'APROVEED';

    let hiddenDelete = false;
    if (!isHeader) {
      hiddenDelete = true;
    }

    const actions = isActions && <ScheduleItemActions
      hideRemove={(hiddenDelete)}
      disabled={disabled}
      onEdit={() => {
        this.setState({ open: true, current: this.getCurrentEdit(r, 'module', r.appointmentItemRequestId), action: 'edit' })
      }}
      onDelete={() => {
        const orderItem = viewMode === 'copy' ? r.order : r.appointmentItemRequestId;
        this.selectToBeRemoved(index + 1, 'module', orderItem);
      }}
      id={r.order}
    />;

    const selected = isSelected ? <Checkbox
      id={`check-${r.appointmentItemRequestId}`}
      tabIndex={index}
      onChange={(e) => this.markAction(e.target.checked, r.appointmentItemRequestId, foundModule.name)}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
    /> : <div/>;

    return {
      ...data,
      packageId: r.packageId,
      startDate: (r.startDate || ''),
      endDate: this.getPlanModuleEnDate(r),
      module: foundModule.module,
      packageQuantity: r.packageQuantity,
      quantity: r.packageQuantity ? r.packageQuantity : 1,
      actions,
      idserver: foundModule.order,
      order: foundModule.order ?? index + 1,
      selected,
    }
  }

  formatedModule = (r, foundModule, startDate, disabled, index) => {
    const { viewMode, t } = this.props;
    let isMePM = false;
    const { isHeader, companyPartnerShip } = this.getCompanyPartnership();
   
    if (r.companyIdPartnership && r.companyIdPartnership.companyId == companyPartnerShip) {
      isMePM = true;
    }
    const isModule = true;
    let lenderName = null;
    if (viewMode != undefined) {
      const isNotHeader = (r.companyIdPartnership && r.companyIdPartnership.companyId == companyPartnerShip) ? r.companyIdPartnership.name  : t('common.other');
      lenderName = (isHeader && r.companyIdPartnership) ? r.companyIdPartnership.name : isNotHeader;
    } else {
      let found = [];
      if (this.state.thirdParties != null) {
        found = this.state.thirdParties.filter(c => c.companyIdPartnership.companyId == r.companyIdPartnership);
      }
      lenderName = found.length > 0 ? found[0].companyIdPartnership.name : localStorage.getItem('itlg_default_company_name');
    }

    return this.buildModule({ r, foundModule, startDate, lenderName, isHeader, isMePM, isModule, disabled, index });
  };

  onCalculatePractices(calculate, dataTable, companyPartnerShip) {
    if (calculate) {
      let myPractices = [];
      dataTable.forEach(e => {
        if (e.companyIdPartnership.companyId == companyPartnerShip && e.appointmentItemRequestStatus == 'APROVEED') {
          myPractices.push(e.specialty);
        } else {
          myPractices.push("");
        }
      });
      this.props.onCalculatePractices(myPractices);
    }
  }

  getCompanyPartnership() {
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    return { isHeader, companyPartnerShip };
  }

  formatDataTable = (plansProps = [], modulesProps = [], calculate = false) => {
    if (!plansProps) {
      return;
    }
    
    const { appointmentSchedules, dates } = this.state;
    const { companyPartnerShip } = this.getCompanyPartnership();
    if ((this.props.appointmentStatus == 'PARTIAL_ASSIGNED'  || this.props.appointmentStatus == 'SCHEDULES_ASSIGNED'  ) && (!plansProps || !modulesProps)) {
      return;
    }
    if (appointmentSchedules?.length) {
      const plans = [];
      const modules = [];
      let dataTable = [];
      appointmentSchedules.forEach((r, index) => {
        let startDate = '';
        if (r.startDate !== '') {
          startDate = moment(r.startDate);
        }
        // Practice
        let disabled = true;
        if (dates.startDate && dates.startDate != '' && dates.endDate && dates.endDate != '') {
          disabled = false;
        }
        if (r.practiceTypeId) {
          const foundPlan = (plansProps.find((p) => p.practiceTypeId === r.practiceTypeId));
          if (!foundPlan) return;
          if (foundPlan.recurrence && foundPlan.recurrence.endType === 'OCURRENCES') {
            foundPlan.recurrence.finishOcurrences = foundPlan.recurrence.ocurrences;
          }
          const recurrence = r.schedulePlan;
          if (!recurrence.hasOwnProperty('finishOcurrences')) {
            recurrence.finishOcurrences = (recurrence.ocurrences ? recurrence.ocurrences : null);
          }
          if (r.endDate && r.endDate != null) {
            recurrence.finish = '3';
          }
          if (recurrence.endType === 'DATE' && r.endDate == null) {
            recurrence.finish = '1';
          }
          if (recurrence.endType === 'OCURRENCES') {
            recurrence.finish = '2';
          }
          // Adjust recurrence of INTRADAYS
          if (recurrence.processingScheduleType && recurrence.processingScheduleType === 'INTRADAY') {
            recurrence.recurrenceType = 'INTRADAY';
            recurrence.quantityIntraday = recurrence.processingDurationTime / foundPlan.estimatedDuration;
          }
          foundPlan.recurrence = recurrence;
          foundPlan.startTime = r.startTime;
          foundPlan.endTime = r.endTime;
          foundPlan.id = foundPlan.practiceTypeId;
          foundPlan.observations = r.observations;
          foundPlan.companyIdPartnership = r.companyIdPartnership;
          foundPlan.appointmentItemRequestStatus = r.appointmentItemRequestStatus;
          foundPlan.order = index+1;
          foundPlan.file=r.file;
          let [quantity] = [0];
          if (!this.state.isPatient) {
            [quantity] = setQuantityRequests(foundPlan, startDate, r.endDate, this.props.dates.endDate); 
          }
          r.quantityBefore = quantity;
          
          const formatPlan = this.formatPlan(r, foundPlan, startDate, index, disabled);
          plans.push(formatPlan);
          if (this.state.isPatient) {
            formatPlan.name = this.props.t('schedule.unique');
          }
          dataTable.push(formatPlan);
        } else {
          // Module
          const foundModule = (modulesProps.find((p) => p.packageId === r.packageId));
          
          if (!foundModule) return;
          foundModule.startTime = r.startTime;
          foundModule.endTime = r.endTime;
          foundModule.id = foundModule.packageId;
          foundModule.order = index+1;
          foundModule.companyIdPartnership = r.companyIdPartnership;
          foundModule.observations = r.observations;
          foundModule.appointmentItemRequestStatus = r.appointmentItemRequestStatus;
          foundModule.file = r.file;
          const formatedModule = this.formatedModule(r, foundModule, startDate, disabled, index);
          formatedModule.itemRequestPackageDetails = r.itemRequestPackageDetails;
          modules.push(formatedModule);
          dataTable.push(formatedModule);
        }
      });
      dataTable = dataTable.sort((a, b) => a.order - b.order);
      this.onCalculatePractices(calculate, dataTable, companyPartnerShip);
      return this.setState({ plans, dataTable, modules, id: appointmentSchedules.length, plansProps, modulesProps });
    }
  }

  getCurrentEdit = (v, type, appointmentItemRequestId) => {
    v.module = (type === 'module');
    v.appointmentItemRequestId = appointmentItemRequestId;
    return v;
  }

  formatValue(value) {
    value.map(v => {
      if (!v.actions) {
        v.actions = (<ScheduleItemActions
          onEdit={() => {
            this.setState({ open: true, current: v, action: 'edit' })
          }}
          onDelete={() => {
            this.selectToBeRemoved(v.id, v.plan ? 'practice' : 'module')
          }}
          id={v.order}
        />)
      }
      if (v.plan) {
        this.setState(prev => ({
          setAction: true,
          plans: (_.cloneDeep(prev.plans) || []).concat([v]),
          dataTable: (_.cloneDeep(prev.dataTable) || []).concat([v]),
          id: prev.id + 1
        }))
      } else {
        this.setState(prev => ({
          setAction: true,
          modules: (_.cloneDeep(prev.modules) || []).concat([v]),
          dataTable: (_.cloneDeep(prev.dataTable) || []).concat([v]),
          id: prev.id + 1
        }))
      }
    })
  }

  __getMaxOrder() {
    const { appointmentSchedules } = this.state;
    const maxorder = appointmentSchedules.reduce((max, schedule) => {
      return (schedule.order > max) ? schedule.order : max;
    }, 0);
    return maxorder + 1;
  }

  handlerAddModuleCopy(dataModule) {
    const newModule = this.formatAppointmentScheduleItem({ module: dataModule });
    this.props.onNewPracticeModuleAdded(newModule, null);
  }

  addModule(module) {
    const { appointmentSchedules, current, dataTable, modules, packageQuantity } = this.state;
    if (this.props.viewMode === 'copy') {
      this.handlerAddModuleCopy(module);
      return;
    }
    let isChange = this.state.current && !this.state.current.module;
    if (!current?.module) {
      module.id = isChange ? current.order : dataTable.length + 1;
      const newModule = this.generateModuleItem(module);
      if (!isChange) {
        newModule.id = this.__getMaxOrder();
      }
      newModule.packageQuantity = packageQuantity;
      let newDataTable = _.cloneDeep(dataTable);
      let newModules = _.cloneDeep(modules);
      let newAppointementScheduleItems = [];
      let appointmentScheduleItem = this.formatAppointmentScheduleItem(newModule);
      if (isChange) {
        newDataTable = this.replaceDataTables(module.id, newModule);
        newModules.push(newModule);
        newAppointementScheduleItems = appointmentSchedules.map((app) => {
          return (app.order === appointmentScheduleItem.order) ? appointmentScheduleItem : app;
        });
      } else {
        newDataTable.push(newModule);
        newModules.push(newModule);
        newAppointementScheduleItems = (_.cloneDeep(appointmentSchedules) || []).concat([appointmentScheduleItem]);
      }
      this.setState({ current: null, modules: newModules, dataTable: newDataTable, id: newDataTable.length + 1, appointmentSchedules: newAppointementScheduleItems }, () => {
        this.props.onNewPracticeModuleAdded(appointmentScheduleItem, this.linkToItemSchedule());
        this.props.onFilledSchedule(newDataTable);
      });
      setTimeout(() => {
        this.closeAlert();
      }, 100);
      return;
    }
    this.editModule(module);
  }

  editModule(module) {
    const { appointmentSchedules, modules } = this.state;
    const editModule = this.generateModuleItem(module);
    const newModules = _.cloneDeep(modules);
    const appointmentScheduleItem = this.formatAppointmentScheduleItem(editModule);
    const newAppointementScheduleItems = appointmentSchedules.map((app) => {
      if (app.order === appointmentScheduleItem.order) {
        return appointmentScheduleItem;
      }
      return app;
    });
    const modulesUpdated = newModules.map((m, i) => {
      if (m.id === module.id) {
        this.editDataTable(editModule);
        return editModule;
      }
      return m;
    });
    if (!appointmentSchedules[0].file) {
      appointmentSchedules[0].file = editModule.file; 
    }

    this.setState({
      modules: modulesUpdated,
      dataTable: this.replaceDataTables(editModule.id, editModule),
      appointmentSchedules: newAppointementScheduleItems,
    });
  }

  generateModuleItem(module) {
    const startDate = moment(module.startDate).format(dateFormat);
    const endDate = module.providerEndDate ? moment(module.providerEndDate).format(dateFormat) : '';
    const moduleDetails = this.props.modules.find(m => m.packageId === module.module.id);
    const quantity = _.get(moduleDetails, 'packageDetail.0.schedulePlan.ocurrences', 1);
    return {
      module: module,
      id: module.id,
      name: this.props.t('Package'),
      itemRequestDetails: module.itemRequestDetails,
      itemRequestPackageDetails: module.itemRequestPackageDetails,
      specialty: module.module.name,
      start_date: startDate,
      endDate,
      companyIdPartnership: module.companyIdPartnership,
      quantity,
      file:module.file,
      actions: (<ScheduleItemActions
        onEdit={() => {
          this.setState({ open: true, current: module, action: 'edit' })
        }}
        onDelete={() => {
          this.selectToBeRemoved(module.id, 'module')
        }}
        id={this.state.dataTable.length + 1}
      />),
    }
  }

  sendFile (body)  {
    console.log("body",body)
		AppointmentApiInvoker.postSaveFile(body, (res) => {
		  console.log('Respuesta del servicio:', res);
		});
	};

	deleteFile (id)  {
		AppointmentApiInvoker.putFile(id, (res) => {
		console.log('Respuesta del servicio:', res);
		});
	};

  updatePlan(items) {
    if (this.props.viewMode !== 'copy') {
      const { appointmentSchedules } = this.state;
      this.setState({ loading: true });
      const sprov = appointmentSchedules && appointmentSchedules.length > 0 ? appointmentSchedules[0].serviceProvider : {};
      if (sprov) {
        items.map(item => item.serviceProvider = sprov);
      }
      items = items.map(item => {
        const isSliceText = item.observations && item.observations.length > 600;
        if (item?.itemRequestPackageDetails) {
          delete item.item;
        }
        return {
        ...item,
        observations: (isSliceText ? item.observations.slice(0, 600) : item.observations) || undefined,
        }
      });
      EmployeeApiInvoker.postAppointmentScheduleItems(this.props.appointmentId, items, () => {
        this.onAppointment();
        //caso 1. eliminar y no guardar otro archivo
        if(items[0].itemRequestPackageDetails[0].file === null){
          this.deleteFile(items[0].file[0].appointmentItemRequestResourcesId);
        }
        //caso 2. Editar el archivo existente/nuevo y guardarlo
        else if(items[0].itemRequestPackageDetails[0].file.base64){
			      const { name, type, date, base64 } = items[0].itemRequestPackageDetails[0].file;
            const [day, month, year, hourRaw, minute, period] = date.split(/\/|\s|:/);

            const hour = period.toLowerCase() === 'p. m.' && hourRaw !== '12'
              ? (parseInt(hourRaw) + 12).toString()
              : hourRaw === '12' && period.toLowerCase() === 'a. m.'
              ? '00'
              : hourRaw;

            const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
          
            const body = {
            name: name || 'file',
            extension: type.split('/')[1].toUpperCase(),
            file: base64,
            appointmentItemRequestId: items[0].appointmentItemRequestId,
            dateUpload: formattedDate,
            //...(this.state.fileId !== 0 && { appointmentItemRequestResourcesId: this.state.fileId }) // Solo añade si fileId es distinto de 0
            appointmentItemRequestResourcesId: items[0].file?.[0]?.appointmentItemRequestResourcesId || null
          };
            
            // Enviar el objeto creado al servicio
            this.sendFile(body);
		    }

        this.getPMTypesAndItems();
      }, () => {
        this.setState({ loading: false });
      });
    } else {
      this.props.onUpdatePlanCopy(JSON.parse(JSON.stringify(items)));
    }
  }

  formatSchedulePlan(schedulePlan) {
    const isFinish3 = schedulePlan.recurrence.finish === '3' ? 'DATE' : null;
    const isFinish1 = schedulePlan.recurrence.finish === '1' ? 'DATE' : isFinish3;
    const isNotWeekRecurrence = isArray(schedulePlan.recurrence.weekRecurrence) ? schedulePlan.recurrence.weekRecurrence.join(',') : null;
    const weekRecurrence = isArray(schedulePlan.recurrence.weekRecurrence) ? schedulePlan.recurrence.weekRecurrence : isNotWeekRecurrence;
    return {
      observations: schedulePlan.observations,
      endType: schedulePlan.recurrence.finish === '2' ? 'OCURRENCES' : isFinish1,
      every: schedulePlan.recurrence.every,
      monthDay: schedulePlan.recurrence.monthRecurrence || null,
      ocurrences: schedulePlan.recurrence.finishOcurrences || null,
      recurrenceType: schedulePlan.recurrence.recurrenceType || null,
      quantityIntraday: schedulePlan.recurrence.quantityIntraday || null,
      processingDurationTime: schedulePlan.recurrence.processingDurationTime,
      processingScheduleType: schedulePlan.recurrence.processingScheduleType,
      weekDays: schedulePlan.recurrence.weekRecurrence ? weekRecurrence : null,
    }
  }

  formatAppointmentScheduleItem(s) {
    const { provider, items } = this.props;
    const notServiceProviderId = items?.length > 0 ? items[0].serviceProvider.serviceProviderId : null;
    return {
      order: s.id,
      observations: s.plan ? s.plan.observations : s.module.observations,
      serviceProvider: {
        serviceProviderId: (provider?.provider.serviceProviderId ? provider.provider.serviceProviderId : notServiceProviderId)
      },
      startDate: s.plan ? s.plan.startDate : s.module.startDate,
      endDate: s.plan ? s.plan.endDate : s.module.endDate,
      endTime: s.plan ? s.plan.endTime : s.module.endTime,
      startTime: s.plan ? s.plan.startTime : s.module.startTime,
      packageId: s.module ? s.module.module.packageId : null,
      packageQuantity: s.module ? s.module.packageQuantity : null,
      practiceTypeId: s.plan ? s.plan.plan.id : null,
      companyIdPartnership: s.plan ? s.plan.companyIdPartnership : s.module.companyIdPartnership,
      itemRequestDetails: s.plan ? s.plan.itemRequestDetails : s.module.itemRequestDetails,
      itemRequestPackageDetails: s.module?.itemRequestPackageDetails ?? null,
      schedulePlan: s.plan ? this.formatSchedulePlan(s.plan) : null,
      rejectedDetail: s.rejectedDetail,
      file:s.plan ? s.plan.file : s.module.file
    }
  }

  addPlan(plan) {
    const { appointmentSchedules, current, dataTable, plans } = this.state;
    if (this.props.viewMode === 'copy') {
      this.props.onNewPracticeModuleAdded(this.formatAppointmentScheduleItem({ plan }), null);
      return;
    }

    let isChange = this.state.current != null;
    if (!current?.plan) {
      plan.id = isChange ? current.order : dataTable.length + 1;
      const newPlan = this.generatePlanItem(plan);
      if (!isChange) {
        newPlan.id = this.__getMaxOrder();
      }
      const newPlans = _.cloneDeep(plans);
      let newDataTable = _.cloneDeep(dataTable);

      const appointmentScheduleItem = this.formatAppointmentScheduleItem(newPlan);
      let newAppointementScheduleItems = [];
      if (isChange) {
        newDataTable = this.replaceDataTables(plan.id, newPlan);
        newPlans.push(newPlan);
        newAppointementScheduleItems = appointmentSchedules.map((app) => {
          if (app.order === appointmentScheduleItem.order) {
            return appointmentScheduleItem;
          }
          return app;
        })
      } else {
        newDataTable.push(newPlan);
        newPlans.push(newPlan);
        newAppointementScheduleItems = (_.cloneDeep(appointmentSchedules) || []).concat([appointmentScheduleItem]);
      }

      this.setState({ current: null, plans: newPlans, dataTable: newDataTable, id: newDataTable.length + 1, appointmentSchedules: newAppointementScheduleItems }, () => {
        this.props.onNewPracticeModuleAdded(appointmentScheduleItem, this.linkToItemSchedule());
        this.props.onFilledSchedule(newDataTable);
      })
      return;
    }
    this.editPlan(plan);
  }

  getFilePromise(appointmentItemRequestId) {
    return new Promise((resolve, reject) => {
      AppointmentApiInvoker.getFile(appointmentItemRequestId, resolve, reject);
    });
  }
  
  getPMTypesAndItems() {
    const { appointmentId, type } = this.props;
    const { plansProps, modulesProps } = this.state;
    this.getPracticeTypes();
    this.getModuleTypes();
   if(type !== "transfer"){
    AppointmentApiInvoker.getAppointmentItems(appointmentId, async (res) => {
    
      try {
        const promises = res.map(async (item) => {
          const data = await this.getFilePromise(item.appointmentItemRequestId);
          if (data.length > 0) {
            item.file = data; 
          }
        });
    
        await Promise.all(promises);

        this.setState({ appointmentSchedules: res }, () => {
          this.formatDataTable(plansProps, modulesProps);
          this.setState({ loading: false });
        });
      } catch (error) {
        console.error('Error al obtener los archivos:', error);
        this.setState({ loading: false });
      }
    }, (error) => {
      console.error('** error getAppointmentItems', error);
      this.setState({ loading: false });
    });
   }else{
    AppointmentApiInvoker.getAppointmentById(appointmentId, (res) => {
      this.setState({ appointmentSchedules: res.items }, () => {
        this.formatDataTable(plansProps, modulesProps);
        this.setState({ loading: false });
      })
    }, (error) => {
      console.error('** error getAppointmentItems', error);
      this.setState({ loading: false });
    }
  );
   }
  }

  linkToItemSchedule() {
    return (newItemSchedule) => {
      this.getPMTypesAndItems();
    }
  }

  editPlan(plan) {
    const { plans } = this.state;
    const plansUpdated = plans.map((p, i) => {
      if (p.id === plan.id) {
        const newPlan = this.generatePlanItem(plan);
        this.editDataTable(newPlan);
        return newPlan;
      } else {
        return p;
      }
    });
    this.setState({ plans: plansUpdated });
  }

  generatePlanItem(plan) {
    const startDate = moment(plan.startDate);
    const start_date = startDate.format(dateFormat);
    const endDate = this.getPlanModuleEnDate(plan);
    const [quantity] = setQuantityRequests(plan, startDate, null, this.props.dates.endDate);
    return {
      plan,
      id: plan.id,
      name: this.props.t('Practice'),
      specialty: plan.plan.value,
      start_date,
      endDate,
      quantity,
      actions: (
        <ScheduleItemActions
          onEdit={() => { this.setState({ open: true, current: plan, action: 'edit' }) }}
          onDelete={() => { this.selectToBeRemoved(plan.id, 'practice') }}
          id={plan.order}
        />
      ),
    }
  }

  editDataTable(row) {
    let current = this.state.dataTable;
    this.state.dataTable.forEach((dt, i) => {
      if (row.id === dt.id) {
        current[i] = row;
        this.setState({ dataTable: current, current: null }, () => {
          this.props.onFilledSchedule(this.state.dataTable);
        });
      }
    });
  }

  /**
   * Delete item related functions
   */
  // 1. Set id of item to be removed and opens delete confirmation
  selectToBeRemoved(id, type, appointmentItemRequestId) {
    this.setState({
      remove: id,
      appointmentItemRequestIdToDelete: appointmentItemRequestId,
      deleteAlertText: type === 'module' ? this.props.t('appointment.new.schedule.module') : this.props.t('appointment.new.schedule.practice'),
    }, () => {
      this.setState({ deleteAlert: true });
    })
  }

  // 2. Lookup of item to be removed
  removeItem() {
    const { action, t } = this.props;
    if (action === 'edit' && this.state.dataTable.length === 1) {
      this.setState((prev) => ({
        ...prev,
        alert: {
          ...prev.alert,
          message: t('practicesModules.isNotSave'),
          open: true,
        }
      }));
      return setTimeout(() => {
        this.setState((prev) => ({ ...prev, alert: {...prev.alert, open: false } }));
      }, 2000);
    }
    const itemToRemove = this.state.dataTable.find(dt => dt.id === this.state.remove);
    if (!itemToRemove) {
      return;
    }

    this.props.onDeletePracticeModule(itemToRemove, this.state.appointmentItemRequestIdToDelete);
    if (itemToRemove.plan) {
      this.remove('plans');
      return;
    }
    this.remove('modules');
  }

  // 3. remove item from state
  remove(type) {
    this.setState((prevState) => {
      const current = prevState[type].filter((c) => c.id === prevState.remove);
      return { [type]: current }
    }, () => {
      this.removeDataTable(this.state.remove);
    });
  }

  // 4. Updates the table
  removeDataTable(id) {
    const { appointmentSchedules, dataTable } = this.state;
    const current = dataTable.filter((dt) => dt.id !== id);
    const newAppointmentSchedules = appointmentSchedules.filter((appS, i) => {
      if (this.props.viewMode == 'edit') {
        return (appS.appointmentItemRequestId !== this.state.appointmentItemRequestIdToDelete);
      } else {
        let order = appS.order;
        return (order != id);
      }
    });
    this.setState({ dataTable: current, appointmentSchedules: newAppointmentSchedules, id: current.length }, () => {
      this.setState({ remove: null });
      this.props.onFilledSchedule(current);
      setTimeout(() => {
        this.closeAlert();
      }, 100);
    })
  }

  replaceDataTables(id, item) {
    const currentDataTable = this.state.dataTable;
    const newDataTable = currentDataTable.filter(a => a.id !== id).concat([item]);
    return newDataTable;
  }

  closeAlert() {
    this.setState({
      error: false,
      deleteAlert: false,
      current: null,
      isModule: false,
    });
  }

  getPracticeTypes() {
    AppointmentApiInvoker.getGenericPracticeTypes(practiceTypes => {
      this.props.onFetchPracticeTypes(practiceTypes);
    }, null);
  }

  getModuleTypes() {
    AppointmentApiInvoker.getPackagesAll(data => {
      if (data?.length) {
        this.props.onFetchModules(data);
      }
    }, null);
  }

  renderHeaderDefault = () => {
    const { t } = this.props;
    const headerDefault = [
      { Header: t('appointment.new.schedule.selected'), accessor: 'selected' },
      { Header: t('appointment.new.schedule.order'), accessor: 'idserver' },
      { Header: t('appointment.new.schedule.lender'), accessor: 'lender' },
      { Header: t('appointment.new.schedule.type'), accessor: 'name' },
      { Header: t('appointment.new.schedule.name'), accessor: 'specialty' },
      { Header: t('appointment.new.schedule.status'), accessor: 'status' },
      { Header: t('appointment.new.schedule.start_date'), accessor: 'start_date' },
      { Header: t('common.endDate'), accessor: 'endDate' },
      { Header: t('appointment.new.schedule.quantity'), accessor: 'quantity' },
    ];
    if (this.props.viewMode !== 'view') {
      headerDefault.push({ Header: t('appointment.new.schedule.actions'), accessor: 'actions' });
    }
    return headerDefault;
  }

  renderTableHead = (appointmentStatus) => {
    const { t } = this.props;
    if (this.state.isPatient) {
      return [
        { Header: t('appointment.new.schedule.selected'), accessor: 'selected' },
        { Header: t('appointment.new.schedule.order'), accessor: 'order' },
        { Header: t('appointment.new.schedule.type'), accessor: 'name' },
        { Header: t('appointment.new.schedule.name'), accessor: 'specialty' },
        { Header: t('common.date'), accessor: 'startDate' },
      ];
    }
    if (appointmentStatus === 'SCHEDULES_ASSIGNED') {
      return [
        { Header: t('appointment.new.schedule.order'), accessor: 'order' },
        { Header: t('appointment.new.schedule.lender'), accessor: 'lender' },
        { Header: t('appointment.new.schedule.type'), accessor: 'name' },
        { Header: t('appointment.new.schedule.name'), accessor: 'specialty' },
        { Header: t('appointment.new.schedule.status'), accessor: 'status' },
        { Header: t('appointment.new.schedule.start_date'), accessor: 'startDate' },
        { Header: t('common.endDate'), accessor: 'endDate' },
        { Header: t('appointment.new.schedule.quantity'), accessor: 'quantity' },
      ];
    }
    return this.renderHeaderDefault();
  }

  render() {
    const { t, appointmentId, appointmentStatus, disabled, checkInvalid, dates, action, customer, customerAddress, isTransfer } = this.props;
    const { alert, appointmentSchedules, loading, dataTable, deleteAlert, error, current, open, isPatient } = this.state;
    const tableHead = this.renderTableHead(appointmentStatus);
    const iconCardColor = isPatient ? 'secondary' : 'primary';
    const titleCard = isPatient ? t('label.practices') : t('appointment.new.schedule.title');
    const hasAdd = Authority.hasPermission('appointment_edit');
    const titleTransfer = isTransfer ? "Traslados" :""
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    return (
      <GridItem id="schedules-table" className="table schedule-detail full-width">
        <Card>
          <CardHeader icon>
            <CardIcon color={iconCardColor}>
              <ViewList />
            </CardIcon>
            <h3 className="card-icon-title">
              {isTransfer ? titleTransfer : titleCard }
            </h3>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              defaultPageSize={5}
              sortable={true}
              loading={loading}
              tableHead={tableHead}
              tableData={dataTable}
              colorsColls={['primary']}
            />
          </CardBody>
          {!disabled && !loading && hasAdd &&
            <CardFooter xs={12} className={`${checkInvalid && !(dataTable?.length) ? 'schedule-form-submit invalid-text' : 'schedule-form-submit'}`}>
              {checkInvalid && !(dataTable?.length) && (
                <FormHelperText className="helper-error">
                  {t('error.required')}
                </FormHelperText>
              )}
              <Tooltip
                title={!dates.startDate || !dates.endDate ? t('appointment.new.warningDates') : ''}
              >
                <Button
                  id="button-sched-plus"
                  onClick={() => this.setState({ open: true, action: 'create', current: null })}
                  color="primary"
                  disabled={!dates?.startDate || !dates?.endDate || companyPartnerShip !== null}
                >
                  <Add />
                </Button>
              </Tooltip>
            </CardFooter>
          }
        </Card>
        <ScheduleDetailModal
          onGetModuleTypes={this.getModuleTypes.bind(this)}
          onGetPracticeTypes={this.getPracticeTypes.bind(this)}
          current={current}
          open={open}
          action={this.state.action}
          actionAppointment={action}
          items={appointmentSchedules}
          customer={customer}
          appointmentId={Number(appointmentId)}
          dates={dates}
          onCloseModal={() => this.setState({ open: false, current: null, selectedItem: [], selectedNames:[] })}
          onCreatedModule={value => this.addModule(value)}
          onCreatedPlan={value => this.addPlan(value)}
          onUpdatePlan={value => this.updatePlan(value)}
          customerAddress={customerAddress}
          appointmentStatus={appointmentStatus}
          isTransfer={true}
        />
        <ConfirmAlert
          text={this.state.deleteAlertText}
          onClose={() => this.closeAlert()}
          onConfirm={() => this.removeItem()}
          showWithoutConfirmation={deleteAlert}
          error={error}
        />
        <Snackbar
          place="tr"
          color={alert.color}
          message={alert.message}
          open={alert.open}
        />
      </GridItem>
    )
  }
}

ScheduleDetail.defaultProps = {
  dates: {
    startDate: null,
    endDate: null,
  },
  onFilledSchedule: () => { },
  onNewPracticeModuleAdded: () => { },
  onDeletePracticeModule: () => { },
  disabled: false,
  appointmentId: null,
  checkInvalid: false,
  customer: {},
  action: '',
  items: [],
}

ScheduleDetail.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  onFilledSchedule: PropTypes.func,
  onNewPracticeModuleAdded: PropTypes.func,
  onDeletePracticeModule: PropTypes.func,
  customer: PropTypes.object,
  action: PropTypes.string,
  items: PropTypes.array,
  appointmentId: PropTypes.string,
  checkInvalid: PropTypes.bool,
  appointmentStatus: PropTypes.string,
  dates: PropTypes.object,
  viewMode: PropTypes.string,
  classes: PropTypes.object,
  modules: PropTypes.array,
  practiceTypes: PropTypes.array,
  provider: PropTypes.object,
  setProvider: PropTypes.func,
  onSelectedItems: PropTypes.func,
  onCalculatePractices: PropTypes.func,
  onFetchPracticeTypes: PropTypes.func,
  onFetchModules: PropTypes.func,
  customerAddress: PropTypes.object,
  onUpdatePlanCopy: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(ScheduleDetail));
