import { Theme, makeStyles } from "@material-ui/core";

import { whiteColor } from "../../assets/components/material-dashboard-pro-react";

export const useStyles = makeStyles((theme: Theme) => ({
  selectLine: {
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      backgroundColor: whiteColor,
      marginTop: theme.spacing(1),
    },
    '& label': {
      marginLeft: theme.spacing(1),
      marginTop: 8,
      padding: '0 5px',
      zIndex: 1,
    },
    '& label + .MuiInput-formControl': {
      marginTop: theme.spacing(1.5),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid rgba(0, 0, 0, 0.26)',
      fontSize: '0.875rem',
      height: '1.1876em',
      padding: '18.5px 14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        cursor: 'default',
      },
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  },
}))