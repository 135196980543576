import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

type Props = {
  language: string;
  onChangeLang: (lang: string) => void;
};

export const LanguageSelector: FC<Props> = ({ language, onChangeLang }) => {
  const { t } = useTranslation();

  const languages = [
    { value: 'es', enabled: true },
    { value: 'en', enabled: true },
    { value: 'pt', enabled: true },
    { value: 'it', enabled: true },
    { value: 'fr', enabled: false },
  ];

  const onChange = (value: string) => {
    onChangeLang(value);
  };
  const getLanguagesOptions = () => {
    const enabledLanguages = languages.filter((lang) => lang.enabled);
    return enabledLanguages.map((lang) => {
      const label = t(`languages.${lang.value}`);
      return <option key={lang.value} value={lang.value}>{label}</option>
    });
  }

  return (
    <select id="select-language" onChange={(event) => onChange(event.target.value)} value={language}>
      {getLanguagesOptions()}
    </select>
  )
}
