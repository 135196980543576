import { connect } from 'react-redux';
import DashboardDiabeticsAndHospitalization from '../components/DashboardsPB/DashboardDiabeticsAndHospitalization';
import React from 'react';


const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardDiabeticService = connect(
    mapStateToProps,
    mapDispatchToProps
)((props) => <DashboardDiabeticsAndHospitalization {...props} variant="diabetic" />);

export default DashboardDiabeticService;