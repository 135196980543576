import './NewCustomer.css';

import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import Authority from '../../util/Authority'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import DateInput from '../DateInput';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateToServer();

class NewCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            nationalities: [],
            genderTypes: [],
            name: '',
            lastName: '',
            document: '',
            gender: '',
            documentType: '',
            email: '',
            birthDate: '',
            nationality: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            save: false,
            dataChanged: false,
            loading: false,
            judicialized: false,
            generalPractitionerId: null,
            generalPractitionerOptions: [],
            registration: null,
        }
    }

    componentWillMount() {
        this.getNationalities();
        this.getGenderTypes();
        this.getGeneralPractitioner()
        this.getDocumentTypes()
    }

    componentWillReceiveProps(next) {
        if (next.documentTypes) {
            const documentTypes = this.formatDocumentTypes(next.documentTypes);
            this.setState({
                documentTypes,
                documents: next.documentTypes,
            });
        }
    }


    getGeneralPractitioner() {
        PersonApiInvoker.getGeneralPractitioner(
            (data) => {
                if (data?.length) {
                    const result = this.formatGeneralPractitioner(data);
                    this.setState({ generalPractitionerOptions: result });
                }
            }, null);
        }
    
    formatGeneralPractitioner(GeneralPractitioner) {
        const result = GeneralPractitioner?.map((d) => {
            return {
                id: parseInt(d.generalPractitionerId),
                value: `${d.name} ${d.lastName}`,
                registration: d.medicalRegistrationNumber,
            }
        })
        return result;
    }

    setRegistration(id) {
        this.setState((prevState) => {
        const result = prevState.generalPractitionerOptions.find(
            (doctor) => doctor.id === id);
            return { registration: result?.registration };
        });
    } 

    getNationalities () {
        PersonApiInvoker.getNationalities(jsonResponse => {
            this.setState({
                nationalities: jsonResponse.map(nationalityItem => ({
                    id: nationalityItem.nationalityId,
                    name: nationalityItem.name,
                }))
            });
        })
    }

    formatDocumentTypes(documentTypes) {
        const result = documentTypes.map(d => ({
            id: d.idTypeId,
            value: `${d.name} - ${d.type}`,
        }));
        return result;
    }

    setGenderTypesState (genderTypes) {
        const { t } = this.props;
        const genderTypesMap = {
            UNDEFINED: t('customers.new.gender.undefined'),
            MALE: t('customers.new.gender.male'),
            FEMALE: t('customers.new.gender.female'),
        }
        const result = genderTypes.map(genderType => {
            return {
                id: genderType,
                value: genderTypesMap[genderType],
            }
        });
        this.setState({ genderTypes: result });
    }

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    getGenderTypes () {
        PersonApiInvoker.getGenderTypes(data => {
            this.setGenderTypesState(data);
        }, null);
    }

    findDocumentType(id) {
        const type = this.state.documents.find(d => d.idTypeId === id);
        return type || {};
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                browserHistory.push(`/editar-paciente/${data.personId}`)
                this.cleanFields();
            }
        }, 5000);
    }

    validEmail(value) {
        if(value.length === 0) {
            return true;
        }
        const emailRex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRex.test(value);
    }

    postCustomer() {
        const { t } = this.props;
        PersonApiInvoker.postCustomer({
            id: this.state.document,
            avatarUrl: '',
            birthdate: moment(this.state.birthDate).format(dateToServer),
            email: this.state.email,
            nationality: this.findNationality(),
            sex: this.state.gender,
            firstName: this.state.name,
            lastName: this.state.lastName,
            idType: this.findDocumentType(this.state.documentType),
            metadata: {},
            verificationId: 0,
            qr: '',
            generalPractitionerId: {
                generalPractitionerId: this.state.generalPractitionerId,
              },         
            judicialized: this.state.judicialized 
        }, (data) => {
            this.props.onAddCustomer(data);
            this.setState({ dataChanged: false, loading: false });
            this.openAlert('success', t('customer.message.success'), data);
        
        }, (error) =>  {
            this.openAlert('danger', 'Error: ' + error.message + '');
            this.setState({ loading: false });
        });
    }

    typeClick() {
        this.setState({ save: true, loading: true });
        const { t } = this.props;
        const requiredFields = this.state.name && this.state.lastName
        && this.state.document && this.state.documentType !== '0'
        && this.state.birthDate && this.state.nationality && this.state.gender;

        if (requiredFields && this.validEmail(this.state.email)) {
            this.postCustomer();
            return;
        }
        const messageEmail = !this.validEmail(this.state.email) ? t('message.invalidEmail') : '';
        const messageRequired = !requiredFields ? t('appointment.new.requiredFields') : '';
        this.openAlert('warning', `${ messageRequired } ${ messageEmail }`);
        this.setState({ loading: false });
    }

    findNationality () {
        let nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === this.state.nationality.id;
        });

        return {
            name: nationalityObj.name,
            nationalityId: nationalityObj.id
        };
    }

    CancelSave(){
        browserHistory.push(`/patient`);
    }

    cleanFields() {
        this.setState({
            name: '',
            lastName: '',
            document: '',
            nationality: '',
            documentType: '0',
            email: '',
            birthDate: null,
            generalPractitionerId: null,
            registration: null,
            save: false
        });
    }

    handleValue(value, state) {
        this.setState({
            [state]: value,
            dataChanged: true
        });
    }

    handleCheckboxChange(event) {
        this.setState({ judicialized: event.target.checked });
    }

    render() {
        const { t } = this.props;
        const { dataChanged, loading } = this.state;
        const maxDate = moment().format(dateToServer);
        const minDate = moment().subtract(120, 'years').format(dateToServer);
        return (
            <div className="new-customer">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <Add />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('customers.new.title')}</h3>
                            </CardHeader>
                            <CardBody className="new-customer-form">
                                <form>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('customers.new.first_name')} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.name} invalid={this.state.name === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('customers.new.last_name')} onChangeValue={(value) => this.handleValue(value, 'lastName')} value={this.state.lastName} invalid={this.state.lastName === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <Autocomplete
                                                className="nationality-autocomplete select-input"
                                                options={this.state.nationalities}
                                                getOptionLabel={option => option.name}
                                                getOptionSelected={option => option.id}
                                                renderInput={params => ( 
                                                    <TextField {...params} label={t('customers.new.nationality')} fullWidth/>
                                                )}
                                                onChange={(event, value) => this.handleValue(value, 'nationality')}
                                                value={this.state.nationality}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <SelectInput
                                                className="select-input"
                                                label={t('customers.new.gender')}
                                                elements={this.state.genderTypes}
                                                value={this.state.gender}
                                                onSelectedValue={(value) => this.handleValue(value, 'gender')}
                                                invalid={this.state.gender === '' && this.state.save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <SelectInput
                                                className="select-input"
                                                label={t('customers.new.id_type')}
                                                elements={this.state.documentTypes}
                                                onSelectedValue={(value) => this.handleValue(value, 'documentType')}
                                                value={this.state.documentType}
                                                invalid={this.state.documentType === '' && this.state.save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('customers.new.id')} type="text" onChangeValue={(value) => this.handleValue(value, 'document')} value={this.state.document} invalid={this.state.document === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} className="calendar-up">
                                            <DateInput
                                                minDate
                                                min={minDate}
                                                max={maxDate}
                                                text={t('customers.new.birth_date')}
                                                onChangeValue={(value) => this.handleValue(value, 'birthDate')}
                                                value={this.state.birthDate}
                                                invalid={this.state.birthdate ? this.state.birthdate <= maxDate : !this.state.birthDate && this.state.save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput
                                                text={t('customers.new.email')}
                                                type="email"
                                                onChangeValue={(value) => this.handleValue(value, 'email')}
                                                value={this.state.email}
                                                invalid={!this.validEmail(this.state.email)}
                                            />
                                        </GridItem>

                                        {Authority.hasPermission('admission_module') && <>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <SelectInput
                                                      label={t('label.patientForm.mainDoctor')}
                                                      onSelectedValue={(value) => {
                                                        this.handleValue(value, 'generalPractitionerId')
                                                        this.setRegistration(value)
                                                       }}
                                                      elements={this.state.generalPractitionerOptions}
                                                      id="doctor"
                                                      value={this.state.generalPractitionerId}
                                                      disabled={false}
                                                      invalid={!this.state.generalPractitionerId && this.state.save}
                                                    ></SelectInput>
                                                  </GridItem>
                                                  <GridItem xs={12} sm={12} md={6}>
                                                    <ValidationInput
                                                      text={t('label.patientForm.medicalRecord')}
                                                      id="medical_record"
                                                      onChangeValue={(value) =>
                                                        this.handleValue(value, 'registration')
                                                      }
                                                      value={
                                                        this.state.registration ? this.state.registration : ''
                                                      }
                                                      formControlProps={{
                                                        fullWidth: true,
                                                      }}
                                                      inputProps={{
                                                        type: 'text',
                                                      }}
                                                      disabled={false}
                                                    />
                                                  </GridItem>
                                                  </>
                                                  }
                                        <GridItem xs={12} sm={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.judicialized}
                                                        onChange={(event) => this.handleCheckboxChange(event)}  
                                                        name="judicialized"
                                                        color="primary"
                                                        className="custom-checkbox" 
                                                    />
                                                }   
                                                label={t('customers.new.judicialized')} 
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <p className="required-text">
                                                <small>*</small> {t('common.fields.required')}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                            <CardFooter className="new-customer-submit padding-horizontal">
                                <Button id="button-cancel" onClick={this.CancelSave.bind(this)}>
                                    {t('customers.new.cancel')}
                                </Button>
                                <ButtonSpinner
                                    onClick={this.typeClick.bind(this)}
                                    disabled={!dataChanged || loading}
                                    label={t('customers.new.save')}
                                    labelLoading={t('customers.new.saving')}
                                    loading={loading}
                                    typeButton="submit"
                                    color="primary"
                                    id="button-save"
                                />
                            </CardFooter>
                        </Card>
                        <Snackbar
                            place="tr"
                            color={this.state.alertColor}
                            message={this.state.alertMessage}
                            open={this.state.alertOpen}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

NewCustomer.propTypes = {
    t: PropTypes.func.isRequired,
    onGetDocumentTypes: PropTypes.func.isRequired,
    onAddCustomer: PropTypes.func.isRequired,
}

export default withTranslation()(NewCustomer);
