import BaseInvoker from "../BaseInvoker"
import { getPropsType, deletePropsType, postPropsType, putPropsType } from "./types";

const urlInvoke = '/api';
const baseInvoker = new BaseInvoker()

export const getCustomerResources = ({customerId,callback, callerror}:getPropsType) => {
    baseInvoker.get(`${urlInvoke}/customer/${customerId}/resources`, callback, callerror)

}

export const postCustomerResources = ({customerId, body, callback, callerror}:postPropsType) => {
 baseInvoker.post(`${urlInvoke}/customer/${customerId}/resources`,body, callback, callerror)
}

export const putCustomerResources = ({resourceId, body, callback, callerror}:putPropsType) => {
 baseInvoker.put(`${urlInvoke}/customer/${resourceId}/resources/update`,body, callback, callerror)

}

export const deleteCustomerResources = async ({resourceId, callback, callerror}:deletePropsType) => {
 baseInvoker.delete(`${urlInvoke}/customer/${resourceId}/resources/delete`, {}, callback, callerror)
}