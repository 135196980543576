import BaseInvoker from './BaseInvoker'

const baseInvoker = new BaseInvoker()
const urlInvoke = '/api'

export const getAffiliateById = ({
  value,
  callback,
  callerror,
}: {
  value: string
  callback: () => {}
  callerror: () => {}
}) => {
  baseInvoker.get(
    `${urlInvoke}/customer/affiliated?affiliatedId=${value}&actives=true`,
    callback,
    callerror
  )
}
