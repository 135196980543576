import React, { useEffect, useState } from 'react'
// Se importan ambos estilos para poder elegir según el contexto
import '../HospitalizationForm/HospitalizationStyles.css'
import '../DiabeticProgram/DiabeticProgramStyles.css'


import Card from '../Card/Card.jsx'
import CardBody from '../Card/CardBody.jsx'
import CardHeader from '../Card/CardHeader.jsx'
import CardIcon from '../Card/CardIcon.jsx'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import HotelIcon from '@material-ui/icons/Hotel'
import PersonApiInvoker from '../../api/PersonApiInvoker'
import SelectInput from '../SelectInput/index.js'
import ValidationInput from '../ValidationInput'

const FormPatients = (props) => {
  const {
    setDataPatientForm,
    setIsValidatedPatientForm,
    setPersonId,
    editData,
    save,
    onlyView,
    id,        
    variant,   
  } = props

  // Estados locales
  const [patient, setPatient] = useState(null)
  const [identification, setIdentification] = useState(null)
  const [direction, setDirection] = useState(null)
  const [zone, setZone] = useState(null)
  const [location, setLocation] = useState(null)
  const [province, setProvince] = useState(null)
  const [doctor, setDoctor] = useState(null)
  const [medicalRecord, setMedicalRecord] = useState(null)
  const [isValidated, setIsValidated] = useState(false)
  const [patientsOptions, setPatientsOptions] = useState([])
  const [addressOptions, setAddressOptions] = useState([])
  const [generalPractitionerOptions, setGeneralPractitionerOptions] = useState([])

  // Objeto con los datos que se enviarán
  let data = {
    patient,
    identification,
    direction,
    zone,
    location,
    province,
    doctor,
    medicalRecord,
  }

  const setEditData = (data) => {
    PersonApiInvoker.getPersonsAddress(data.patient, false, (response) => {
      formatAddress(response[0])
    })
    if (!patient) {
      setPersonId(data.patient)
      setPatient(data.patient)
      setIdentification(data.identification)
      setDirection(data.direction)
      setLocation(data.location)
      setProvince(data.province)
      setZone(data.zone)
      setDoctor(data.doctor)
      setMedicalRecord(data.medicalRecord)
    }
  }

  const getGeneralPractitioner = () => {
    PersonApiInvoker.getGeneralPractitioner(
      (data) => {
        if (data && data.length) {
          const result = formatGeneralPractitioner(data)
          setGeneralPractitionerOptions(result)
        }
      },
      (e) => {}
    )
  }

  const formatGeneralPractitioner = (generalPractitioners) => {
    return generalPractitioners.map((d) => ({
      id: parseInt(d.generalPractitionerId),
      value: `${d.name} ${d.lastName}`,
      registration: d.medicalRegistrationNumber,
    }))
  }

  // Formateo para clientes internos
  const formatCustomers = (customers) => {
    return customers.map((e) => ({
      id: e.personId,
      userId: e.id,
      value: `${e.firstName} ${e.lastName}`,
    }))
  }

  // Formateo para clientes disponibles (usado en el segundo archivo)
  const formatCustomersAvailable = (customers) => {
    return customers.map((e) => ({
      id: e.customer.personId,
      userId: e.customer.id,
      value: `${e.customer.firstName} ${e.customer.lastName}`,
    }))
  }

  const formatAddress = (address) => {
    setAddressOptions([
      {
        id: `${address.street} ${address.number}`,
        value: `${address.street} ${address.number}`,
        geographicZone: address.geographicZone.detail,
        location: address.location.name,
        province: address.provinceName,
      },
    ])
  }

 
  const setDataPatient = () => {
    
    if (typeof id !== 'undefined') {
      if (id) {
        
        PersonApiInvoker.getCustomersIntern((data) => {
          let dataPatient = formatCustomers(data)
          setPatientsOptions(dataPatient)
        })
      } else {
        
        PersonApiInvoker.getCustomersAvailable((data) => {
          let dataPatient = formatCustomersAvailable(data)
          setPatientsOptions(dataPatient)
        })
      }
    } else {
     
      PersonApiInvoker.getCustomersIntern((data) => {
        let dataPatient = formatCustomers(data)
        setPatientsOptions(dataPatient)
      })
    }
  }

  const validateForm = () => {
    if (doctor && patient && addressOptions) {
      setIsValidated(true)
    } else {
      setIsValidated(false)
    }
  }

  const customSelectOption = (value) => {
    setPatient(value)
    PersonApiInvoker.getCustomer(value, (response) => {
      setIdentification(response.customer.id)
      setDoctor(
        response.customer.generalPractitionerId
          ? response.customer.generalPractitionerId.generalPractitionerId
          : null
      )
      setMedicalRecord(
        response.customer.generalPractitionerId
          ? response.customer.generalPractitionerId.medicalRegistrationNumber
          : ''
      )
    })
    PersonApiInvoker.getPersonsAddress(value, false, (data) => {
      formatAddress(data[0])
      setPersonId(value)
    })
  }

  useEffect(() => {
    setDataPatient()
    getGeneralPractitioner()
  
    if (editData) {
      setEditData(editData)
    }
  }, [editData])
  
  useEffect(() => {
    validateForm()
    setIsValidatedPatientForm(isValidated)
    setDataPatientForm(data)
  }, [
    patient,
    identification,
    direction,
    zone,
    location,
    province,
    doctor,
    medicalRecord,
    addressOptions,
    isValidated,
  ])

  return (
    <Card className={variant === 'hospitalization' ? 'hospitalization-style' : 'diabetic-style'}>
      <CardHeader color="success" icon>
        <CardIcon color="primary">
          <HotelIcon />
        </CardIcon>
        <h4>Paciente</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <SelectInput
              label="Paciente *"
              onSelectedValue={(value) => customSelectOption(value)}
              elements={patientsOptions}
              value={patient}
              invalid={!patient && save}
              disabled={editData || onlyView}
            ></SelectInput>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <ValidationInput
              value={identification ? identification : ''}
              text="Identificación"
              id="identification"
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: 'text' }}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <SelectInput
              className="select-input"
              label="Dirección *"
              onSelectedValue={(value) => setDirection(value)}
              elements={addressOptions}
              value={addressOptions.length > 0 ? addressOptions[0] : ''}
              invalid={!addressOptions.length && save}
              disabled={editData || onlyView}
            ></SelectInput>
          </GridItem>
          <GridItem md={6}>
            <ValidationInput
              value={
                addressOptions.length > 0
                  ? addressOptions[0].geographicZone
                  : zone
                  ? zone
                  : ''
              }
              text="Zona geográfica"
              id="zone"
              disabled={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: 'text' }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <ValidationInput
              value={
                addressOptions.length > 0
                  ? addressOptions[0].location
                  : location
                  ? location
                  : ''
              }
              text="Localidad"
              id="location"
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: 'text' }}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <ValidationInput
              value={
                addressOptions.length > 0
                  ? addressOptions[0].province
                  : province
                  ? province
                  : ''
              }
              text="Provincia"
              id="province"
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: 'text' }}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <SelectInput
              label="Médico de cabecera *"
              onSelectedValue={(value) => setDoctor(value)}
              elements={generalPractitionerOptions}
              id="doctor"
              value={doctor}
              disabled={true}
              invalid={!doctor && save}
            ></SelectInput>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <ValidationInput
              value={medicalRecord ? medicalRecord : ''}
              text="Registro médico"
              id="medical_record"
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: 'text' }}
              disabled={true}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormPatients
