import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { color: string }>((_theme) => ({
    container: {
        padding: '1rem',
        backgroundColor: ({ color }) => color, 
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'left',
        alignItems: 'center',
        gap: '0.5rem',
        fontWeight: 600,
    }
}));

export default useStyles;