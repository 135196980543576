import { Add, Work } from '@material-ui/icons'
import { Card, makeStyles } from '@material-ui/core'
import { CardBody, CardFooter, CardHeader, CardIcon } from '../Card'

import Button from '../CustomButtons/Button'
import ConfirmAlert from '../ConfirmAlert'
import CreateFinancier from './CreateFinancier'
import CustomDialog from '../CustomDialog'
import { CustomerFinancier } from '../../core'
import PropTypes from 'prop-types'
import React from 'react'
import Snackbar from '../Snackbar/Snackbar'
import { SnackbarColor } from '../../util/interfaces'
import Table from '../Table/Table'
import { useFinanciers } from './hooks/useFinanciers'

type ComponentProps = {
  componentMode: string;
  personId: number;
}

const useStyles = makeStyles(() => ({
  financiersList: {
    fontSize: 14,
    overflow: 'visible',
    marginBottom: 20,
    '& .card-icon-title': {
      paddingTop: 15,
    },
    '& .content-button-add': {
      paddingBottom: '10px !important',
    },
  },
}))
const Financiers = ({ componentMode, personId }: ComponentProps) => {
  const classes = useStyles()
  const {
    t,
    closeAlert,
    onDeleteFinancier,
    setOpenForm,
    onCreateFinancier,
    openSnackbar,
    rebuildFinanciersOnUpdate,
    buildFinancier,
    deleteAlert,
    success,
    alertMessage,
    openForm,
    title,
    tableData,
    formAction,
    financier,
    snackbar,
  } = useFinanciers(personId, componentMode);

  return (
    <>
      <Card className={classes.financiersList}>
        <CardHeader icon>
          <CardIcon color="secondary">
            <Work />
          </CardIcon>
          <h3 className="card-icon-title">{title}</h3>
        </CardHeader>
        <CardBody className="financers-table">
          <Table {...tableData} />
        </CardBody>
        {componentMode !== 'view' && (
          <CardFooter className="content-button-add flex-end">
            <Button
              id="button-add-financer"
              className="edit-customer-create"
              color="primary"
              onClick={() => onCreateFinancier()}
            >
              <Add />
              {t('customers.new.create.financer')}
            </Button>
          </CardFooter>
        )}
      </Card>

      <ConfirmAlert
        show={deleteAlert}
        text={t('common.financier')}
        onClose={() => closeAlert()}
        onConfirm={() => onDeleteFinancier()}
        success={success}
        onSuccess={() => closeAlert()}
        messageDeletedUnsuccess={alertMessage}
        showCancelSuccess={false}
      />

      <CustomDialog
        title={t('financer.title')}
        open={openForm}
        onClose={() => setOpenForm(false)}
      >
        <CreateFinancier
          formAction={formAction}
          financierData={financier}
          personId={personId}
          onFinancierSubmitted={(
            data: CustomerFinancier,
            notification: string
          ) => {
            setOpenForm(false)
            const newFinancier = buildFinancier(data)
            rebuildFinanciersOnUpdate(newFinancier)
            openSnackbar(SnackbarColor.success, notification)
          }}
        />
      </CustomDialog>

      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message || alertMessage}
        open={snackbar.open}
      />
    </>
  )
}

Financiers.propTypes = {
  t: PropTypes.func,
  personId: PropTypes.number,
  componentMode: PropTypes.string,
  actionEdit: PropTypes.func, 
};
export default Financiers
