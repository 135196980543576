import { InfoOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../../Table/Table';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import config from '../../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateTimeSmallFormat = config.getDateTimeSmallFormat();
const TableDataComponents = (props) => {
  const { classes, loadingevents, data, showTooltip, t, title } = props;
  console.log("la data",data)
  const getSortMethod = (a, b) => {
    let a1 = moment(a, dateTimeSmallFormat).valueOf();
    let b1 = moment(b, dateTimeSmallFormat).valueOf();
    if(a1 > b1) { return 1;} 
    else if (a1 < b1) { return -1;} 
    else {
      return 0;
    }
  }
  return (
    <>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7, classes.titleWidthIconEnd)}>
          <span>
            {title}
          </span>
          {showTooltip &&
            <Tooltip 
              placement="right" 
              title={t('tooltipInfo.schedulesNotAssigned')}
            >
              <InfoOutlined />
            </Tooltip>
          }
        </h4>
        <Table
          sortable={true}
          loading={loadingevents}
          tableHeaderColor="primary"
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.transferId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('common.date'), accessor: 'scheduleDateTime', sortMethod: (a, b) => getSortMethod(a, b) },
            { Header: t('service.TypeOfTransfer'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.notasigned.table.customer'), accessor: 'customer' },
            { Header: t('reassignAppointments.notasigned.table.location'), accessor: 'location' },
            { Header: t('reassignAppointments.notasigned.table.geographicZone'), accessor: 'geographicZone' },
          ]}
          tableData={data}
          colorsColls={['primary']}
          defaultPageSize={data?.length > 5 ? 20 : 5}
        />
      </>
  )
}

TableDataComponents.propTypes = {
  classes: PropTypes.object,
  loadingevents: PropTypes.bool,
  data: PropTypes.array,
  showTooltip: PropTypes.bool,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default withTranslation()(TableDataComponents);