import { useEffect, useState } from 'react';

import ApiInvoker from '../api/ApiInvoker';
import PersonApiInvoker from '../api/PersonApiInvoker';

export const useVehicleData = (t) => {

  const [documentTypes, setDocumentTypes] = useState([]);
  const [genderTypes, setGenderTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [types, setTypes] = useState([]);
  const [wheelChairSuitable, setWheelChairSuitable] = useState([])

  const formatGenderTypes = (genderTypes) => {
    const genderTypesValue = {
      UNDEFINED: t('label.undefined'),
      MALE: t('label.gender.male'),
      FEMALE: t('label.gender.female'),
    }
    return genderTypes.map(genderType => ({
      id: genderType,
      value: genderTypesValue[genderType],
    }));
  };

  const formatWheelChairSuitable = () => {
    return [
      { id: true, value: t('form.option.yes'), name: t('form.option.yes') },
      { id: false, value: t('form.option.no'), name: t('form.option.no') },
    ];
  };

  const getGenderTypes = () => {
    PersonApiInvoker.getGenderTypes(data => {
      const d = formatGenderTypes(data);
      setGenderTypes(d);
    }, null);
  };

  const getWheelChairSuitable = ()=>{
    const data = formatWheelChairSuitable();
    setWheelChairSuitable(data);
  }

  const formatBrands = (data) => data
  .filter(brand => brand.name !== undefined && brand.name !== null && brand.name !== "")
  .map(brand => ({
    id: brand.vehicleBrandId,
    name: brand.name,
    vehicleBrandId:brand.vehicleBrandId,
    
  }));

  const getBrands = () => {
    ApiInvoker.getVehiclesBrand(data => {
      const brand = formatBrands(data)
      setBrands(brand);
    }, null);
  }
  const formatModels = (data) => data.filter(model => model.name !== undefined && model.name !== null)
  .map(model => ({
    id: model.vehicleModelId,
    name: model.name,
    vehicleModelId:model.vehicleModelId,
    vehicleBrandId:model.vehicleBrand.vehicleBrandId
  }));

  const getModels = () => {
    ApiInvoker.getVehiclesModel(model => {
      const models = formatModels(model);
      setModels(models);
    }, null);
  }

  const getTypes = ()=>{
    setTypes([
      {id: 'AMBULANCE', value:'AMBULANCIA', name: 'AMBULANCIA'},
      {id: 'CAMIONETA', value:'CAMIONETA', name: 'CAMIONETA'},
      {id: 'COMPACTO', value:'COMPACTO', name: 'COMPACTO'},
      {id: 'MINIVAN', value:'MINI VAN', name: 'MINI VAN'},
      {id: 'SEDAN', value:'SEDAN', name: 'SEDAN'},
      {id: 'SUV', value:'SUV', name: 'SUV'},
      {id: 'VAN', value:'VAN', name: 'VAN'},
    ]);
  }

  useEffect(() => {
    getGenderTypes();
    getBrands();
    getModels();
    getTypes()
    getWheelChairSuitable()
   
    return () => {
      setDocumentTypes([]);
      setGenderTypes([]);
      setModels([]);
      setTypes([]);
      setWheelChairSuitable([]);
    }
  }, []);

  return {
    documentTypes,
    genderTypes,
    brands,
    models,
    types,
    wheelChairSuitable
  }
}