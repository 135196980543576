import './MedicalRecordSignVitals.css';

import { HeartWithPulse, Signature, TextSnippet } from '../../icons';
import React, { Component } from 'react';

import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Button from '../CustomButtons/Button';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomInput from '../CustomInput/CustomInput';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PropTypes from 'prop-types';
import SignatureModal from './SignatureModal/SignatureModal';
import { Tooltip } from '@material-ui/core';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateTimeSmallFormat = config.getDateTimeSmallFormat();
const dateTimeFormat = config.getDateTimeFormat();
const dateFormat = config.getDateFormat();
const dateTimeToServer = 'YYYY-MM-DD HH:mm:SS';

class MedicalRecordSignVitals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulesVitalSigns: this.props?.scheduleVitalSigns,
            VitalSignsOne: [],
            vitalSigns: [],
            scheduleInformation: {
                scheduleMedicines: [],
                vitalSignList: [],
                note: '',
                notes: [],
                scheduleId: '',
                employeeName: '',
                practiceName: '',
                type: '',
                startDateTime: '',
                endDateTime: '',
                scheduleStatus: '',
                scheduledStartDateTime: '',
                scheduledEndDateTime: '',
            },
            resources: [],
            scheduleSelected: this.props?.scheduleSelected,
            openSignature: false,
        }
    }

    saveFile = (url) => {
        window.open(url, '_blank');
    };

    componentDidMount() {
        this.getScheduleInformation(this.props.params.scheduleId);
        this.getResources(this.props.params.scheduleId);
    }

    getScheduleInformation = async (scheduleId) => {
        AppointmentApiInvoker.getScheduleInformation(scheduleId, data => {
            this.setState({ scheduleInformation: data });
        }, (error) => {
            console.error('** error getScheduleInformation', error);
        });
    }

    getResources(scheduleId) {
        AppointmentApiInvoker.getScheduleResources(scheduleId, resources => {
            this.setState({ resources });
        }, (error) => {
            console.error('** error getScheduleResources', error);
        });
    }

    onBack = () => browserHistory.goBack();

    toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    formatDatetime(date, format) {
        return new moment(date, [dateTimeFormat, dateTimeToServer, 'x']).format(format);
    }

    renderVitalSigns(data) {
        const { t } = this.props;
        const dataList = data.map((d, index) => ({
            ...d,
            key: index+1,
        }))
        return dataList.map((item) => {
            const date = this.formatDatetime(item.timeRegistry, dateFormat);
            const time = this.formatDatetime(item.timeRegistry, 'hh:mm a');
            
            return (
                <GridContainer key={`vs-${item.key}`} className="container multi-record">
                    <GridItem xs={12}>
                        <p className="record-header">{t('medical.record.detail.medicines', {
                            date,
                            time,
                        })}</p>
                    </GridItem>
                    <GridItem xs={6}>
                        <CustomInput
                            id="temperature"
                            disabled={true}
                            labelText={t('medical.record.detail.temperature')}
                            value={item.temperature ? `${item.temperature} °C` : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <CustomInput
                            id="pulse"
                            disabled={true}
                            labelText={t('medical.record.detail.pulse')}
                            value={item.pulse ? `${item.pulse} ${t('scheduleDetail.vital_signs.pulse.unit')}` : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <CustomInput
                            id="breathing"
                            disabled={true}
                            labelText={t('medical.record.detail.oximeter')}
                            value={item.breathing ? `${item.breathing}%`: '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <CustomInput
                            id="pressure"
                            disabled={true}
                            labelText={t('medical.record.detail.presure')}
                            value={ (item.pressure?.min && item.pressure?.max) ?`${item.pressure.max}/${item.pressure.min}mmHg` : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            )
        })
    }

    renderMedicines(data) {
        const { t } = this.props;
        return data.map((item, index) => {
            const date = this.formatDatetime(item.medicineDateTime, dateFormat);
            const time = this.formatDatetime(item.medicineDateTime, 'hh:mm a');
            const key = index+1;
            let expire = '-';
            if (item.medicineExpireDate != null) {
                expire = this.toTitleCase(new moment(item.medicineExpireDate).format('MMMM YYYY'));
            }
            return (
                <GridContainer key={`med-${key}`} className="container multi-record">
                    <GridItem xs={12}>
                        <p className="record-header">{t('medical.record.detail.medicines', {
                            date, time,
                        })}</p>
                    </GridItem>

                    <GridItem xs={3}>
                        <CustomInput
                            id="medicine"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.medicine')}
                            value={item.medicineType ? item.medicineType.name : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={3}>
                        <CustomInput
                            id="presentation"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.presentation')}
                            value={item.medicinePresentationType ? item.medicinePresentationType.name : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={2}>
                        <CustomInput
                            id="dose"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.dose')}
                            value={`${(item.medicineDose ?? '-')} ${(item.medicineUnitType.name ?? '-')}`}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={2}>
                        <CustomInput
                            id="quantity"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.quantity')}
                            value={item.medicineQuantity}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={2}>
                        <CustomInput
                            id="via"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.route')}
                            value={item.medicineViaType ? item.medicineViaType.name : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={3}>
                        <CustomInput
                            id="lot"
                            disabled={true}
                            labelText={t('medical.record.detail.medicines.batch')}
                            value={item.medicineLotNumber ? item.medicineLotNumber : '-'}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={3}>
                        <CustomInput
                            id="expire"
                            disabled={true}
                            labelText={t('common.dueDate')}
                            value={expire}
                            formControlProps={{
                                disabled: true,
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            );
        });
    }

    renderNote(date, note) {
        const { t } = this.props;
        return (
            <GridContainer className="container multi-record">
                <GridItem xs={12}>
                    <p className="record-header">{t('medical.record.detail.medicines', {
                        date: this.formatDatetime(date, dateFormat),
                        time: this.formatDatetime(date, 'hh:mm a'),
                    })}</p>
                </GridItem>
                <GridItem xs={12}>
                    <p className="record-text">{note ?? '-'}</p>
                </GridItem>
            </GridContainer>
        )
    }

    renderNotes(data) {
        return data.map(note => this.renderNote(note.date, note.text));
    }

    renderResources(data) {
        const { t } = this.props;
        return data.map((item, index) => {
            const date = this.formatDatetime(item.scheduleResourceUploadDate, dateFormat);
            const time = this.formatDatetime(item.scheduleResourceUploadDate, 'hh:mm a');
            return (
                <GridContainer key={`res-${item.scheduleResourceId}`} className="container multi-record">
                    <GridItem xs={12}>
                        <p className="record-header">{t('medical.record.detail.resource', {
                            date, time,
                        })}</p>
                    </GridItem>
                    <GridItem xs={6}>
                        <img className="resource-img" src={item.fullURL} style={{ width: 100 }} alt="hmm" />
                    </GridItem>
                    <GridItem xs={6}>
                        <p className="file">{t('medical.record.detail.resource.file')}</p>
                        <Button link className="link" onClick={() => this.saveFile(item.fullURL)}>
                            {item.scheduleResourceName}
                        </Button>
                    </GridItem>
                </GridContainer>
            );
        })
    }

    showSignature = () => this.setState({ openSignature: true })

    renderNoData = (t) => <p className="no-data">{t('common.no.data')}</p>

    formatPatientWeight = (scheduleInformation) => {
        const { t } = this.props;
        if (!scheduleInformation.patientWeight) {
            return '-';
        }
    
        const { patientWeight, weightUnit } = scheduleInformation;
        const keyTranslate = `common.weight.unit.${weightUnit}`;
        return `${patientWeight} ${t(keyTranslate)}`;
    };

    render() {
        const { t } = this.props;
        const { resources, scheduleInformation, openSignature } = this.state;
        let glucose = '-';
        
        if (scheduleInformation?.capillaryGlucoseDetail) {
            glucose =
                scheduleInformation.capillaryGlucoseDetail.capillaryGlucoseValue + " " +
                (scheduleInformation.capillaryGlucoseUnitType ? scheduleInformation.capillaryGlucoseUnitType.name + " " : "") +
                t(`common.glucose.unit.${scheduleInformation.capillaryGlucoseDetail.capillaryGlucoseClassificationType}`)
        }
        const scheduledStartDateTime = new moment(scheduleInformation.scheduledStartDateTime).format(dateTimeSmallFormat);
        const startDateTime = new moment(scheduleInformation.startDateTime).format(dateTimeSmallFormat);
        const scheduledEndDateTime = new moment(scheduleInformation.scheduledEndDateTime).format(dateTimeSmallFormat);
        const endDateTime = new moment(scheduleInformation.endDateTime).format(dateTimeSmallFormat);
        let hasData = false;
        if (
                scheduleInformation.patientWeight || 
                scheduleInformation.capillaryGlucoseDetail ||
                scheduleInformation.note 
            ) {
            hasData = true;
        }
        const infoType = scheduleInformation.type === 'Practica' ? t('label.practice') : t('label.module');
        const shouldRenderNotes = !!(scheduleInformation?.notes?.length);
        const valuePatientWeight = this.formatPatientWeight(scheduleInformation);

        return (
            <>
                <GridContainer>
                    <GridItem xs={12} className="medical-record-schedule">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <CalendarToday />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('medical.record.schedule.title')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-information">
                                <GridContainer className="container medical-record-customer">
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="schedule_id"
                                            disabled={true}
                                            labelText={t('medical.record.detail.scheduleId')}
                                            value={scheduleInformation.scheduleId}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="status"
                                            disabled={true}
                                            labelText={t('medical.record.detail.status')}
                                            value={t(`schedule.booking.state.${scheduleInformation.scheduleStatus}`)}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="start_date"
                                            disabled={true}
                                            labelText={t('medical.record.detail.start_date_programed')}
                                            value={scheduledStartDateTime}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="real_start_date"
                                            disabled={true}
                                            labelText={t('medical.record.detail.start_date_real')}
                                            value={startDateTime}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="end_date"
                                            disabled={true}
                                            labelText={t('medical.record.detail.end_date_programed')}
                                            value={scheduledEndDateTime}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="real_end_date"
                                            disabled={true}
                                            labelText={t('medical.record.detail.end_date_real')}
                                            value={endDateTime}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="type"
                                            disabled={true}
                                            labelText={t('medical.record.detail.type')}
                                            value={infoType}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="practice_name"
                                            disabled={true}
                                            labelText={t('medical.record.detail.practice')}
                                            value={scheduleInformation.practiceName}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="employe_name"
                                            disabled={true}
                                            labelText={t('medical.record.detail.employee')}
                                            value={scheduleInformation.employeeName}
                                            formControlProps={{
                                                disabled: true,
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <p className="signature-item padding-horizontal">
                                            <Tooltip title={t('label.signaturePatient.view')}>
                                                <Button link onClick={() => this.showSignature()} className="link">
                                                    <Signature /> <span>{t('label.signaturePatient.title')}</span>
                                                </Button>
                                            </Tooltip>
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} className="medical-record-vitalsigns">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <HeartWithPulse />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('medical.record.signvitals.title')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-vital-sign-list">
                                {scheduleInformation?.vitalSignList?.length > 0
                                ? this.renderVitalSigns(scheduleInformation.vitalSignList)
                                : this.renderNoData(t)}
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} className="medical-record-medicine">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <LocalHospitalIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('medical.record.medicine.title')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-medicines">
                            {scheduleInformation?.scheduleMedicines?.length > 0 ?
                                this.renderMedicines(scheduleInformation.scheduleMedicines)
                                    : this.renderNoData(t)}
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} className="medical-record-notes">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <TextSnippet />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('common.clinicalNotes')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-notes">
                                {scheduleInformation.note &&
                                    this.renderNote(scheduleInformation.endDateTime, scheduleInformation.note)
                                }
                                {shouldRenderNotes && this.renderNotes(scheduleInformation.notes)}
                                {!shouldRenderNotes && !scheduleInformation.note &&
                                    this.renderNoData(t)
                                }
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} className="medical-record-other">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <NoteAddIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('medical.record.other.title')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-information">
                                {hasData ?
                                    <GridContainer className="container">
                                        <GridItem xs={12}>
                                            <CustomInput
                                                id="patient-weight"
                                                disabled={true}
                                                labelText={t('medical.record.detail.weight')}
                                                value={valuePatientWeight}
                                                formControlProps={{
                                                    disabled: true,
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <CustomInput
                                                id="patient-glucose"
                                                disabled={true}
                                                labelText={t('medical.record.detail.glucose')}
                                                value={glucose}
                                                formControlProps={{
                                                    disabled: true,
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                : this.renderNoData(t)}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} className="resources-detail">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <AttachmentIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('medical.record.images.title')}</h3>
                            </CardHeader>
                            <CardBody className="schedule-resources">
                                {resources != null && resources.length > 0 ?
                                    this.renderResources(resources)
                                    : this.renderNoData(t)
                                }
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem className="actions-buttons text-center">
                        <Button id="button-back" onClick={() => this.onBack()}>
                            {t("appointment.new.back")}
                        </Button>
                    </GridItem>
                </GridContainer>

                {(scheduleInformation.scheduleId && openSignature) &&
                    <SignatureModal
                        open={openSignature}
                        scheduleId={scheduleInformation.scheduleId}
                        onCloseSignature={(value) => this.setState({ openSignature: value })}
                    />
                }
            </>

        )
    }
}

MedicalRecordSignVitals.propTypes = {
    t: PropTypes.func,
    scheduleVitalSigns: PropTypes.array,
    scheduleSelected: PropTypes.object,
    params: PropTypes.object,
}

export default withTranslation()(MedicalRecordSignVitals)
