import '../../../NewSchedule.css';
import './Modules.css';

import { Check, InfoOutlined } from '@material-ui/icons';
import { Checkbox, FormControlLabel, Tooltip, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../../../api/AppointmentApiInvoker';
import Button from '../../../../CustomButtons/Button';
import CustomSweetAlert from '../../../../CustomSweetAlert';
import DateInput from '../../../../DateInput';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import ModalConfigurePractices from './ModalConfigurePractices';
import ModalSuggestedProfessionals from './ModalSuggestedProfessionals';
import PropTypes from 'prop-types';
import SelectInput from '../../../../SelectInput';
import Snackbar from '../../../../Snackbar/Snackbar';
import TextareaInput from '../../../../TextareaInput';
import ValidationInput from '../../../../ValidationInput';
import config from '../../../../../config/config';
import moment from 'moment';
import styles from '../../../../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateToServer();

class Modules extends Component {
  constructor(props) {
    super(props)
		const { t } = props;

    const selector = [
      { id: 1, value: t('appointment.new.schedule.lender.me'), component: true, },
      { id: 2, value: t('appointment.new.schedule.lender.third'), component: false, }
    ];

    this.state = {
      modules: [],
      module: '',
      module_selected: {},
      id: 0,
      startTime: '10:00',
      endTime: '23:30',
      save: false,
      startDate: '',
      estimatedDuration: '',
      openConfigurePractices: false,
      practices: [],
      checkDateStart: false,
      dateStart: null,
      warningDates: false,
      checkDateEnd: false,
      checkQuantity: false,
      packageQuantity: 0,
      packageQuantityOriginal: 0,
      dateEnd: null,
      startDateOriginal: null,
      endDateOriginal: null,
      showAlertChangeDate: false,
      showAlertChangeDateStartDuration: false,
      errorDateStartAfterDateEndAppointment: false,
      showAlertChangeDuration: false,
      showAlertChangeDurationDate: false,
      alertMessageColor: '',
      alertMessageMsg: '',
      alertMessageOpen: false,
			third_parties: [],
			selector,

      third_parties_new: [],
      third_parties_codes: [],
      lender: null,
      lenderCode: null,
    }
  }

  setPartnership_new = (data) => {
    const partnership = data.map(p => ({
        id: p.companyIdPartnership.companyId,
        value: p.companyIdPartnership.name,
        code: p.companyIdPartnership.partnerCode.toString(),
    }));

    const id = parseInt(localStorage.getItem('itlg_default_company_id'));
    const value = localStorage.getItem('itlg_default_company_name');
    const code = id.toString();
    const partnershipArray = [{ id, value, code }, ...partnership];

    return partnershipArray;
  }

  getPartnership_new = () => {
    if (this.state.module) {
      AppointmentApiInvoker.getCompanyPartnership(this.state.module, data => {
          const third_parties_new = this.setPartnership_new(data);
          const third_parties_codes = third_parties_new.map(tp => ({
              id: tp.id,
              value: tp.code
          }));
          
          this.setState(prevState => {
              const newLender = prevState.lender || (third_parties_new.length ? third_parties_new[0].id : null);
              const newLenderCode = prevState.lenderCode || newLender;
              
              return {
                  third_parties_new,
                  third_parties_codes,
                  lender: newLender,
                  lenderCode: newLenderCode
              };
          });
      }, () => {
          this.setState({ third_parties_new: [], third_parties_codes: [] });
      });
  }
}

handleLenderChange_new = (value) => {
  this.setState({ 
    lender: value, 
    lenderCode: value 
  });
}

  setStateOnComponentAction() {
    const { current, dates } = this.props;
    if (current) {
      const practices = this.itemRequestPackageDetailServerToScreen(current.item.itemRequestPackageDetails, current.packageId,current.file);
      const lender = this.isJSON(current.item.companyIdPartnership) ? current.item.companyIdPartnership.companyId : current.item.companyIdPartnership;
      this.setState({
        id: current.id,
        module: current.packageId,
        startDate: current.item.startDate,
        endDate: current.item.endDate || dates.endDate,
        startDateOriginal: current.item.startDate,
        endDateOriginal: current.item.endDate || dates.endDate,
        startTime: current.item.startTime,
        endTime: current.item.endTime,
        itemRequestDetails: current.item.itemRequestDetails,
        order: current.item.order,
        packageQuantity: current.item.packageQuantity,
        packageQuantityOriginal: current.packageQuantity,
        practices,
        lender,
        lenderCode: lender,
        file:current.itemRequestPackageDetails[0]?.file
      }, () => {
        this.getPartnership_new();
      } );
    }
  }

  setStateOnSpecialtyOrModule() {
    const { current } = this.props;
    const lender = this.isJSON(current.companyIdPartnership) ? current.companyIdPartnership.companyId : current.companyIdPartnership;
    const data = {
      id: current.id,
      module: current.module.packageId,
      order: current.order,
      itemRequestDetails: current.itemRequestDetails,
      practices: current.itemRequestPackageDetails,
      packageQuantity: current.packageQuantity,
      lender,
      file:current.itemRequestPackageDetails[0].file
    }
    if (!current.specialty) {
      this.setState({
        ...data,
        startDate: current.startDate,
        endDate: current.endDate,
        startTime: current.startTime,
        endTime: current.endTime,
      });
      return;
    }
    this.setState({
      ...data,
      startDate: current.module.startDate,
      endDate: current.module.endDate,
      startTime: current.module.startTime,
      endTime: current.module.endTime,
    });
  }

  componentDidMount() {
    const { appointmentAction, current, dates, modules } = this.props;
    this.getPartnership();
    this.getPartnership_new();

    if (modules) {
      this.setState({ modules: this.formatModules(modules) });
    } else {
      this.loadModules();
    }

    this.getModuleTypes();
    if (dates) {
      this.setState({
        startDate: dates.startDate,
        endDate: dates.endDate,
        startDateOriginal: dates.startDate,
        endDateOriginal: dates.endDate,
      });
    }

    const isComponentAction = appointmentAction === 'assign_agenda' || appointmentAction === 'edit' || appointmentAction === 'create' || appointmentAction === 'copy';

    if (isComponentAction) {
      this.setStateOnComponentAction();
      return;
    }
    if (current?.module) {
      this.setStateOnSpecialtyOrModule();
    }
    this.getPartnership_new();
  }

  isJSON = (str) => !!str?.companyId;

  buildPartnershipData = (data) => {
		const partnership = data.map(p => ({
			id: p.companyIdPartnership.companyId,
      value: p.companyIdPartnership.name,
		}));

		const id = parseInt(localStorage.getItem('itlg_default_company_id'));
		const value = localStorage.getItem('itlg_default_company_name');
		const partnershipArray = [{ id, value }, ...partnership];
    return partnershipArray;
	}

  getPartnership = () => {
    const { lender } = this.state;
    AppointmentApiInvoker.getCompanyPartnershipModules(data => {
      const third_parties = this.buildPartnershipData(data);
      if (!this.props?.appointmentAction && !lender) {
        const newLender = third_parties.length ? third_parties[0].id : lender;
        this.setState({ lender: newLender });
      }
			this.setState({ third_parties });
    }, () => {
      this.setState({ third_parties: [] });
    });
  }

  buildRowPractice(serverItem, moduleRow) {
    const row = moduleRow;
    const serverPracticeType = serverItem.practiceType?.practiceTypeId || serverItem.practiceTypeId;
    if (moduleRow.practiceType.practiceTypeId !== serverPracticeType) {
      return row;
    }
    if(serverItem.file){
      row['file']=serverItem.file
    }
    if (serverItem.endTime) {
      const endTime = moment(serverItem.endTime, 'HH:mm:SS').format('hh:mm a');
      row['endTime'] = endTime;
    }
    if (serverItem.startTime) {
      const startTime = moment(serverItem.startTime, 'HH:mm:SS').format('hh:mm a');
      row['startTime'] = startTime;
    }
    if (serverItem.schedulePlan) {
      row['schedulePlan']['monthRecurrence'] = serverItem.schedulePlan.monthDay;
      const notWeekRecurrence = serverItem.schedulePlan?.weekDays ? serverItem.schedulePlan.weekDays.split(',') : null;
      row['schedulePlan']['weekRecurrence'] =  serverItem.schedulePlan.weekRecurrence ?? notWeekRecurrence;
    } else {
      row['schedulePlan']['monthRecurrence'] = serverItem.monthDay;
      const notWeekRecurrence = serverItem?.weekDays ? serverItem.weekDays.split(',') : null;
      row['schedulePlan']['weekRecurrence'] = serverItem.weekRecurrence ?? notWeekRecurrence;
    }
    row['observations'] = serverItem.observations;
    return row;
  }

  getPractices = (items, moduleinfo,file) => {
    const practices = [];
    moduleinfo.packageDetail.forEach(m => {
      let row = m;
      if (m.schedulePlan.processingScheduleType === 'INTRADAY') {
        row['schedulePlan'].recurrenceType = 'INTRADAY';
      }
      if(file){
        row['file']=file[0] || file
      }
      items.forEach(serverItem => {
        row = this.buildRowPractice(serverItem, row);
      });
      practices.push(row);
    });
    return practices;
  }

  itemRequestPackageDetailServerToScreen = (items, packageId,file) => {
    const moduleinfo = this.findModule(packageId);
    if (!moduleinfo) {
      return [];
    }

    const maxPackageQuantity = this.calculateQuantity(moduleinfo.duration);
    this.setState({ duration: moduleinfo.duration, maxPackageQuantity });

    const practices = this.getPractices(items, moduleinfo,file);
    return practices;
  }

  itemRequestPackageDetailScreenToServer = (item) => {
    const itemRequestPackageDetails = [];
    item.itemRequestPackageDetails.forEach(m => {
      const practicesFilter = this.state.practices.filter((practice) => {
        return practice.practiceType.practiceTypeId === m.practiceTypeId
      })

      if (practicesFilter.length > 0) {
        const onNotWeekRecurrence = Array.isArray(practicesFilter[0].schedulePlan.weekDays) ? practicesFilter[0].schedulePlan.weekDays.join(',') : practicesFilter[0].schedulePlan.weekDays;
        const weekDays =
          practicesFilter[0].schedulePlan.weekRecurrence ? practicesFilter[0].schedulePlan.weekRecurrence.join(',') : onNotWeekRecurrence;
        
        let startTime = practicesFilter[0].startTime;
        let endTime = practicesFilter[0].endTime;

        if (startTime != null) {
          startTime = moment(practicesFilter[0].startTime, 'hh:mm a').format('HH:mm');
        }
        if (endTime != null) {
          endTime = moment(practicesFilter[0].endTime, 'hh:mm a').format('HH:mm');
        }


        m['startTime'] = startTime;
        m['endTime'] = endTime;

        m['weekDays'] = weekDays;
        m['monthDay'] = practicesFilter[0].schedulePlan.monthRecurrence;
        itemRequestPackageDetails.push(m);
      }
    });
    return itemRequestPackageDetails;
  }

  getModuleTypes() {
    AppointmentApiInvoker.getPackagesActive(data => {
      if (data?.length) {
        this.setState({ modules: this.formatModules(data) });
      }
    }, null);
  }

  loadModules() {
    this.props.onGetModuleTypes();
  }

  componentWillReceiveProps(next) {
    if (next.modules) {
      this.setState({ modules: this.formatModules(next.modules) });
    }
    if (next.dates) {
      this.setState({ startDate: next.dates.startDate, endDate: next.dates.endDate });
    }
  }

  formatModules(modules) {
    const result = modules.map(m => {
      return {
        id: m.packageId,
        value: m.name,
        estimatedDuration: m.packageDetail[0].practiceType.estimatedDuration || this.props.t('estimated_no_available'),
        description: m.description,
        duration: m.duration,
      }
    });
    return result;
  }

  formatDetails(packageDetail) {
    if (!packageDetail) {
      return [];
    }
    return packageDetail.map(m => ({
      ...m,
      schedulePlan: {
        ...m.schedulePlan,
        weekRecurrence: m.schedulePlan.weekDays,
        monthRecurrence: m.schedulePlan.every,
        monthDay: m.schedulePlan.every,
      },
      startTime: '10:00 am',
      endTime: '11:00 pm',
    }));
  }

  calculateQuantity = (duration, forceZero) => {
    if (duration == null) {
      return null;
    }
    let startDate = this.state.startDate;
    if (!startDate) {
      if (this.props.current?.item) {
        startDate = this.props.current.item.startDate;
      }
    } else {
      startDate = moment(startDate).utc().format(dateToServer);
    }

    const diff = (new Date(this.props.dates.endDate) - new Date(startDate) + 1);
    let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    diffDays = Math.trunc(diffDays / duration);
    if (diffDays === 0) {
      if (forceZero) {
        return 0;
      }
      diffDays = 1;
    }
    return diffDays;
  }

  openAlert = (color, message) => {
    this.setState({
      alertMessageColor: color,
      alertMessageMsg: message,
      alertMessageOpen: true
    });

    setTimeout(() => {
      this.setState({ alertMessageOpen: false });
    }, 5000);
  }

  handleValue(value, state) {

    if (state === 'module') {
      const moduleobj = this.findModule(value);
      this.setState({
        practices: this.formatDetails(moduleobj?.packageDetail),
        duration: moduleobj?.duration || null,
        packageQuantity: moduleobj?.duration ? this.calculateQuantity(moduleobj?.duration, false) : null,
        maxPackageQuantity: moduleobj?.duration ? this.calculateQuantity(moduleobj?.duration, false) : null,
      }, () => {
        this.getPartnership_new();
    });
    }

    if (state === 'lender' || state === 'lenderCode') {
      this.handleLenderChange_new(value, state);
    } else {
        this.setState({ [state]: value, refresh: false });
    }


    if (state === 'checkDateStart') {
      value = !this.state.checkDateStart;
    }
    if (state === 'checkDateEnd') {
      value = !this.state.checkDateEnd;
    }
    if (state === 'checkQuantity') {
      value = !this.state.checkQuantity;
    }

    this.setState({ [state]: value }, () => {
      if (state === 'startDate' && this.props.appointmentAction !== 'assign_agenda') {
        const { module } = this.state;
        const moduleobj = this.findModule(module);
        this.setState({
          packageQuantity: this.calculateQuantity(moduleobj.duration, false),
          maxPackageQuantity: this.calculateQuantity(moduleobj.duration, false),
        });
      }
    });
  }

  findModule(moduleId) {
    let result;
    this.props.modules.map(m => {
      if (m.packageId === moduleId) {
        result = m;
      }
    })
    return result;
  }

  isAnInvalidModule() {
    if (this.state.duration != null) {
      return (
        this.state.module === '' ||
        !this.state.startDate
      );
    } else {
      return (
        this.state.module === '' ||
        !this.state.startDate ||
        !this.state.endDate
      );
    }
  }

  saveModule(force) {
    this.setState({ showAlertChangeDate: false },
      () => {
        this.saveModuleReal(force);
      });
  }

  buildItemRequestDetails(item) {
    const { itemRequestDetails } = this.state;
    if (itemRequestDetails) {
      if (Array.isArray(itemRequestDetails)) {
        item.itemRequestDetails = itemRequestDetails;
      } else {
        item.itemRequestDetails = [itemRequestDetails];
      }
    } else {
      item.itemRequestDetails = []
    }
    for (let idx = 0; idx < item.itemRequestPackageDetails.length;idx++) {
      let startTime = item.itemRequestPackageDetails[idx].startTime;
      let endTime = item.itemRequestPackageDetails[idx].endTime;
      if (startTime != null) {
        startTime = moment(startTime, 'hh:mm a').format('HH:mm');
      }
      if (endTime != null) {
        endTime = moment(endTime, 'hh:mm a').format('HH:mm');
      }
      item.itemRequestPackageDetails[idx].startTime = startTime;
      item.itemRequestPackageDetails[idx].endTime = endTime;
    }
    if (item.schedulePlan.recurrenceType == 'INTRADAY') {
      item.schedulePlan.recurrenceType = 'DAY';
    }
    return item;
  }

  saveModuleReal(force) {
    const { t } = this.props;
    if (this.isAnInvalidModule()) {
      this.setState({ save: true });
      this.openAlert('warning', t('appointment.new.requiredFields'));
      return;
    }

    if (this.state.duration != null && this.state.packageQuantity <= 0) {
      return;
    }
    if (this.props.appointmentAction === 'assign_agenda') {

      if (this.state.duration != null && (this.state.packageQuantity > this.state.maxPackageQuantity)) {
        this.setState({ warningModuleQuantity: true });
        return;
      }

      if (this.state.duration == null && this.state.startDate > this.state.endDate) {
        this.setState({ warningDates: true });
        return;
      }

      if (this.state.duration) {
        const { module } = this.state;
        const moduleinfo = this.findModule(module);
        const packageQuantityNew = this.calculateQuantity(moduleinfo.duration, true);
        if (packageQuantityNew < this.state.packageQuantity) {
          this.setState({ showAlertChangeDurationDate: true });
          return;
        }

        if (!force && (this.state.packageQuantity !== this.state.packageQuantityOriginal)) {
          this.setState({ showAlertChangeDurationDate: true });
          return;
        }

      } else {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        if (startDate) {
          startDate = new Date(startDate);
          startDate = moment(startDate).utc().format(dateToServer);
        }
        if (endDate) {
          endDate = new Date(endDate);
          endDate = moment(endDate).utc().format(dateToServer);
        }

        if (!force && (startDate != this.state.startDateOriginal || endDate != this.state.endDateOriginal)) {
          this.setState({ showAlertChangeDate: true });
          return;
        }
      }

      const items = this.props.items.map((item) => {
        if (item.packageId === this.props.current.item.packageId) {
          item.startTime = this.state.startTime;
          item.endTime = this.state.endTime;
          item.startDate = this.state.startDate;
          item.endDate = this.state.endDate;
          item.packageQuantity = this.state.packageQuantity;
          item.itemRequestPackageDetails = this.itemRequestPackageDetailScreenToServer(item);

          return this.buildItemRequestDetails(item);
        } else {
          if (item.schedulePlan.recurrenceType == 'INTRADAY') {
            item.schedulePlan.recurrenceType = 'DAY';
          }
          return item;
        }
      });
      this.props.onUpdatePlan(items);
    } else if (this.props.appointmentAction === 'edit' || this.props.appointmentAction === 'copy') {
      const items = this.props.items.filter((item) => {
        return item.appointmentItemRequestId === this.props.current.appointmentItemRequestId
      }).map((item) => {

        let startTime = this.state.endTime;
        let endTime = this.state.endTime;
        if (startTime != null) {
					startTime = moment(this.state.startTime, 'hh:mm a').format('HH:mm');
				}
				if (endTime != null) {
					endTime = moment(this.state.endTime, 'hh:mm a').format('HH:mm');
				}

        item.packageId = this.state.module;
        item.startTime = startTime;
        item.endTime = endTime;
        item.startDate = this.state.startDate;
        item.endDate = this.state.endDate;
        item.packageQuantity = this.state.packageQuantity;
        item.order = this.state.order;
        item.companyIdPartnership = this.state.lender ? {"companyId": this.state.lender} : null;
        item.appointmentItemRequestStatus = 'CREATED'

        if (this.state.practices && this.state.practices.length > 0) {

          let practicesArray = [];
          if (this.props.appointmentId != null) {

            this.state.practices.forEach(m => {
              const notWeekRecurrence = Array.isArray(m.schedulePlan.weekDays) ? m.schedulePlan.weekDays.join(',') : m.schedulePlan.weekDays;
              const weekDays = m.schedulePlan.weekRecurrence && Array.isArray(m.schedulePlan.weekRecurrence) ?
                m.schedulePlan.weekRecurrence.join(',') :
                notWeekRecurrence;

              m['practiceTypeId'] = m['practiceType']['practiceTypeId'];
              m['weekDays'] = weekDays;
              m['monthDay'] = m.schedulePlan.monthRecurrence;
              practicesArray.push(m);
            });
          } else {
            practicesArray = this.state.practices;
          }
          item.itemRequestPackageDetails = practicesArray;
        } else {
          item.itemRequestPackageDetails = this.itemRequestPackageDetailScreenToServer(item);
        }
        return this.buildItemRequestDetails(item);
      });
      this.props.onUpdatePlan(items);
    } else {
      let id = this.state.id;
      if (!id && this.props.current) {
        id = this.props.current.order
      }

      if (this.state.duration) {
        if (this.state.packageQuantity > this.state.maxPackageQuantity) {
          this.setState({ warningModuleQuantity: true });
          return;
        }

        const moduleinfonew = this.findModule(this.state.module);
        const packageQuantityNewDate = this.calculateQuantity(moduleinfonew.duration, true);
        if (packageQuantityNewDate <= 0) {
          this.setState({ showAlertChangeDuration: true });
          return;
        }

        let startDateM = this.state.startDate;
        let endDateS = this.state.endDate;
        if (startDateM) {
          startDateM = new Date(startDateM);
          startDateM = moment(startDateM).utc();
        }
        if (endDateS) {
          endDateS = new Date(endDateS);
          endDateS = moment(endDateS).utc();
        }

        if (startDateM > endDateS) {
          this.setState({ errorDateStartAfterDateEndAppointment: true });
          return;
        }
      }
      let practicesArray = [];

      if (this.props.appointmentId != null) {
        this.state.practices.forEach(m => {
          const notRecurrence = Array.isArray(m.schedulePlan.weekDays) ? m.schedulePlan.weekDays.join(',') : m.schedulePlan.weekDays;
          const isValidArrayData = !!(m.schedulePlan.weekRecurrence && Array.isArray(m.schedulePlan.weekRecurrence));
          const weekDays = isValidArrayData ? m.schedulePlan.weekRecurrence.join(',') : notRecurrence;
          

          m['practiceTypeId'] = m['practiceType']['practiceTypeId'];
          m['weekDays'] = weekDays;
          m['monthDay'] = m.schedulePlan.monthRecurrence;
          m['file'] = m.file || null;
          practicesArray.push(m);
        });
      } else {
        practicesArray = this.state.practices;
      }
      this.props.onSavedModules({
        id: id,
        module: this.findModule(this.state.module),
        itemRequestDetails: this.state.itemRequestDetails,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        packageQuantity: this.state.packageQuantity,
        order: this.state.order,
        companyIdPartnership: this.state.lender,
        itemRequestPackageDetails: practicesArray,
        file: this.state?.practice ?  this.state?.practice[0]?.file : null
      })

      this.setState({
        id: 0,
        module: '',
        startTime: '',
        endTime: '',
        save: false,
      });
    }
  }

  getPracticesType = () => {
    const { t } = this.props;
    let practiceStr = '';
    let index = 0;
    this.state.practices.forEach(m => {
      if (index > 0) {
        practiceStr = practiceStr.concat('\n');
      }
      index++;
      practiceStr = practiceStr.concat(`${m.practiceType.name.toUpperCase()}: `);
      if (m.schedulePlan.processingScheduleType === 'INTRADAY') {
        const practices = 1440 / m.practiceType.estimatedDuration;
        practiceStr = practiceStr.concat(`${practices} `);
        practiceStr = practiceStr.concat(t('appointment.duration.perDay'));
      } else if (m.schedulePlan.recurrenceType === 'WEEK') {
        const days = m.schedulePlan.weekDays.split(',');
        practiceStr = practiceStr.concat(`${days.length} `);
        practiceStr = practiceStr.concat(t('appointment.duration.perWeek'));
      } else if (m.schedulePlan.recurrenceType === 'MONTH') {
        practiceStr = practiceStr.concat(m.schedulePlan.every);
        practiceStr = practiceStr.concat(t('appointment.duration.perMonth'));
      } else {
        practiceStr = practiceStr.concat(t('appointment.duration.perDayEach'));
        practiceStr = practiceStr.concat(t('appointment.duration.perDays', { number: m.schedulePlan.every }))
      }
    });
    return practiceStr;
  }

  setErrorTextMaxQuantity = (field, qIntraday) => {
    const { t } = this.props;
    return !field
      ? t('error.required')
      : (field < 1 && t('error.number-min-1'))
      || ((field > qIntraday) && t('error.number-min-1-max', { maxNum: qIntraday }));
  }

  getFieldInvalid(state) {
    return !state && this.state.save;
  }

  getFieldErrorText(state) {
    let text = '';
    if (!state && this.state.save) {
      text = this.props.t('error.required');
    }
    return text;
  }

  render() {
    const { t, appointmentAction, classes } = this.props;
    const { alertMessageColor, alertMessageMsg, alertMessageOpen, duration, lender, modules, third_parties, third_parties_new, third_parties_codes, lenderCode } = this.state;
    const selectedDescription = this.state.modules.filter(f => (f.id === this.state.module))[0];
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
    const displayDuration = duration == null ? t('modules.duration.not_defined') : `${duration} ${t('appointment.table.days')}`;
    const { startDate: minStartDate, endDate: maxEndDate } = this.props.dates;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');
    return (
      <GridContainer className="form-module-container">
        <Snackbar
          place="tr"
          color={alertMessageColor}
          message={alertMessageMsg}
          open={alertMessageOpen}
        />
        <GridItem xs={12}>
          <SelectInput
            id="select-module"
            label={t('appointment.new.schedule.modules.modules')}
            elements={modules}
            disabled={appointmentAction === 'assign_agenda' || companyPartnerShip !== null}
            onSelectedValue={value => this.handleValue(value, 'module')}
            value={this.state.module}
            invalid={this.getFieldInvalid(this.state.module)}
            errorText={this.getFieldErrorText(this.state.module)}
          />
        </GridItem>
        <GridItem xs={12}>
          {(this.state.module && modules.length > 0) ?
            <div>
              {(selectedDescription?.description) ?
                <TextareaInput
                  id="textarea-description"
                  text={t('estimated_description')}
                  value={selectedDescription.description}
                  disabled
                /> : ''}
              <TextareaInput
                id="textarea-duration"
                text={t('modules.duration.title')}
                value={displayDuration}
                disabled
              />
              <TextareaInput
                id="textarea-practice"
                text={t('modules.duration.practice_type')}
                value={this.getPracticesType()}
                disabled
              />
              {appointmentAction != 'assign_agenda' &&
                <GridItem xs={12} className="no-padding margin-top">
                  
                  <GridItem xs={12} sm={6} className="base-font availability-label no-padding">
                    <h4 className="padding-horizontal">{t('appointment.new.schedule.practice.lender')}</h4>
                  </GridItem>
                  
                  <GridItem xs={12} className="no-padding">

                    <SelectInput
                      id="select-lender"
                      className="no-padding"
                      label={t('appointment.new.schedule.practice.lender')}
                      elements={third_parties_new}
                      value={lender}
                      onSelectedValue={(value) => this.handleValue(value, 'lender')}
                    />
                    <SelectInput
                      id="select-lender"
                      className="no-padding"
                      label={t('appointment.new.schedule.practice.lender.provider.code')}
                      elements={third_parties_codes}
                      value={lenderCode}
                      onSelectedValue={(value) => this.handleValue(value, 'lenderCode')}
                    />
                  </GridItem>
                </GridItem>
              }
              <GridItem xs={12} className="start-end-module no-padding">
                <GridContainer className="no-padding">
                  <GridItem xs={12} sm={6}>
                    <FormControlLabel
                      className="check-box-dates"
                      control={
                        <Checkbox
                          id="check-date-start"
                          tabIndex={-1}
                          onChange={value => this.handleValue(value, 'checkDateStart')}
                          checked={this.state.checkDateStart}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          disabled={companyPartnerShip !== null}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.label, root: 'check-label' }}
                      label={t('modules.change_date_start')}
                    />
                    <DateInput
                      id="date-module-start"
                      className="date-module"
                      text={t('appointment.new.schedule.modules.start_date')}
                      minDate
                      disabled={!this.state.checkDateStart}
                      min={minStartDate}
                      max={maxEndDate}
                      value={this.state.startDate}
                      onChangeValue={value => this.handleValue(value, 'startDate')}
                      invalid={this.getFieldInvalid(this.state.startDate)}
                      errorText={this.getFieldErrorText(this.state.startDate)}
                      fullWidth={false}
                    />
                  </GridItem>

                  {this.state.duration == null ?
                    <GridItem xs={12} sm={6} className="dates-grid">
                      <FormControlLabel
                        className="check-box-dates"
                        control={
                          <Checkbox
                            id="check-date-end"
                            tabIndex={-1}
                            onChange={value => this.handleValue(value, 'checkDateEnd')}
                            checked={this.state.checkDateEnd}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            disabled={companyPartnerShip !== null}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label, root: 'check-label' }}
                        label={t('modules.change_date_end')}
                      />
                      <DateInput
                        id="date-module-end"
                        className="date-module"
                        text={t('appointment.new.schedule.practice.end_date')}
                        minDate
                        disabled={!this.state.checkDateEnd}
                        min={minStartDate}
                        max={maxEndDate}
                        value={this.state.endDate}
                        onChangeValue={value => this.handleValue(value, 'endDate')}
                        invalid={this.getFieldInvalid(this.state.endDate)}
                        errorText={this.getFieldErrorText(this.state.endDate)}
                        fullWidth={false}
                      />
                    </GridItem>
                    :
                    <GridItem xs={12}>
                      <FormControlLabel
                        className="check-box-dates"
                        control={
                          <Checkbox
                            id="checkbox-dates"
                            tabIndex={-1}
                            onChange={value => this.handleValue(value, 'checkQuantity')}
                            checked={this.state.checkQuantity}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            disabled={companyPartnerShip !== null}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label, root: 'check-label' }}
                        label={t('modules.change.quantity.title')
                        }
                        disabled={this.props.appointmentAction === 'assign_agenda'}
                      />
                      <GridContainer>
                        <GridItem xs={6}>
                          <ValidationInput
                            id="package-quantity"
                            text={t('modules.change.quantity.field')}
                            value={this.state.packageQuantity}
                            min={1}
                            type="number"
                            errorText={this.setErrorTextMaxQuantity(this.state.packageQuantity, this.state.maxPackageQuantity)}
                            invalid={this.state.packageQuantity < 1 || this.state.packageQuantity > this.state.maxPackageQuantity}
                            onChangeValue={value => this.handleValue(value, 'packageQuantity')}
                            disabled={!this.state.checkQuantity || this.props.appointmentAction === 'assign_agenda'}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <Tooltip className="toolTipEdit" aria-label="info" title={t('modules.change.quantity.help')}>
                            <InfoOutlined className="infoIcon" />
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  }
                </GridContainer>
              </GridItem>
            </div>
            : ''
          }
          {this.state.warningDates &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.new.dialog.reviewpractice.title')}
              showConfirm={false}
              onCancel={() => this.setState({ warningDates: false })}
              cancelBtnCssClass="btn primary"
              cancelBtnText={t('common.accept')}
              showCancel
              message={
                <>
                  <p>{t('appointment.module.date_start_after_date_end1')}</p>
                  <p>{t('appointment.module.date_start_after_date_end2')}</p>
                </>
              }
            />
          }
          {this.state.warningModuleQuantity &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.new.dialog.reviewpractice.title')}
              showConfirm={false}
              onCancel={() => this.setState({ warningModuleQuantity: false })}
              cancelBtnCssClass="btn primary"
              cancelBtnText={t('common.accept')}
              showCancel
              message={
                <>
                  <p>{t('appointment.module.quantity.message1')}</p>
                  <p>{t('appointment.module.quantity.message2')}</p>
                </>
              }
            />

          }
          {this.state.showAlertChangeDate &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.edit.changequantityschedule.title')}
              onConfirm={() => this.saveModule(true)}
              confirmBtnCssClass={"btn primary"}
              confirmBtnText={t('common.accept')}
              onCancel={() => this.setState({ showAlertChangeDate: false })}
              cancelBtnCssClass="btn danger"
              cancelBtnText={t('cancel')}
              showCancel={true}
              message={
                <div className="schedule-errors-list">
                  <span className="schedule-errors-item">
                    {t('appointment.edit.datestartordateend.message')}
                  </span>
                  <span className="schedule-errors-item">
                    {t('appointment.edit.datestartordateend.question')}
                  </span>
                </div>
              }
            />
          }
          {this.state.showAlertChangeDateStartDuration &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.edit.changeduration.title')}
              onConfirm={() => this.setState({ showAlertChangeDateStartDuration: false })}
              confirmBtnCssClass="btn primary"
              confirmBtnText={t('common.accept')}
              showCancel={false}
              message={
                <div className="schedule-errors-list">
                  <span className="schedule-errors-item">
                    {t('appointment.edit.start.date.message')}
                  </span>
                </div>
              }
            />
          }
          {this.state.errorDateStartAfterDateEndAppointment &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.edit.changeduration.title')}
              onConfirm={() => this.setState({ errorDateStartAfterDateEndAppointment: false })}
              confirmBtnCssClass="btn primary"
              confirmBtnText={t('common.accept')}
              showCancel={false}
              message={
                <div className="schedule-errors-list">
                  <span className="schedule-errors-item">
                    {t('appointment.new.module.date.start.after.date.end.appointment1')}
                  </span>
                  <span className="schedule-errors-item">
                    {t('appointment.new.module.date.start.after.date.end.appointment2')}
                  </span>
                </div>
              }
            />
          }
          {this.state.showAlertChangeDuration &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.edit.changeduration.title')}
              onConfirm={() => this.setState({ showAlertChangeDuration: false })}
              confirmBtnCssClass="btn primary"
              confirmBtnText={t('common.accept')}
              showCancel={false}
              message={
                <div className="schedule-errors-list">
                  <span className="schedule-errors-item">
                    {t('appointment.edit.changeduration.message1')}
                  </span>
                  <span className="schedule-errors-item">
                    {t('appointment.edit.changeduration.message2')}
                  </span>
                </div>
              }
            />
          }
          {this.state.showAlertChangeDurationDate &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.edit.changeduration.title')}
              onConfirm={() => this.setState({ showAlertChangeDurationDate: false })}
              confirmBtnCssClass="btn primary"
              confirmBtnText={t('common.accept')}
              showCancel={false}
              message={
                <div className="schedule-errors-list">
                  <span className="schedule-errors-item">
                    {t('appointment.edit.start.date.message')}
                  </span>
                </div>
              }
            />
          }
        </GridItem>

        <GridItem className="schedule-form-submit module" xs={12}>
          {this.state.module &&
            <Button id="button-config" color="primary" onClick={() => this.setState({ openConfigurePractices: true })}>
              {t('modules.configurePractices')}
            </Button>
          }
          <Button id="button-save" color="primary" onClick={() => this.saveModule(false)}>
            {t('appointment.new.schedule.modules.save')}
          </Button>
        </GridItem>
        <ModalSuggestedProfessionals
          open={this.state.openSuggestedProfesionals}
          appointmentAction={this.props.appointmentAction}
          items={this.props.items}
          customer={this.props.customer}
          lender={this.state.lender}
          itemRequestDetails={this.state.itemRequestDetails}
          onCloseModal={() => this.setState({ openSuggestedProfesionals: false })}
          onConfirmModal={value => this.setState({ itemRequestDetails: value, openSuggestedProfesionals: false })}
          module={(this.state.module ? this.findModule(this.state.module) : { 'packageDetail': [] })}
        />

        <ModalConfigurePractices
          open={this.state.openConfigurePractices}
          appointmentAction={this.props.appointmentAction}
          items={this.props.items}
          practices={this.state.practices}
          onSuggestedProfesionals={() => this.setState({ openSuggestedProfesionals: true })}
          customer={this.props.customer}
          itemRequestDetails={this.state.itemRequestDetails}
          onCloseModal={() => this.setState({ openConfigurePractices: false })}
          onConfirmModal={value => this.setState({ openConfigurePractices: false, practice: value })}
          module={(this.state.module ? this.findModule(this.state.module) : { 'packageDetail': [] })}
          isOwnLender={companyId == this.state.lender}
        />
      </GridContainer>
    )
  }
}

Modules.propTypes = {
  t: PropTypes.func,
  onSavedModules: PropTypes.func,
  modules: PropTypes.array,
  current: PropTypes.object,
  dates: PropTypes.object,
  appointmentAction: PropTypes.string,
  onGetModuleTypes: PropTypes.func,
  onUpdatePlan: PropTypes.func,
  items: PropTypes.array,
  appointmentId: PropTypes.number,
  classes: PropTypes.object,
  customer: PropTypes.object,
}

export default withStyles(styles)(withTranslation()(Modules));
