import { Add, Assignment } from '@material-ui/icons';
import { Grid, Tooltip } from '@material-ui/core';

import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip/ComponentDisabledWithTooltip';
import DateInput from '../DateInput';
import React from 'react';
import Table from '../Table/Table';
import ValidationInput from '../ValidationInput';
import { useDiabeticProgram } from './hooks/useDiabeticProgram';
import { useTranslation } from 'react-i18next';

const DiabeticProgram = () => {
  const { t } = useTranslation();
  const {
    data,
    loading,
    filterable,
    filterSearchClear,
    filterSearch,
    setDoctor,
    doctor,
    setLocalDateStart,
    localDateStart,
    setAffiliateId,
    affiliateId,
    setCustomerName,
    customerName,
    addProgram,
  } = useDiabeticProgram()

  return (
    <Grid container className="appointments appointments-list">
      <Grid item xs={12}>
        <Card>
          <CardHeader
            icon
            className="filters-header"
            style={{ paddingBottom: 20 }}
          >
            <div className="header-internment-table">
              <div className="header-icon">
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </div>
              <div className="header-buttons">
                <Tooltip title={t('appointment.new')}>
                  <span>
                    <Button
                      className="button-new-int"
                      round
                      color="primary"
                      authority="diabetic_new"
                      onClick={addProgram}
                    >
                      <Add className="appointments-button-icon" />{' '}
                      {t('appointment.new')}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {
              <Grid container className="appointments-filters">
                <Grid
                  container
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  className="appointments-filters-row"
                >
                  <Grid container item xs={12}>
                    <Grid container item xs={12} sm={6}>
                      <ValidationInput
                        text={t('common.patient')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={customerName}
                        onChangeValue={(e) => setCustomerName(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidationInput
                        text={t('common.beneficiaryNumber')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={affiliateId}
                        onChangeValue={(e) => setAffiliateId(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateInput
                        text={t('common.consultationDate')}
                        value={localDateStart}
                        onChangeValue={(e) => setLocalDateStart(e)}
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <ValidationInput
                        text={t('common.doctor')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        value={doctor}
                        onChangeValue={(e) => setDoctor(e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  className="filters-actions-appointments"
                >
                  <Grid
                    container
                    item
                    className="filters-row"
                  >
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={t('dashboard.filters.search')}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            onClick={filterSearch}
                            label={t('dashboard.filters.search')}
                            labelLoading={t('dashboard.filters.search')}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button block onClick={filterSearchClear} color="danger">
                        {t('dashboard.filters.clean')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </CardHeader>

          <CardBody className="appointments-content-body not-scroll-table filtrable-table">
            <Table
              filterable={filterable}
              striped
              loading={loading}
              tableHeaderColor="primary"
              sortable
              tableHead={[
                {
                  Header: t('users.view.name'),
                  accessor: 'name',
                  width: 130,
                },
                {
                  Header: t('users.view.lastName'),
                  accessor: 'last_name',
                },
                {
                  Header: t('common.beneficiaryNumber'),
                  accessor: 'axa_id',
                },
                {
                  Header: t('common.date'),
                  accessor: 'date',
                },
                { Header: t('common.hour'), accessor: 'time' },
                { Header: t('common.doctor'), accessor: 'doctor' },
                {
                  Header: t('actions'),
                  accessor: 'actions',
                  width: 200,
                  sortable: false,
                  filterable: false,
                },
              ]}
              tableData={data}
              colorsColls={['primary']}
              className="-striped -highlight filtrable sticky"
              defaultPageSize={data.length <= 25 ? 25 : data.length}
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DiabeticProgram;
