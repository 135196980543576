import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'
import {
  GetReasonRejectedError,
  GetReasonsRejectedConnectionError,
  GetReasonsRejectedUsecase,
} from '../../../../core/reasons-rejected/application/usecases/get-reasons-rejected.usecase'
import React, { useEffect, useState } from 'react'

import { DIContainer } from '../../../../Root'
import PropTypes from 'prop-types'
import { ReasonRejected } from '../../../../core/reasons-rejected/domain/rejected-reason'
import { SnackbarColor } from '../../../../util/interfaces'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  loading: {
    padding: '1.5rem 0',
  },
  rejectedReasonsTitle: {
    marginTop: 20,
  },
  reasonsRejected: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: 'calc(100% - 2rem)',
    '& .MuiFormLabel-root': {
      fontSize: '.875rem',
      color: 'rgba(0, 0, 0, 0.54) !important',
      paddingLeft: 20,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'left',
    },
  },
}))

interface ReasonsRejectedOptionsProps {
  reasonRejectedId: number | null
  setReasonRejectedId: (reasonRejectedId: number) => void
  openSnackbar: (color: SnackbarColor, message: string) => void
}

const ReasonsRejectedOptions = ({
  reasonRejectedId,
  setReasonRejectedId,
  openSnackbar,
}: ReasonsRejectedOptionsProps) => {
  const getUsecase = DIContainer.get<GetReasonsRejectedUsecase>(
    GetReasonsRejectedUsecase
  )

  const { t } = useTranslation()
  const classes = useStyles()

  const [reasonsRejected, setReasonsRejected] = useState<ReasonRejected[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getDataErrors = async (
    dataError: GetReasonRejectedError
  ): Promise<void> => {
    let message = t('common.errorService')
    if (dataError instanceof GetReasonsRejectedConnectionError) {
      message = t('confirm.error')
    }
    return openSnackbar(SnackbarColor.danger, message)
  }

  const getRejectedReasons = async (): Promise<void> => {
    setLoading(true)
    const result = await getUsecase.execute()

    if (result.isLeft()) {
      const dataError = result.getLeft()
      await getDataErrors(dataError)
      setLoading(false)
      return
    }

    const reasons = result.getRight()
    const reasonsActive = Array.isArray(reasons)
      ? reasons.filter((r) => r.isActive)
      : []
    setReasonsRejected(reasonsActive)
    setLoading(false)
  }

  useEffect(() => {
    getRejectedReasons()
    return () => {
      setReasonsRejected([])
      setLoading(false)
    }
  }, [])

  const menuItems = reasonsRejected.map((r) => (
    <MenuItem key={r.id} value={r.id}>
      {r.detail}
    </MenuItem>
  ))

  const titleComp = t('rejectedReason.title')
  const selectLabel = t('appointment.new.schedule.selected')

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress className="circular-progress" />
    </div>
  ) : (
    reasonsRejected && (
      <>
        <p className={classes.rejectedReasonsTitle}>{titleComp}:</p>
        <FormControl fullWidth className={classes.reasonsRejected}>
          <InputLabel htmlFor="reasons-select">{selectLabel}</InputLabel>
          <Select
            label={selectLabel}
            labelId="reasons-select"
            id="reasons-select"
            value={reasonRejectedId ?? ''}
            onChange={(event: any) =>
              setReasonRejectedId(parseInt(event.target.value))
            }
            inputProps={{
              name: 'reasons-select',
              id: 'reasons-select',
            }}
          >
            {menuItems}
          </Select>
        </FormControl>
      </>
    )
  )
}

ReasonsRejectedOptions.defaltProps = {
  reasonRejectedId: null,
}

ReasonsRejectedOptions.propTypes = {
  reasonRejectedId: PropTypes.number,
  setReasonRejectedId: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
}

export default ReasonsRejectedOptions
