import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import { ClinicalHistoryData } from '../interfaces'
import DateInput from '../../DateInput'
import SelectInput from '../../SelectInput'
import ValidatedTextField from '../components/ValidatedInput'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import { useTranslation } from 'react-i18next'

interface FollowUpProps {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const FollowUp: React.FC<FollowUpProps> = ({
  data,
  handleFieldChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const { practiceTypes, getPracticeTypes } = useClinicalHistory()

  useEffect(() => {
    getPracticeTypes()
  }, [])

  const practiceTypeOptions =
    practiceTypes?.map((pract: any) => ({
      id: pract.practiceTypeId,
      value: pract.name,
    })) ?? []

  const followLabel = t('clinicalHistory.followUpLabel')

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className="step-title">
        <Typography variant="h6">{followLabel}</Typography>
      </Grid>
      <Grid item container xs={12} className="content-form">
        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            id="date-input-next-consult"
            text={t('clinicalHistory.nextConsultation')}
            value={data?.nextConsultationDate}
            onChangeValue={(value: any) =>
              handleFieldChange('nextConsultationDate', value)
            }
            disabled={disabled}
            variant="outlined"
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            id="date-input-discharge"
            text={t('clinicalHistory.dischargeDate')}
            value={data?.dischargeDate}
            onChangeValue={(value: any) =>
              handleFieldChange('dischargeDate', value)
            }
            disabled={disabled}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className="no-padding-select-input"
          style={{ marginBottom: 16 }}
        >
          <SelectInput
            label={`${t('common.speciality')} 1`}
            elements={practiceTypeOptions}
            value={data?.specialty1?.practiceType ?? ''}
            onSelectedValue={(value: any) =>
              handleFieldChange('specialty1', { practiceType: value })
            }
            freeSolo={true}
            getOptionSelected={(option: any) => option?.id || null}
            id="select-specialty1"
            disabled={disabled}
            hasBoxStyle={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className="no-padding-select-input"
          style={{ marginBottom: 16 }}
        >
          <SelectInput
            label={`${t('common.speciality')} 2`}
            elements={practiceTypeOptions}
            value={data?.specialty2?.practiceType ?? ''}
            onSelectedValue={(value: any) =>
              handleFieldChange('specialty2', { practiceType: value })
            }
            freeSolo={true}
            getOptionSelected={(option: any) => option?.id || null}
            id="select-specialty2"
            disabled={disabled}
            hasBoxStyle={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className="no-padding-select-input"
          style={{ marginBottom: 24 }}
        >
          <SelectInput
            label={`${t('common.speciality')} 3`}
            elements={practiceTypeOptions}
            value={data?.specialty3?.practiceType ?? ''}
            onSelectedValue={(value: any) =>
              handleFieldChange('specialty3', { practiceType: value })
            }
            freeSolo={true}
            getOptionSelected={(option: any) => option?.id || null}
            id="select-specialty3"
            disabled={disabled}
            hasBoxStyle={true}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <ValidatedTextField
            variant="outlined"
            label={t('label.observations')}
            placeholder={t('label.observationsDescription')}
            minRows={4}
            value={data?.observations ?? ''}
            onChange={(value) => handleFieldChange('observations', value)}
            type="string"
            maxLength={200}
            multiline
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <ValidatedTextField
            variant="outlined"
            label={t('common.others')}
            placeholder={t('common.othersDescription')}
            minRows={4}
            value={data?.others ?? ''}
            onChange={(value) => handleFieldChange('others', value)}
            type="string"
            maxLength={200}
            multiline
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FollowUp
