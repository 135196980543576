import { connect } from 'react-redux';
import React from 'react';
import DashboardDiabeticsAndHospitalization from '../components/DashboardsPB/DashboardDiabeticsAndHospitalization';


const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardPBHospitalizationsService = connect(
    mapStateToProps,
    mapDispatchToProps
)((props) => <DashboardDiabeticsAndHospitalization {...props} variant="internment" />);

export default DashboardPBHospitalizationsService;