export const validTypes = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const maxFileSize = 5 * 1024 * 1024


export const mimeTypes = {
    '/9j/': {format:'image/jpeg', ext:"jpeg"},
    iVBORw0KGgo: {format:'image/png', ext: "png"},
    JVBERi0xLjQ: {format:'application/pdf', ext:"pdf"},
    UEsDBBQAAAA: {format:'application/vnd.openxmlformats-officedocument.wordprocessingml.document' , ext: "docx"},
    UEsDBBQABgA: {format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ext: "xlsx"}
  }

export const signatures = [
    { signature: "504B0304", mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ext: ".docx" }, // DOCX
    { signature: "504B0304", mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ext: ".xlsx" }, // XLSX
    { signature: "25504446", mime: "application/pdf", ext: ".pdf" }, // PDF
    { signature: "89504E47", mime: "image/png", ext: ".png" }, // PNG
    { signature: "FFD8FFE0", mime: "image/jpeg", ext: ".jpg" }, // JPG
    { signature: "FFD8FFE1", mime: "image/jpeg", ext: ".jpg" }, // JPG
    { signature: "FFD8FFE2", mime: "image/jpeg", ext: ".jpg" }, // JPG
  ];