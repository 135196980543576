import { Grid, Typography } from '@material-ui/core'
import {
  Medicine,
  MedicinePresentationType,
  MedicineType,
  MedicineUnitType,
  MedicineViaType,
} from '../interfaces'

import Button from '../../CustomButtons/Button'
import DateInput from '../../DateInput'
import React from 'react'
import { SelectOutlined } from '../../SelectOutlined/SelectOutlined'
import ValidatedTextField from '../components/ValidatedInput'
import { useTranslation } from 'react-i18next'

interface MedicinesHcProps {
  medicineTypes: MedicineType[]
  medicineViaTypes: MedicineViaType[]
  medicinePresentationTypes: MedicinePresentationType[]
  medicineUnitTypes: MedicineUnitType[]
  currentMedicine: Medicine
  disabled: boolean
  setIsFormVisible: (value: boolean) => void
  handleSaveMedicine: (medicine: Medicine) => void
  handleMedicineFieldChange: (
    field: keyof Medicine,
    value: Medicine[keyof Medicine],
    data: Medicine
  ) => void
}

const MedicinesForm: React.FC<MedicinesHcProps> = ({
  medicineTypes,
  medicineViaTypes,
  medicinePresentationTypes,
  medicineUnitTypes,
  currentMedicine,
  disabled,
  setIsFormVisible,
  handleSaveMedicine,
  handleMedicineFieldChange,
}) => {
  const { t } = useTranslation()

  const translations = {
    medicineTitle: t('clinicalHistory.medicines.registerTitle'),
  }

  const updateMedicineField = (field: keyof Medicine, value: any) => {
    handleMedicineFieldChange(field, value, currentMedicine)
  }

  const saveMedicine = () => {
    handleSaveMedicine(currentMedicine)
  }

  const medicineOptions =
    medicineTypes?.map((type) => ({
      id: type.medicineTypeId as number,
      value: type.name ?? '',
    })) ?? []

  const medicinePresentationOptions =
    medicinePresentationTypes?.map((presentation) => ({
      id: presentation.medicinePresentationTypeId as number,
      value: presentation.name ?? '',
    })) ?? []

  const medicineUnitOptions =
    medicineUnitTypes?.map((unit) => ({
      id: unit.medicineUnitTypeId as number,
      value: unit.name ?? '',
    })) ?? []

  const medicineViaOptions =
    medicineViaTypes?.map((via) => ({
      id: via.medicineViaTypeId as number,
      value: via.name ?? '',
    })) ?? []

  return (
    <div>
      <Grid container spacing={2}>
        <Typography variant="h6" className="step-title">
          {translations?.medicineTitle}
        </Typography>
        <React.Fragment key={currentMedicine.scheduleMedicineId}>
          <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
            <SelectOutlined
              label={t('medical.record.detail.medicines.medicine')}
              options={medicineOptions}
              select={currentMedicine.medicineType?.medicineTypeId ?? ''}
              onChange={(value) =>
                updateMedicineField('medicineType', { medicineTypeId: value })
              }
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
            <SelectOutlined
              label={t('medical.record.detail.medicines.presentation')}
              options={medicinePresentationOptions}
              select={
                currentMedicine.medicinePresentationType
                  ?.medicinePresentationTypeId ?? ''
              }
              onChange={(value) =>
                updateMedicineField('medicinePresentationType', {
                  medicinePresentationTypeId: value,
                })
              }
              disabled={disabled}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginTop: 12,
              marginBottom: 16,
            }}
          >
            <ValidatedTextField
              label={t('medical.record.detail.medicines.dose')}
              placeholder={t('common.dinamicNumberRange', {min: 0, max: 9999})}
              value={currentMedicine.medicineDose}
              onChange={(value: any) =>
                updateMedicineField('medicineDose', value)
              }
              type="number"
              variant="outlined"
              min={0}
              max={9999}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
            <SelectOutlined
              label={t('clinicalHistory.unit')}
              options={medicineUnitOptions}
              select={
                currentMedicine.medicineUnitType?.medicineUnitTypeId ?? ''
              }
              onChange={(value) =>
                updateMedicineField('medicineUnitType', {
                  medicineUnitTypeId: value,
                })
              }
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
            <SelectOutlined
              label={t('clinicalHistory.administrationMethod')}
              options={medicineViaOptions}
              select={
                currentMedicine.medicineViaType?.medicineViaTypeId ?? ''
              }
              onChange={(value) =>
                updateMedicineField('medicineViaType', {
                  medicineViaTypeId: value,
                })
              }
              disabled={disabled}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginTop: 12,
              marginBottom: 16,
            }}
          >
            <ValidatedTextField
              label={t('service.quantity')}
              placeholder={t('common.dinamicNumberRange', {min: 1, max: 9999})}
              variant="outlined"
              value={currentMedicine.medicineQuantity}
              onChange={(value: any) =>
                updateMedicineField('medicineQuantity', value)
              }
              type="number"
              min={1}
              max={9999}
              disabled={disabled}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginTop: 12,
              marginBottom: 16,
            }}
          >
            <ValidatedTextField
              label={t('clinicalHistory.batchNumber')}
              placeholder={t('clinicalHistory.batchNumberDescription')}
              variant="outlined"
              type="string"
              value={currentMedicine.medicineLotNumber}
              onChange={(value) =>
                updateMedicineField('medicineLotNumber', value)
              }
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
            <DateInput
              id="date-input-from"
              text={t('clinicalHistory.expirationDate')}
              disabled={disabled}
              value={currentMedicine.medicineExpireDate}
              onChangeValue={(value: any) =>
                updateMedicineField('medicineExpireDate', value)
              }
            />
          </Grid>
        </React.Fragment>
      </Grid>

      <div
        style={{
          marginTop: 16,
          display: 'flex',
          justifyContent: 'space-evenly',
          gap: 16,
        }}
      >
        <Grid item>
          <Button
            simple
            style={{ margin: '16px 0' }}
            variant="contained"
            onClick={() => setIsFormVisible(false)}
            color="primary"
          >
            {t('appointment.new.backStep')}
          </Button>
        </Grid>
        {!disabled && (
          <Grid item>
            <Button
              style={{ margin: '16px 0' }}
              variant="contained"
              disabled={!currentMedicine.medicineType?.medicineTypeId}
              onClick={() => {
                saveMedicine()
                setIsFormVisible(false)
              }}
              color="primary"
            >
              {t('common.save')}
            </Button>
          </Grid>
        )}
      </div>
    </div>
  )
}

export default MedicinesForm
