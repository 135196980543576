import { Component } from 'react'
import ApiInvoker from './ApiInvoker';
import config from '../config/config';


export default class ApiPowerBI extends Component {

	static getReportByCompanyAndPartnetship(reportType, callback, callerror) {
        ApiPowerBI.callReport(reportType, callback, callerror);
    }

    static callReport(reportType, callback, callerror) {
        let companyId = parseInt(localStorage.getItem('itlg_default_company_id'));
        let companyPartnerShip = -1;
        if (localStorage.getItem('company_partnership_id') != null) {
            companyPartnerShip = localStorage.getItem('company_partnership_id');
        }
        ApiInvoker.getReportByCompanyAndPartnetship(companyId, companyPartnerShip, reportType, data => {
            // Process response
            ApiPowerBI.callLoginPB(data.clientIdBI,data.clientSecretBI,data.tenantIdBI,data.datasetId,data.reportId, callback, callerror);
        }, error => {
            callerror(error);
        });
    }

    static callLoginPB(client,secret,tenant,datasetId,reportId, callback, callerror) {   
        let url = config.powerbi_urllogin.replace("{tenantId}", tenant );     
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
            urlloginpb: url,
            clientid: client,
            clientsecret: secret,
            granttype: 'client_credentials',
            scope: config.powerbi_scope,
            resource: config.powerbi_resource
            }),
        };
        fetch('/api/pb/login', requestOptions)
            .then(response => (response.json()))
            .then(data => {

            if (!data.error) {
                ApiPowerBI.callApiPB(client,secret,tenant,datasetId,reportId,data.access_token, callback, callerror);
            } else {
                callerror(null);
            }
        })
        .catch((_) => {
            callerror(null);
        });
    }
        
    static callApiPB(client,secret,tenantId,datasetId,reportId, token, callback, callerror) {
    
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
            urlapipb: config.powerbi_urlreport,
            datasets: datasetId,
            reportid: reportId,
            token: token
            }),
        };

        fetch('/api/pb/api', requestOptions)
            .then(response => (response.json()))
            .then(data => {
            if (!data.error) {
                callback({reportToken: data.token, reportId: reportId, isNewVersion: true, error: null, loading: false});
            } else {
                callerror(null);
            }
        })
        .catch((_) => {
            callerror(null);
        });
    }
}
