import './CreateAddress.css';

import React, { Component } from 'react';

import ApiInvoker from '../../api/ApiInvoker';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import CheckInput from '../CheckInput';
import { GetGeoLocation } from '../../handlers/get-geolocation';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import MapComponent from './MapComponent';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ThirdPartiesInvoker from '../../api/ThirdPartiesInvoker';
import ValidationInput from '../ValidationInput';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class CreateAddress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressType: '',
            addressTypes: [],
            addressTypesForPosting: [],
            locations: [],
            cities: [],
            countries: [],
            country: '',
            provinces: [],
            province: '',
            geographicZones: [],
            geographicZone: '',
            city: '',
            street: '',
            number: '',
            floor: '',
            apartment: '',
            neighborhood: '',
            zipcode: '',
            latitude: '',
            longitude: '',
            addressDefault: false,
            alertColor: '',
            alertMessage: '',
            alertOpen: false,
            save: false,
            addressChecked: _.get(props.edit, 'latitude', false) && _.get(props.edit, 'longitude', false),
            geolocationUpdated: false,
            loading: false,
            changeManualGeo: false,
            addressVerify: false,
            manualGeoVerify: true,
            errorGeolocation: false,
        }
    }

    buildInitialStateOnEdit(editProps) {
        const {
            editable, addressType, addressDefault, countryId, department, floor, geographicZone, latitude, longitude, location, neighborhood, number, save, provinceId, street, zipcode,
        } = editProps;

        this.setState({
            addressType: addressType?.addressTypeId,
            number,
            neighborhood,
            street,
            zipcode,
            city: location.locationId,
            apartment: department,
            floor,
            editable: editable,
            province: provinceId !== 0 ? provinceId : '',
            country: countryId !== 0 ? countryId : '',
            geographicZone: (geographicZone?.geographicZoneId) ?? '',
            latitude: latitude ?? '',
            longitude: longitude ?? '',
            addressDefault,
            addressVerify: true,
            save,
            hasPermission: false,
            isBrowserUnsupported: false,
        });
    }

    componentWillMount() {
        this.getCountries();
        this.getAddressTypes();
        if (this.props.edit) {
            const { countryId, location, provinceId } = this.props.edit;

            this.buildInitialStateOnEdit(this.props.edit);

            if (countryId && countryId !== 0) {
                this.getProvinces(countryId);
            }

            if (provinceId && provinceId !== 0) {
                this.getCities(provinceId);
            }

            if (location.locationId && location.locationId !== 0) {
                this.getGeographicZones(location.locationId);
            }
        }
        this.setGeolocationParams();
    };

    componentDidUpdate() {
        const { latitude, longitude } = this.state;
        if (this.shouldSearchForLocationInGoogle() && latitude && longitude) {
            ThirdPartiesInvoker.getGoogleLocationBasedOnLatLong(latitude, longitude, this.handleLocationFromGeoposition.bind(this));
        }
    }

    async setGeolocationParams() {
        const { t, edit } = this.props;
        const { latitude, longitude, hasPermission, isBrowserUnsupported } = await GetGeoLocation();

        if (!edit) {
            this.setState({ latitude, longitude });
        }
        this.setState({ hasPermissionGeolocation: hasPermission, isBrowserUnsupportedGeolocation: isBrowserUnsupported });
        if (!isBrowserUnsupported) {
            this.openAlert('danger', t('geolocation.browserUnsupported'));
            return;
        }
        if (!hasPermission) {
            this.openAlert('warning', t('geolocation.permissionDenied'));
        }
    }

    shouldSearchForLocationInGoogle() {
        const { geolocationUpdated, countries } = this.state;
        return (
            !this.props.edit &&
            !geolocationUpdated &&
            navigator?.geolocation &&
            !_.isEmpty(countries)
        );
    };

    handleLocationFromGeoposition(responseJson) {
        if (responseJson.status === 'OK') {
            const addressComponents = responseJson.results[0].address_components;
    
            const locality = this.findLocationByName(addressComponents, 'locality');
            const city = this.findLocationByName(addressComponents, 'administrative_area_level_2');
            const region = this.findLocationByName(addressComponents, 'administrative_area_level_1');
            const country = this.findLocationByName(addressComponents, 'country');
    
            this.openAlert('info', this.props.t('address.gmap.getLocation'), {
                geolocationUpdated: true,
            });

            // this should be concatenated to wait for the services calls based on selected values
            this.getProvinces(
                this.getLocationFromStateByName('country', country),
                () => {
                    this.getCities(
                        this.getLocationFromStateByName('province', region),
                        () => {
                            this.getGeographicZones(
                                this.getLocationFromStateByName('city', [locality, city])
                            )
                        }
                    );
                }
            );
        }
    }

    findLocationByName(addressComponents, type) {
        const component = addressComponents.find(component => component.types[0] === type);
        return component ? component.long_name : undefined;
    }

    getLocationFromStateByName(type, googleItem) {
        const state = this.state;
        const stateMap = {
            'country': state.countries,
            'province': state.provinces,
            'city': state.cities
        };
        const locationSelected = _.find(stateMap[type], (item) => {
            let value = _.get(item, 'value', '');
            value = this.normalizeSpecialChars(value);

            if (_.isArray(googleItem)) {
                return _.some(googleItem, item => {
                    let normalizedGoogleItem = this.normalizeSpecialChars(item);
                    return (value && normalizedGoogleItem ? value.toLowerCase() === normalizedGoogleItem.toLowerCase() : false);
                });
            } else {
                const normalizedGoogleItem = this.normalizeSpecialChars(googleItem);
                return (value && normalizedGoogleItem ? value.toLowerCase() === normalizedGoogleItem.toLowerCase() : false);
            }
        }) || {};

        return locationSelected.id;
    };

    normalizeSpecialChars(value) {
        if (value) {
            return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        } else {
            return value;
        }
    }

    handleValue(value, state) {
        if (state === 'latitude' || state === 'longitude') {
            this.setState({ manualGeoVerify: false });
        }
        let { addressChecked } = this.state;

        if (state !== 'addressType' && state !== 'geographicZone' && state !== 'addressDefault') {
            addressChecked = false;
        }
        this.setState({
            [state]: value,
            addressChecked,
        });
    }

    handleValueLatLng(value, state) {
        if (value.indexOf('e') > -1) return;

        const rgx_lat = /^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:\d|[1-8]\d)(?:(?:\.\d{1,7})?))$/;
        const rgx_lng = /^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:\d|[1-9]\d|1[0-7]\d)(?:(?:\.\d{1,7})?))$/;
        let errorGeolocation = null;
        if (state === 'latitude') {
            if (!value.match(rgx_lat)) {
                errorGeolocation = this.props.t('address.change.lat.error');;
            }
        }
        if (state === 'longitude') {
            if (!value.match(rgx_lng)) {
                errorGeolocation = this.props.t('address.change.lng.error');;
            }
        }
        this.setState({
            [state]: value,
            manualGeoVerify: false,
            errorGeolocation,
        });
    }

    handleCountry(country) {
        this.setState({
            addressChecked: false,
            country,
            city: null,
            geographicZone: null
        }, () => {
            this.getProvinces(country);
        });
    }

    handleProvince(value) {
        this.setState({
            province: value,
            addressChecked: false,
            geographicZone: null
        }, () => {
            this.getCities(value);
        });
    }

    handleCities(city) {
        this.setState({
            city,
            addressChecked: false,
            geographicZone: null
        }, () => {
            this.getGeographicZones(city);
        });
    }

    getCountries() {
        ApiInvoker.getCountries(data => {
            this.formatCountries(data);
        }, null);
    }

    getProvinces(country, setStateCallBack) {
        ApiInvoker.getProvinces(country, data => {
            this.formatProvinces(data, setStateCallBack);
            this.setState({ country });
        }, null);
    }

    getCities(province, setStateCallBack) {
        ApiInvoker.getLocations(province, cities => {
            this.formatCities(cities, setStateCallBack);
            this.setState({ province });
        }, null);
    }

    getAddressTypes() {
        ApiInvoker.getAddressTypes(addressTypes => {
            this.setState({
                addressTypes: _.map(addressTypes, this.getAddressType),
                addressTypesForPosting: addressTypes,
            });
        });
    }

    getAddressType(addressType) {
        return {
            id: addressType.addressTypeId,
            value: addressType.name,
        };
    }

    getGeographicZones(city) {
        ApiInvoker.getGeographicZoneFromLocation(city, geographicZones => {
            this.formatGeographicZones(geographicZones);
            this.setState({ city });
        }, null);
    }

    checkGeocode() {
        const { t } = this.props;
        const googleMaps = window.google?.maps;

        if (!googleMaps) {
            console.error('Google map api was not found in the page.');
            return;
        }
        const { cities, city, countries, country, number, province, provinces, street } = this.state;
        const cityComplete = _.find(cities, (item) => item.id === city);
        const provinceComplete = _.find(provinces, (item) => item.id === province);
        const countryComplete = _.find(countries, (item) => item.id === country);
        
        this.setState({ changeManualGeo: false });
        const isFieldsRequired = street && number && cityComplete && provinceComplete && countryComplete;
        if (!isFieldsRequired) {
            this.openAlert('warning', t('common.messageWarning.fieldsComplete'));
            return;
        }
        //geodecode
        const completeAddress = `${number} ${street}, ${cityComplete.value}, ${provinceComplete.value}, ${countryComplete.value}`;
        const geocoder = new googleMaps.Geocoder();
        const address = completeAddress;
        
        try {
            geocoder.geocode({ address: address }, (results, status) => {
                let responseJson = { "results": results };
                if (status === 'OK') {  
                    this.setState({
                        latitude: responseJson.results[0].geometry.location.lat(),
                        longitude: responseJson.results[0].geometry.location.lng(),
                        addressChecked: true,
                    }, () => {
                        this.setState({ addressVerify: true, errorGeolocation: null, manualGeoVerify: true });
                    });
                }
            });
        } catch (exception) {
            console.log(exception);
        }
    }

    formatProvinces(provinces, setStateCallBack) {
        let result = [];
        result = provinces.map(p => ({
            id: p.provinceId,
            value: p.name,
        }));

        this.setState({ provinces: result }, setStateCallBack);
    }

    formatCountries(countries) {
        let result = [];
        result = countries.map(c => ({
            id: c.countryId,
            value: c.name,
        }));

        this.setState({ countries: result });
    }

    formatCities(cities, setStateCallBack) {
        let result = [];
        result = cities.map(c => ({
            id: c.locationId,
            value: c.name
        }));

        this.setState({
            locations: cities,
            cities: result,
        }, setStateCallBack);
    }

    formatGeographicZones(zones) {
        let geographicZones = [];
        geographicZones = zones.map(gz => ({
            id: gz.geographicZoneId,
            value: gz.detail,
        }));

        this.setState({ geographicZones });
    }

    saveAddress() {
        const { t } = this.props;
        const { addressType, city, geographicZone, neighborhood, number, street, zipcode } = this.state;
        const isFieldsRequired = addressType && number && neighborhood && street && zipcode && city && geographicZone;
        if (!isFieldsRequired) {
            this.openAlert('warning', t('common.messageWarning.fieldsComplete'));
            this.setState({ loading: false });
            return;
        }
        if (!this.state.addressChecked) {
            this.openAlert('danger', this.props.t('address.messageWngCheckAddress'));
            return;
        }
        if (this.props.edit) {
            this.props.edit.save = true;
            this.sendAddress();
            return;
        }
        this.setState({ save: true });
        this.sendAddress();
    }

    bodyAddress = (edit) => {
        const { personId } = this.props;
        const {
            addressDefault, apartment, floor, latitude, longitude, neighborhood, number, street, zipcode,
        } = this.state;
        const data = {
            personId,
            location: this.findLocation(),
            latitude: (latitude ? latitude.toString().substring(0, 14) : latitude),
            longitude: (longitude ? longitude.toString().substring(0, 14) : longitude),
            department: apartment,
            floor,
            addressType: this.getAddressTypeForPosting(),
            number,
            neighborhood,
            street,
            zipCode: zipcode,
            addressDefault,
            geographicZone: this.findGeographicZone(),
        }
        if (edit) {
            data.addressId = edit.addressId;
            data.address = edit.address;
        } else data.address = '';

        return data;
    }

    sendAddress() {
        this.setState({ loading: true });
        const { edit, personId, t } = this.props;

        const addressId = edit?.addressId || null;
        const body = this.bodyAddress(edit || null);
        PersonApiInvoker.postPersonAddress(
            { personId, addressId },
            body,
            (data) => {
                this.setState({ loading: false });
                if (data.message) {
                    this.openAlert('danger', data.message);
                }
                const messageUpdateSuccess =  t('address.messageUpdateSuccess');
                const messageSaveSuccess =  t('address.messageSaveSuccess');
                const message = edit ? messageUpdateSuccess : messageSaveSuccess;
                this.props.onAddressSubmited(data, message);
            }, (error) => {
                this.setState({ loading: false });
                const msg = error.message ?? t('customers.addresses.404.error');
                this.openAlert('danger', msg);
                console.error('** error de Fetch', error.message);
            }
        );
    }

    findLocation() {
        let currentLocation = {};
        currentLocation = this.state.locations.find(l => l.locationId === this.state.city);
        return currentLocation;
    }

    findGeographicZone = () => {
        let currentGeographicZone = null;
        currentGeographicZone = this.state.geographicZones.map(gz => {
            return {
                companyId: localStorage.getItem('itlg_default_company_id'),
                detail: gz.value,
                geographicZoneId: gz.id,
                locationId: this.findLocation().locationId,
                metadata: null,
            }
        }).find(gz => gz.geographicZoneId === this.state.geographicZone);
        return currentGeographicZone;
    }

    openAlert(color, message, extraState) {
        this.setState(_.assign({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        }, extraState));

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    getAddressTypeForPosting() {
        const { addressType } = this.state;
        return _.find(this.state.addressTypesForPosting, (item) => item.addressTypeId === addressType);
    }

    verifyGeo = () => {
        let error = null;
        const { t } = this.props;
        if (this.state.latitude > 90.00 || this.state.latitude < -90.00) {
            error = t('address.change.lat.error');
        }
        if (this.state.longitude > 180.00 || this.state.longitude < -180.00) {
            error = t('address.change.lng.error');
        }
        const lat = this.state.latitude + "";
        const lng = this.state.longitude + "";

        const decimalLat = lat.substring(lat.indexOf(".") + 1);
        const decimalLong = lng.substring(lng.indexOf(".") + 1);

        if (decimalLat.length < 7) {
            error = t('address.change.lat.error');
        }
        if (decimalLong.length < 7) {
            error = t('address.change.lng.error');
        }

        if (error != null) {
            this.setState({ errorGeolocation: error });
        } else {
            this.setState({
                errorGeolocation: null,
                manualGeoVerify: true,
                addressChecked: true,
            });
        }
    }

    disableAddressCheckButton() {
        const { edit, editable } = this.props;
        let isDisabled = false;
        if (edit) {
            if (editable === false && (edit.latitude !== '' && edit.longitude !== '')) {
                return true;
            }
        }
        return isDisabled;
    }

    renderGoogleMap = () => <MapComponent key="map" lat={this.state.latitude} lng={this.state.longitude} />;

    render() {
        const {
            addressDefault, addressType, addressTypes, addressVerify, alertColor, alertMessage, alertOpen, apartment,
            editable, cities, city,
            changeManualGeo, countries, country, errorGeolocation, floor, geographicZone, geographicZones,
            latitude, loading, longitude, manualGeoVerify, neighborhood, number, province, provinces, save, street, zipcode,
        } = this.state;
        const { t, edit } = this.props;

        let localEditable = true;
        if (edit && !editable) {
            localEditable = false;
        }

        return (
            <>
                <GridContainer className="create-address">
                    <GridItem xs={12} sm={12} md={6} className="no-padding">
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={4} className="no-padding">
                                <SelectInput
                                    label={`${t('address.country')} *`}
                                    elements={countries}
                                    value={country}
                                    disabled={!localEditable}
                                    onSelectedValue={(value) => this.handleCountry(value)}
                                    invalid={country === '' && save}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="no-padding">
                                <SelectInput
                                    label={`${t('address.province')} *`}
                                    elements={provinces}
                                    value={province}
                                    disabled={!localEditable}
                                    onSelectedValue={(value) => this.handleProvince(value)}
                                    invalid={province === '' && save}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="no-padding">
                                <SelectInput
                                    label={`${t('address.location')} *`}
                                    elements={cities}
                                    value={city}
                                    disabled={!localEditable}
                                    onSelectedValue={(value) => this.handleCities(value)}
                                    invalid={city === '' && save}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    id="address-zipcode"
                                    text={`${t('address.zipCode')} *`}
                                    value={zipcode}
                                    onChangeValue={(value) => this.handleValue(value, 'zipcode')}
                                    invalid={zipcode === '' && save}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    text={`${t('address.neighborhood')} *`}
                                    id="address-neighborhood"
                                    value={neighborhood}
                                    onChangeValue={(value) => this.handleValue(value, 'neighborhood')}
                                    invalid={neighborhood === '' && save}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    text={`${t('address.street')} *`}
                                    id="address-street"
                                    value={street}
                                    onChangeValue={(value) => this.handleValue(value, 'street')}
                                    invalid={street === '' && save}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    text={`${t('address.number')} *`}
                                    id="address-number"
                                    type="number"
                                    value={number}
                                    onChangeValue={(value) => this.handleValue(value, 'number')}
                                    invalid={number === '' && save}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    text={t('address.floor')}
                                    id="address-floor"
                                    value={floor}
                                    onChangeValue={(value) => this.handleValue(value, 'floor')}
                                    invalid={false}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} className="item-input">
                                <ValidationInput
                                    text={t('address.apartment')}
                                    id="address-apartment"
                                    type="text"
                                    value={apartment}
                                    onChangeValue={(value) => this.handleValue(value, 'apartment')}
                                    invalid={false}
                                    disabled={!localEditable}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="no-padding">
                                <SelectInput
                                    label={`${t('address.addressType')} *`}
                                    elements={addressTypes}
                                    value={addressType}
                                    onSelectedValue={(value) => this.handleValue(value, 'addressType')}
                                    invalid={addressType === '' && save}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} className="no-padding">
                                <SelectInput
                                    label={`${t('address.geographicZone')} *`}
                                    elements={geographicZones} value={geographicZone}
                                    onSelectedValue={(value) => this.handleValue(value, 'geographicZone')}
                                    invalid={geographicZone === '' && save}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <CheckInput
                                    onChangeValue={() => this.handleValue(!addressDefault, 'addressDefault')}
                                    checked={addressDefault}
                                    label={t('address.mainAddress')}
                                    labelPlacement="start"
                                />
                            </GridItem>
                            <GridContainer>
                                <GridItem md={12} className="address-check-button text-right">
                                    <Button
                                        id="button-check"
                                        color="secondary"
                                        onClick={() => this.checkGeocode()}
                                        disabled={this.disableAddressCheckButton()}
                                        size="sm"
                                    >
                                        {t('address.checkAddress')}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem md={12}>
                                    <CheckInput
                                        onChangeValue={() => this.handleValue(!changeManualGeo, 'changeManualGeo')}
                                        checked={changeManualGeo}
                                        disabled={!addressVerify || this.disableAddressCheckButton()}
                                        label={t('address.change.manual.geo')}
                                    />
                                </GridItem>
                            </GridContainer>

                            {changeManualGeo &&
                                <GridContainer>
                                    <GridItem xs={6} sm={4} md={4} className="item-input">
                                        <ValidationInput
                                            text={t('address.latitude')}
                                            type="number"
                                            value={latitude}
                                            onChangeValue={(value) => this.handleValueLatLng(value, 'latitude')}
                                            disabled={!changeManualGeo}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={4} className="item-input">
                                        <ValidationInput
                                            text={t('address.longitude')}
                                            type="number" value={longitude}
                                            onChangeValue={(value) => this.handleValueLatLng(value, 'longitude')}
                                            disabled={!changeManualGeo}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <ButtonSpinner
                                            className="create-address-submit"
                                            onClick={() => this.verifyGeo()}
                                            disabled={!addressVerify || !changeManualGeo}
                                            label={t('address.change.check.geo')}
                                            fullWidth={true}
                                            color="secondary"
                                            size="sm"
                                            id="button-verify"
                                        />
                                    </GridItem>
                                </GridContainer>
                                }
                                
                            {errorGeolocation &&
                                <GridItem md={8}>
                                    <p><span className="error">{errorGeolocation}</span></p>
                                </GridItem>
                            }
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} className="map-container">
                        {this.renderGoogleMap()}
                    </GridItem>
                    <GridItem xs={12} className="item-submit">
                        <ButtonSpinner
                            className="create-address-submit"
                            onClick={() => this.saveAddress()}
                            disabled={!manualGeoVerify}
                            label={t('address.save')}
                            labelLoading={t('address.saving')}
                            loading={loading}
                            typeButton="submit"
                            color="primary"
                            id="button-save"
                        />
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tr"
                    color={alertColor}
                    message={alertMessage}
                    open={alertOpen}
                />
            </>
        )
    };
}

CreateAddress.propTypes = {
    t: PropTypes.func,
    personId: PropTypes.number,
    onAddressSubmited: PropTypes.func,
    edit: PropTypes.object,
    editable: PropTypes.bool,
}

export default withTranslation()(CreateAddress);
