import '../../assets/plugins/react-charts.css'
import './DashboardPB.css'
import { withTranslation } from 'react-i18next'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import ApiInvoker from '../../api/ApiInvoker';
import config from '../../config/config.js'
import Cookies from 'universal-cookie'
import React, { Component } from 'react'

const reportTypes = {
  diabetic: 'diabetic',
  internment: 'internment',
}

interface DashboardState {
  companyId: number
  isNewVersion: boolean
  loading: boolean
  username?: string
  password?: string
  datasetId?: string
  reportId?: string
  token?: string
  reportToken?: string
  error?: string
}

interface DashboardProps {
  variant: keyof typeof reportTypes
}

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: any) {
    super(props)
    this.state = {
      companyId: 1,
      isNewVersion: false,
      loading: true,
    }
    this.callReport()
  }

  callReport() {
    let companyId = parseInt(
      localStorage.getItem('itlg_default_company_id') || '0'
    )
    let companyPartnerShip =
      localStorage.getItem('company_partnership_id') || -1

    ApiInvoker.getReportByCompanyAndPartnetship(
      companyId,
      companyPartnerShip,
      reportTypes[this.props.variant],
      (data: {
        userPBI: string
        passPBI: string
        datasetId: any
        reportId: any
      }) => {
        this.setState(
          {
            username: data.userPBI,
            password: data.passPBI,
            datasetId: data.datasetId,
            reportId: data.reportId,
          },
          this.callLoginPB
        )
      },
      () => {
        this.setState({ isNewVersion: false, loading: false })
      }
    )
  }

  callLoginPB() {
    //let url = config.powerbi_urllogin.replace("{tenantId}", this.state.tenant );     

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        urlloginpb: config.powerbi_urllogin,
        username: this.state.username,
        password: this.state.password,
        granttype:
          this.props.variant === 'internment'
            ? 'client_credentials'
            : 'password',
        scope: config.powerbi_scope,
        resource: config.powerbi_resource,
      }),
    }

    fetch('/api/pb/login', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          this.setState({ token: data.access_token }, this.callApiPB)
        } else {
          this.setState({
            error: data.error,
            isNewVersion: false,
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({ error, isNewVersion: false, loading: false })
      })
  }

  callApiPB() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        urlapipb: config.powerbi_urlreport,
        datasets: this.state.datasetId,
        reportid: this.state.reportId,
        token: this.state.token,
      }),
    }

    fetch('/api/pb/api', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          this.setState({
            reportToken: data.token,
            isNewVersion: true,
            error: "",
            loading: false,
          })
        } else {
          this.setState({
            error: data.error,
            isNewVersion: false,
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({ error, isNewVersion: false, loading: false })
      })
  }

  componentDidMount() {
    const companyId = localStorage.getItem('itlg_default_company_id')
    this.setState({ companyId: companyId ? parseInt(companyId) : 1 })
  }

  render() {
    const { isNewVersion, reportToken } = this.state

    let DASHBOARD_LINKS = {
      diabetic:
        'https://app.powerbi.com/reportEmbed?reportId=c6e0e205-1346-4d4c-9791-9c7351dffbe3&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      internment:
        'https://app.powerbi.com/reportEmbed?reportId=416aa121-f4ca-4175-ac08-84c0ce2de28d&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
    }

    if (isNewVersion && reportToken) {
      const lang = (config.getLang() || 'es') as keyof typeof config.lang_pb
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: this.state.reportId,
              accessToken: this.state.reportToken,
              tokenType: models.TokenType.Embed,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                layoutType: models.LayoutType.Custom,
                localeSettings: { language: config.lang_pb[lang] },
              },
            }}
            cssClassName={'report-style-class'}
          />
        </div>
      )
    } else {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            title="Tablero Operativo"
            width="100%"
            height={window.innerHeight}
            src={DASHBOARD_LINKS[this.props.variant]}
            allowFullScreen
          ></iframe>
        </div>
      )
    }
  }
}

export default withTranslation()(Dashboard)
