import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { MedicalInformation, VolunteerActivism } from '../../icons';
import React, { useEffect, useState } from 'react';

import Button from '../CustomButtons/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CustomDialog from '../CustomDialog';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import config from '../../config/config';
import moment from 'moment';
import { primaryColor } from '../../assets/components/material-dashboard-pro-react';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();

const styles = makeStyles({
  dialogPlan: {
    '& .MuiPaper-rounded': {
      borderRadius: 2,
    },
    '& .MuiDialogTitle-root': {
      backgroundColor: primaryColor[0],
      paddingLeft: 0,
      paddingBottom: 0,
      '& h2': {
        color: 'white',
        padding: 0,
        '& span': {
          alignItems: 'flex-end',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 0,
          width: '100%',
          '& span': {
            fontSize: '4rem',
            fontWeight: 200,
            position: 'relative',
            top: 10,
            lineHeight: 1,
          },
          '& svg': {
            fontSize: '5rem',
            paddingBottom: 16,
          }
        }
      },
      '& .MuiButton-root': {
        display: 'none',
      }
    },
    '& .MuiDialogContent-root': {
      paddingTop: 24,
      paddingBottom: 24,
    },
  },
  progressContent: {
    minHeight: '40vh',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  medicalPlan: {
    minHeight: '40vh',
    padding: '0 12px 24px',
    '& h4': {
      marginBottom: 32,
      '& svg': {
        verticalAlign: 'middle',
      },
      '& strong': {
        display: 'inline-block',
        fontSize: '1.2rem',
        verticalAlign: 'middle',
      },
      '& small': {
        fontWeight: 400,
      }
    },
    '& h5': {
      fontSize: '1.2rem',
      fontWeight: 500,
      marginBottom: 16,
      marginTop: 24,
    },
  },
  label: {
    display: 'block',
    fontSize: '75%',
    opacity: .8,
    paddingBottom: 4,
  },
  count: {
    textAlign: 'right',
  },
  dates: {
    marginBottom: 16,
  },
  itemsPlan: {
    '& > .MuiGrid-item': {
      fontSize: '1rem',
      marginBottom: 12,
      color: '#383838',
    } 
  },
  buttonPlan: {
    borderRadius: 2,
    minWidth: 245,
    padding: '0.5rem 1.25rem',
  }
})

const MedicalPlan = ({ t, data, open, onClosePlan, openAlert }) => {
  const classes = styles();
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notResultMsg, setNotResultMsg] = useState('');

  const getPlan = () => {
    setIsLoading(true);
    PersonApiInvoker.getCustomerMedicalPlan(data.email, pl => {
      if (pl !== true) {
        setPlan(pl);
      } else {
        const message = t('medicalPlan.notResult');
        setNotResultMsg(message);
      }
      setIsLoading(false);
    }, (error) => {
      setIsLoading(false);
      const message = error.message ||  t('medicalPlan.404.error');
      openAlert({ type: 'danger', message});
    });
  }

  useEffect(() => {
    getPlan();
    return () => {
      setPlan([]);
      setNotResultMsg('');
    }
  }, [])

  const renderItemsPlan = (items) => <Grid
    container item
    className={`${classes.itemsPlan} padding-horizontal`}
  >
    {items.map((d) => (
        <Grid container item key={`item-plan-${d.medicalPlanDetailId}`}>
          <Grid item xs={10}>• {d.practiceType.name}</Grid>
          <Grid item xs={2} className={classes.count}>{d.count}</Grid>
        </Grid>
      )
    )}
  </Grid>

  const renderPlan = (items) => {
    const itemsList = items.map((it, index) => ({
      ...it,
      key: index+1,
    }))
    
    return itemsList.map((d) => {
      const fromDate = `${moment(d.validFrom).format(dateFormat)}`;
      const toDate = `${moment(d.validTo).format(dateFormat)}`;
      return (
        <Grid container className={classes.medicalPlan} key={`plan-${d.key}`}>
          <Grid item xs={12}>
            <h4>
              <CreditCardIcon /> <strong>{d.planName} <small>({data.code})</small></strong>
            </h4>
          </Grid>
          <Grid item container className={`${classes.dates} padding-horizontal`}>
            <Grid item xs={6}>
              <span className={classes.label}>{t('label.effectiveDateFrom')}</span>{fromDate}
            </Grid>
            <Grid item xs={6}>
              <span className={classes.label}>{t('label.effectiveDateUntil')}</span>{toDate}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h5><VolunteerActivism /> {t('label.practices')}:</h5>
          </Grid>
          {renderItemsPlan(d.planDetail)}
        </Grid>
      )
    })
  }

  const title = <><span>{t('label.medicalPlan')}</span><MedicalInformation /></>;
  
  return (
    <CustomDialog
      title={title}
      open={open}
      maxWidth={'sm'}
      className={classes.dialogPlan}
    >
      {isLoading &&
      <Grid container className={classes.progressContent}>
        <Grid item xs={12}><CircularProgress /></Grid>
      </Grid>
      }
      {notResultMsg && <Grid container className={classes.progressContent}>
        <Grid item xs={12}>{notResultMsg}</Grid>
      </Grid>}
      {plan && renderPlan(plan)}
      <Grid item xs={12} className="text-center">
        <Button
          id="button-close"
          size="sm"
          className={classes.buttonPlan}
          onClick={() => onClosePlan(false)}
          color="primary"
          data-testid="close-button" 
        >{ t('common.close') }</Button>
      </Grid>
    </CustomDialog>
  )
}

MedicalPlan.defaultProps = {
  open: false,
}

MedicalPlan.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClosePlan: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
}

export default withTranslation()(MedicalPlan);
