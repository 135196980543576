import './AppointmentDetail.css';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { DeleteDocument, HealthBook } from '../../../icons';
import React, { Component } from 'react';
import _, { isArray } from 'lodash';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import AppointmentToolsService from '../../../containers/AppointmentsToolsService';
import Assignment from '@material-ui/icons/Assignment';
import BeforeUnloadComponent from 'react-beforeunload-component';
import BookingDetail from '../../Bookings/BookingDetail';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import ConfirmAlert from '../../ConfirmAlert';
import CustomDialog from '../../CustomDialog';
import CustomInput from '../../CustomInput/CustomInput';
import CustomSweetAlert from '../../CustomSweetAlert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventWithIcon from '../../EventWithIcon/EventWithIcon';
import FullLoading from '../../FullLoading';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import SaveChanges from '../SaveChanges/SaveChanges';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import SchedulerService from '../../../containers/SchedulerService';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import ValidationInput from '../../ValidationInput';
import { browserHistory } from 'react-router';
import config from '../../../config/config';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateFormatTime = config.getDateTimeFormat();
const dateFormatServer = config.getDateToServer();
const dateTimeFormatServer = config.getDateTimeFormatServer();

config.updateMoment();


class AppointmentDetail extends Component {

  constructor(props) {
    super(props);
    const localizer = momentLocalizer(moment);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {},
        disability: {},
      },
      localizer,
      cancellations: [],
      cancellationReason: '',
      confirmMetadataEdit: false,
      currentUserId: localStorage.getItem("user_id"),
      saveChangesDisabled: false,
      bookings: [],
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      events: [],
      event_original: [],
      items: [],
      itemsCopy: [],
      updateErrors: {},
      status: '',
      alertErrorOpen: false,
      alertErrorMessage: '',
      alertOpen: false,
      alertStatus: '',
      appointmentBookingId: 0,
      showConfirmUndo: false,
      showConfirmSave: false,
      bookingErrors: [],
      alertMessageOpen: false,
      alertMessageColor: 'danger',
      alertMessageMsg: 'danger',
      rejecteds: [],
      calendarViewMode: 'month',
      scheduleStatus: '',
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      invalidStartDate: false,
      showErrorStartDayBefore: false,
      showAgendaPracticeDisplay: false,
      myPractices: [],
      selected_item: [],
      hasInvalidItemRequest: false,
      isDateRangeValid: true,
    }
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo();
  }

  onUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  }

  componentDidMount() {
    if (this.props.location.state.mode === 'edit') {
      window.addEventListener('beforeunload', this.onUnload, false);
    }
    if (this.props.location.state.mode !== 'copy') {
      this.fetchAppointment(true);
    }
  
    this.getAppointment();
    this.getCancellations();
  }

  componentWillUnmount() {
    if (this.props.location.state.mode === 'edit') {
      window.removeEventListener('beforeunload', this.onUnload, false);
    }
    window.removeEventListener('unload', this.componentCleanup);
  }

  componentWillMount() {
    window.addEventListener('unload', this.componentCleanup);
  }

  getCancellations = () => {
    AppointmentApiInvoker.getAppointmentCancellations(this.props.params.id, cancellations => {
      const cancelledTotal = cancellations.find(c => c.cancelType === 'Total') || {}
      this.setState({
        cancellationReason: cancelledTotal.reason || '',
        cancellations
      });
    }, (error) => {
      console.error('** error getAppointmentCancellations: ', error);
    })
  }

  setAppointmentCopy(app) {
    const today = moment().format(dateFormatServer);
    const endDateServ = moment(app.endDate);
    let startDate = moment(endDateServ).add(1, 'days');
    const realOriginStartDate = app.startDate;
    const realOriginEndDate = app.startDate;

    const diffStart = moment().diff(startDate, 'days');
    if (diffStart > 0) startDate = today;
    const diffEnd = moment(app.endDate).diff(app.startDate, 'days');
    const endDate = moment(startDate).add(diffEnd, 'days');

    app.startDate = startDate;
    //app.endDate = moment(endDate).format(dateFormatServer);
    app.endDate = endDate;
    app.toolsList = [];

    this.setState({
      customerId: app.customer.personId,
      provider: {},
      affiliateId: app.financier.financierId,
      addressId: app.address.addressId,
      creationEmployeeId: app.creationEmployee ? app.creationEmployee.personId : null,
      description: app.description,
      startDate: '',
      endDate: '',
      metadata: app.metadata,
      tools: [],
    });
    app.appointmentStatus = 'CREATED';
    this.fetchItems(app, realOriginStartDate, realOriginEndDate);
    this.props.onSelectAppointmentObject(app);
    this.setState({
      loading: false,
      customer: { zone: app.address.geographicZone.geographicZoneId }
    });
  }

  getAppointment = () => {
    const { location, params, t } = this.props;
    AppointmentApiInvoker.getAppointment(params.id, app => {
      if (location.state.mode === 'copy') {
        this.setAppointmentCopy(app);
        if(!app?.customer?.active){
            this.openAlert('danger', t('appointment.clone.reject'));
            setTimeout(() => {
                this.onBack();
            }, 2500);
        return; 
      }
      } else {
        if (app.appointmentStatus === 'SCHEDULES_ASSIGNED') {
          this.fetchItemsDetails();
        }
        this.props.onSelectAppointmentObject(app);
        this.setState({ metadata: app.metadata, loading: false, customer: { zone: app.address.geographicZone.geographicZoneId } });
      }
    }, (error) => {
      console.error('** error getAppointment: ', error);
    });
  }

  componentWillReceiveProps(next) {
    if (next.selectedAppointment?.metadata) {
      this.setState({ metadata: next.selectedAppointment.metadata });
    }
  }

  fetchAppointment = async (loading) => {
    const { params } = this.props;
    this.setState({ loading });
    AppointmentApiInvoker.getScheduleAppointment(
      params.id,
      events => {
        this.formatSchedules(events);
        this.setState({ loading: false });
        this.props.onGetSchedulesAppointment(events);
      }, (error) => {
        console.error('** error getScheduleAppointment: ', error);
      }
    )
  }

  fetchItems = async (app, realOriginStartDate, realOriginEndDate) => {
    const { location, params } = this.props;
    AppointmentApiInvoker.getAppointmentItems(params.id, (items) => {
        if (location.state.mode === 'copy') {
          const itn = [];
          items.forEach((b, i) => {
            console.log(b);
            const diffStart = moment(b.startDate).diff(realOriginStartDate, 'days');
            let startDate = moment(app.startDate).add(diffStart, 'days');
            let originaStartDatePractice = b.startDate;
            b.startDate = startDate;
            if (b.endDate != null && b.endDate != '') {
              let endDate = b.endDate.replace('"', '');
              const diffStartEnd = moment(endDate).diff(originaStartDatePractice, 'days');
              b.endDate = moment(b.startDate).add(diffStartEnd, 'days');
            }
            b.order = i;
            b.appointmentItemRequestStatus = 'CREATED';
            itn.push(b);
          })
          this.setState({ items: itn, itemsCopy: itn });
        } else {
          this.setState({ items });
        }
      }, (error) => {
        console.error('** error getAppointmentItems: ', error);
      }
    )
  }

  fetchItemsDetails = async () => {
    AppointmentApiInvoker.getAppointmentItemsDetails(
      this.props.params.id,
      items => {
        const resultItems = this.formatPracticeModule(items);
        this.setState({ items: resultItems });
      }, (error) => {
        console.error('** error getAppointmentItemsDetails: ', error);
      }
    )
  }

  getLenderName = (partnership) => {
    let isHeader = false;
    let companyPartnerShip = localStorage.getItem('company_partnership_id');

    if (companyPartnerShip == null) {
      isHeader = true;
      companyPartnerShip = localStorage.getItem('itlg_default_company_id');
    }
    const isNotHeader = partnership.companyId == companyPartnerShip ? partnership.name : this.props.t('common.other');
    return (isHeader ? partnership.name : isNotHeader);
  }

  formatPracticeModule = (items) => {
    const { t } = this.props;
    const result = items.map(b => {
      const lender = this.getLenderName(b.companyIdPartnership);
      const status = t(`status.appointmentItemRequest.${b.itemRequestStatus}`);

      return {
        order: b.order,
        practiceTypeLabel: b.packageLabel ? b.packageLabel : b.practiceTypeLabel,
        startDate: moment(b.startDate).format(dateFormat),
        endDate: moment(b.providerEndDate).format(dateFormat),
        quantity: b.quantity,
        practiceModule: (b.packageLabel ? t('Package') : t('Practice')),
        serviceProvider: b.serviceProvider,
        status,
        lender,
      }
    });
    return result;
  }

  undoChanges = () => this.setState({ showConfirmUndo: true });

  setProvider = (serviceProvider) => this.setState({ 'serviceProvider': serviceProvider });

  getServiceProviderOnEdit = stateItems => {
    let sprov = this.state.cancellations && this.state.cancellations.length > 0 ? this.state.cancellations[0].serviceProvider : {};
    sprov = this.state.serviceProvider ? this.state.serviceProvider : sprov;
    const itemsExist = stateItems && stateItems.length > 0;
    return itemsExist ? stateItems[0].serviceProvider : sprov; // agregar condicion para solicitudes canceladas --> this.state.cancellations;
  }

  getProvider = () => {
    if (!this.props.selectedAppointment?.customer) {
      return;
    }
    const {
      financier,
      customer,
      startDate,
      startPlanDate,
      endDate,
      endPlanDate,
      applicationClient,
    } = this.props.selectedAppointment;

    const isOriginPatient = applicationClient === 'appmobilepatient';
    const dateStart = isOriginPatient ? startPlanDate : startDate;
    const dateEnd = isOriginPatient ? endPlanDate : endDate;

    return {
      customerId: {
        customer: customer.personId
      },
      financier,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart,
      dateEnd,
      applicationClient,
    }
  }

  getCustomer = () => {
    if (!this.props.selectedAppointment?.customer) {
      return;
    }
    const {
      customer: { personId },
      address: { addressId },
    } = this.props.selectedAppointment;

    return {
      customer: personId,
      address: addressId,
    }
  }

  handleOnSelectedEvent = (event) => {
    this.props.selectedSchedules.map(sch => {
      if (sch.scheduleId === event.id) {
        this.setState({ scheduleStatus: sch.scheduleStatus });
      }
    });

    this.setState({
      openDetail: true,
      updateErrors: null,
      currentEvent: event,
    });
  }

  eventColors = (event, start, end, isSelected) => {
    const backgroundColor = event.color ? (`event-${event.color}`) : 'event-default';
    return {
      className: `${backgroundColor} ${(event.hasRejected) && this.state.calendarViewMode != 'agenda' ? 'event-rejected' : ''}`
    }
  }

  formatSchedules = (data) => {
    let schedules = [...data];
    schedules = _.orderBy(schedules, ['scheduleDateTime'], ['asc']);
    const result = schedules.map(b => {
      let isMePM = false;
      let companyPartnerShip = localStorage.getItem('company_partnership_id');
      if (companyPartnerShip == null) {
        companyPartnerShip = localStorage.getItem('itlg_default_company_id');
      }
      isMePM = b.companyIdPartnership.companyId == companyPartnerShip;
      return {
        id: b.scheduleId,
        title: b.appointmentDetail.practiceType.name,
        allDay: false,
        time_display: `${moment(b.scheduleDateTime).format('hh:mm a')} - ${moment(b.scheduleEndDateTime).format('hh:mm a')}`,
        time_start: moment(b.scheduleDateTime).format('hh:mm a'),
        start: new Date(b.scheduleDateTime),
        end: new Date(b.scheduleEndDateTime),
        view: this.state.calendarViewMode,
        hasRejected: b.scheduleStatus === 'REJECTED',
        color: isMePM ? this.getColor(b) : 'grey',
        employeeId: b.employee.personId,
        type: 'schedule',
        status: !b.status ? 'OK' : b.status,
      }
    });

    const oresult = result.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    this.setState({ event_original: oresult, events: result });
  }

  onBack = () => browserHistory.goBack();

  formatEventItem = (item) => {
    return {
      id: item.scheduleBookingId,
      title: item.label,
      allDay: false,
      time_display: `${moment(item.scheduleDateTime).format('hh:mm a')} - ${moment(item.scheduleEndDateTime).format('hh:mm a')}`,
      time_start: moment(item.scheduleDateTime).format('hh:mm a'),
      start: new Date(item.scheduleDateTime),
      end: new Date(item.scheduleEndDateTime),
      view: this.state.calendarViewMode,
      color: this.getColor(item.group),
      employeeId: item.employeeId,
      status: item.status,
      errorDetail: item.status === 'ERROR' || item.status === 'WARNING' ? item.errorDetail : '',
      type: item.booking || item.scheduleBookingId ? 'booking' : 'schedule',
    }
  }

  updateAppointment = (appointment) => {
    let result = this.state.events;
    this.setState({ updateErrors: null, status: null });

    if (!appointment?.status) {
      this.setState({
        updateErrors: [{
          detail: appointment.message,
          statusError: 'ERROR',
        }],
        status: 'ERROR',
      });
    }
    if (appointment?.status !== 'ERROR') {
      if (appointment.appointmentBookingId) {
        this.setState({ appointmentBookingId: appointment.appointmentBookingId })
      }
      this.state.events.forEach((b, i) => {
        if (b.id === this.state.currentEvent.id) {
          let item = appointment.bookings[0]
          result[i] = this.formatEventItem(item);
        }
      });
      this.setState({ events: _.orderBy(result, ['start'], ['asc']), openDetail: false, appointment: appointment.status });
      return;
    } 
    this.setState({ updateErrors: appointment.errors, status: appointment.status });
  }

  cancelSave = () => this.setState({ showConfirmSave: false, busy: false });

  openAlert = (color, message) => {
    this.setState({
      alertMessageColor: color,
      alertMessageMsg: message,
      alertMessageOpen: true,
    });

    setTimeout(() => {
      this.setState({ alertMessageOpen: false });
    }, 5000);
  }

  confirmUndo = () => {
    const { appointmentBookingId, event_original } = this.state;
    SchedulerInvoker.deleteBookingsByArray([appointmentBookingId], () => {
        const oresult = event_original.reduce((newArray, element) => {
          newArray.push(element);
          return newArray;
        }, []);

        this.setState({ events: oresult, showConfirmUndo: false, appointmentBookingId: null });
        this.openAlert('success', this.props.t('common.undoMessageConfirm'));
      }, (error) => {
        this.setState({ showConfirmUndo: false });
        const message = error.message ?? error;
        this.openAlert('danger', message + '');
      }
    )
  }

  //mlgmlgmlg
  assignAgenda() {
    AppointmentApiInvoker.getAppointmentItems(this.props.selectedAppointment.appointmentId,
      (res) => {
        let hasPrevius = false;
        res.forEach((e) => {
          const sDate = moment(e.startDate);
          const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          if (sDate < now) {
            hasPrevius = true;
          }
        });
        if (hasPrevius) {
          this.setState({ loading: false, busy: false, showErrorStartDayBefore: true })
        } else {
          this.confirmedAssignAgenda()
        }
      }, (error) => {
        console.error('** error getAppointmentItems', error);
      });
  }

  // MLGMLGMLG
  confirmedAssignAgenda = () => {
    if (this.state.selected_item.length == 0) {
      return;
    }
    this.setState({ showErrorStartDayBefore: false });

    this.setState({ showAgendaPracticeDisplay: true })
  }

  confirmedAssignAgendaReal = () => {
    this.setState({ loading: true, busy: true });
    let selected = JSON.stringify(this.state.selected_item).replace("[","").replace("]", "");
    const { selectedAppointment } = this.props;
    
    SchedulerInvoker.assignAgenda(
      selectedAppointment.appointmentId,
      selected,
      (res) => {
        if (res.errors?.length) {
          this.setState({ loading: false, alertOpen: true, alertStatus: res.status, bookings: res, bookingErrors: res.errors,  });
          return;
        }  
          this.afterSave(res);
    
      }, (error) => {
        this.setState({ loading: false, busy: false });
        this.handleSaveError(error);
      }
    );
  }

  formatSaveMetadata(metadata) {
    return {
      sensibility: metadata.sensibility,
      'medicalData': metadata.medicalData,
      'diagnostic': {
        'principalDiagnostic': {
          'id': _.get(metadata, 'diagnostic.principalDiagnostic.recordId'),
          'description': _.get(metadata, 'diagnostic.principalDiagnostic.name'),
          'category': _.get(metadata, 'diagnostic.principalDiagnostic.parentId'),
          'subcategory': _.get(metadata, 'diagnostic.principalDiagnostic.recordCategoryId'),
        },
        'hospitalizationDiagnostic': {
          'id': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.recordId'),
          'description': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.name'),
          'category': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.parentId'),
          'subcategory': _.get(metadata, 'diagnostic.hospitalizationDiagnostic.recordCategoryId'),
        },
        'complexity': {
          'id': _.get(metadata, 'diagnostic.complexity.id'),
          'description': _.get(metadata, 'diagnostic.complexity.name', _.get(metadata, 'diagnostic.complexity.description')),
        },
        'barthelIndex': {
          'id': _.get(metadata, 'diagnostic.barthelIndex.id'),
          'description': _.get(metadata, 'diagnostic.barthelIndex.name', _.get(metadata, 'diagnostic.barthelIndex.description')),
        },
        'observations': _.get(metadata, 'diagnostic.observations', _.get(metadata, 'observations.description')),
      },
      'disability': {
        'disabledPatient': _.get(metadata, 'disability.disabledPatient') || false,
        'activeDisabilityCertificate': _.get(metadata, 'disability.activeDisabilityCertificate') || false,
        'disabilityCertificateCode': _.get(metadata, 'disability.disabilityCertificateCode') || null,
      },
    }
  }

  saveMetadata(skipAssignAgenda) {
    const { location, selectedAppointment } = this.props;
    if (location.state.mode === 'assign_agenda') {
      this.assignAgenda();
      return;
    }
    const { metadata } = this.state;
    const meta = this.formatSaveMetadata(metadata);

    AppointmentApiInvoker.updateAppointmentMetadata(selectedAppointment.appointmentId, meta, () => {
        setTimeout(() => {
          this.setState({ confirmMetadataEdit: false, saveChangesDisabled: true, busy: false },
            () => {
              if (skipAssignAgenda) {
                return;
              }
              this.setState({ busy: false });
              this.assignAgenda();
            }
          );
        }, 1000);
      }, (e) => {
        this.handleSaveError(e);
        this.setState({ busy: false });
      });
  }

  afterSave(bookings) {
    const { t, selectedAppointment } = this.props;
    this.fetchAppointment(false);
    this.openAlert('success', t('appointment.message.success'));
    this.setState({ showConfirmSave: false, loading: false });
    const selected = JSON.stringify(this.state.selected_item).replace("[","").replace("]", "");
    browserHistory.push({
      state: {
        appointmentId: selectedAppointment.appointmentId,
        bookings: bookings,
        items: selected,
        fromAppointmentAssignAgenda: true,
        dates: {
          startDate: selectedAppointment.dateStart,
          endDate: selectedAppointment.dateEnd
        }
      },
      pathname: '/turnos',
    });
  }

  validateFields = () => {
    const { addressId, affiliateId, customerId, financierId, metadata, provider } = this.state;
    let diagnostic;
    let secondary;
    if (metadata?.diagnostic?.hospitalizationDiagnostic?.recordId) {
      diagnostic = true;
      secondary = true;
    }
    const isValidate = (
      !customerId ||
      !addressId ||
      !provider ||
      !provider.dateStart ||
      !provider.dateEnd ||
      !financierId ||
      !affiliateId ||
      !diagnostic ||
      !secondary
    )
    return !isValidate;
  }

  formatSchedulePlan = (s) => {
    const schedulePlan = s.schedulePlan;
    if (schedulePlan.recurrenceType === 'INTRADAY') {
      schedulePlan.processingScheduleType = 'INTRADAY';
      schedulePlan.processingStartTimes = null;
      schedulePlan.every = 1;
      schedulePlan.recurrenceType = 'WEEK';
    }
    schedulePlan.weekDays = Array.isArray(schedulePlan.weekDays) ? schedulePlan.weekDays.join(',') : schedulePlan.weekDays;
    return schedulePlan;
  }

  buildFormatDateToServer(date) {
    if (!date) {
      return null;
    }
    return moment(date).format(dateFormatServer);
  }

  formatDataItem = (s, i) => {
    const companyIdPartnership = s.companyIdPartnership.companyId ? s.companyIdPartnership : { "companyId": s.companyIdPartnership };
    const startDate = this.buildFormatDateToServer(s.startDate);
    const endDate = this.buildFormatDateToServer(s.endDate);
    return {
      order: i + 1,
      companyIdPartnership,
      creationEmployeeId: localStorage.getItem('user_id'),
      startDate,
      endDate,
      startTime: s.startTime,
      endTime: s.endTime,
      appointmentItemRequestStatus: 'CREATED',
      observations: s.observations,
      serviceProvider: {
        serviceProviderId: s.serviceProvider.serviceProviderId
      },
    }
  }

  formatItemRequestDetails = (s) => {
    const items = [];
    s.itemRequestDetails.forEach((c) => {
      const sa = {
        packageDetailId: null,
        filerByZone: c.filerByZone,
        practiceTypeId: c.practiceTypeId,
        suggestedEmployeeId: c.suggestedEmployeeId,
        creationEmployeeId: localStorage.getItem('user_id'),
      }
      items.push(sa);
    });
    return items;
  }

  formatTime = (time) => moment(time, 'HH:mm:SS').format('hh:mm a');
  
  formatRecurrence = (recurrence) => {
    return Array.isArray(recurrence) ? recurrence.join(',') : recurrence;
  };
  
  formatDetailsRow = (m) => {
    const weekRecurrence = this.formatRecurrence(m.weekRecurrence);
    const weekDays = this.formatRecurrence(m.weekDays);
    const startTime = m.startTime ? this.formatTime(m.startTime) : '10:00 am';
    const endTime = m.endTime ? this.formatTime(m.endTime) : '11:00 pm';
  
    return {
      practiceTypeId: m.practiceTypeId,
      creationEmployeeId: localStorage.getItem('user_id'),
      recurrentType: m.recurrenceType,
      every: m.every,
      endType: m.endType,
      processingScheduleType: m.processingScheduleType,
      processingDurationTime: m.processingDurationTime,
      weekDays: m.weekRecurrence ? weekRecurrence : weekDays,
      monthDay: m.monthRecurrence ?? null,
      ocurrences: m.ocurrences,
      observations: m.observations ?? undefined,
      startTime,
      endTime,
    };
  };
  
  formatItemRequestPackageDetails = (s) => {
    let itemRequestPackageDetails = [];
    if (s.itemRequestPackageDetails) {
      s.itemRequestPackageDetails.forEach(m => {
        const row = this.formatDetailsRow(m);
        if (row.startTime.indexOf("am") || row.startTime.indexOf("pm")) {
          row.startTime =  moment(row.startTime, 'hh:mm a').format('HH:mm');
        }
        if (row.endTime.indexOf("am") || row.endTime.indexOf("pm")) {
          row.endTime =  moment(row.endTime, 'hh:mm a').format('HH:mm');
        }
        itemRequestPackageDetails.push(row);
      });
    }
  
    return itemRequestPackageDetails;
  };
  
  formatItemsPractices = (data, s) => {
    const items = this.formatItemRequestDetails(s);
    return {
      ...data,
      practiceTypeId: s.practiceTypeId,
      itemRequestDetails: items,
      appointmentItemRequestStatus: 'CREATED',
      companyIdPartnership: (s.companyIdPartnership.companyId ? s.companyIdPartnership : {"companyId": s.companyIdPartnership}),
      packageId: null,
      creationEmployeeId: localStorage.getItem('user_id'),
      schedulePlan: this.formatSchedulePlan(s),
    }
  }

  formatItemsPackages(data, s) {
    const itemRequestPackageDetails = this.formatItemRequestPackageDetails(s);
    return {
      ...data,
      packageId: s.packageId,
      appointmentItemRequestStatus: 'CREATED',
      companyIdPartnership: s.companyIdPartnership,
      packageQuantity: s.packageQuantity ? s.packageQuantity : null,
      itemRequestDetails: s.itemRequestDetails,
      creationEmployeeId: localStorage.getItem('user_id'),
      itemRequestPackageDetails,
    }
  }

  formatItems() {
    const { itemsCopy } = this.state;
    return itemsCopy.map((s, i) => {
      const data = this.formatDataItem(s, i);
      if (s.practiceTypeId) {        
        return this.formatItemsPractices(data, s);
      }
      return this.formatItemsPackages(data, s);
    });
  }

  validateDateStart = () => {
    let notDateNull = true;
    this.state.itemsCopy.forEach((b, i) => {
      if (b.startDate === '') {
        notDateNull = false;
      }
    });
    return notDateNull;
  }

  validateMinStartDate = (date) => {
    const dif = moment().diff(date, 'days');
    return dif > 0;
  }

  showDialogSaveNewAppointment = () => {
    const isValidateMinDate = this.validateMinStartDate(this.state.provider.dateStart);
    if (isValidateMinDate) {
      this.setState({ invalidStartDate: isValidateMinDate, checkInvalid: true });
      return;
    }
    this.setState({ confirmOpenNewAppointment: true });
  }

  formatDiagnostic = (diagnostic) => ({
    id: diagnostic?.recordId,
    description: diagnostic?.name,
    category: diagnostic?.parent?.parent?.recordId ?? null,
    subcategory: diagnostic?.parent?.recordId ?? null,
  });
  
  formatBarthelIndex = (barthelIndex) => ({
    id: barthelIndex?.id || null,
    description: barthelIndex?.name || barthelIndex?.description || null,
  });

  formatScheduleMetadata(metadata) {
    const { diagnostic, medicalData, disability } = metadata;
    const complexityDescription = diagnostic?.complexity?.name || diagnostic?.complexity?.description;
  
    return {
      sensibility: 1,
      medicalData: {
        requestor: medicalData?.requestor,
        auditor: medicalData?.auditor,
        family: medicalData?.family
      },
      diagnostic: {
        principalDiagnostic: this.formatDiagnostic(diagnostic?.principalDiagnostic),
        hospitalizationDiagnostic: this.formatDiagnostic(diagnostic?.hospitalizationDiagnostic),
        complexity: {
          id: diagnostic?.complexity?.id || null,
          description: diagnostic?.complexity ? complexityDescription : null,
        },
        barthelIndex: this.formatBarthelIndex(diagnostic?.barthelIndex),
        observations: diagnostic?.observations || '',
      },
      disability: {
        disabledPatient: disability?.disabledPatient || false,
        activeDisabilityCertificate: disability?.activeDisabilityCertificate || false,
        disabilityCertificateCode: disability?.disabilityCertificateCode || null,
      },
    };
  }

  formatDatesToServer(dateStart, dateEnd) {
    const startDate = moment(dateStart, [dateFormatServer, dateTimeFormatServer]).format(dateFormatServer)
    const endDate = moment(dateEnd, [dateFormatServer, dateTimeFormatServer]).format(dateFormatServer)
    return { startDate, endDate };
  }

  formatNewSchedule(items) {
    const { addressId, affiliateId, financierId, customerId, diagnostic, metadata, provider } = this.state;
    const { startDate, endDate } = this.formatDatesToServer(provider.dateStart, provider.dateEnd)
    return {
      affiliateId,
      financierId,
      addressId,
      customerId,
      creationEmployeeId: localStorage.getItem('user_id'),
      description: diagnostic,
      items,
      startDate,
      endDate,
      metadata: this.formatScheduleMetadata(metadata),
    };
  }

  postAppointment(body) {
    SchedulerInvoker.postAppointment(body, appointement => {
      if (appointement.status === 'OK') {
        this.setState({ loading: false, appointmentId: appointement.appointmentId }, () => {
          this.performSaveTools(appointement.appointmentId);
        });
        return;
      }
      this.setState({
        alertOpen: true,
        confirmOpen: false,
        checkInvalid: false,
        bookingErrors: appointement.errors,
        alertStatus: appointement.status,
        bookingsResult: appointement,
        loading: false,
      });
    }, (error) => {
      console.log('** AppoDetail postAppointment', error)
      this.setState({ loading: false });
      const message = error.message ?? error;
      this.openAlert('danger', message + '');
    });
  }

  // save copy
  saveNewSchedule() {
    const { t } = this.props;

    if (!this.validateFields()) {
      this.setState({ checkInvalid: true, confirmOpenNewAppointment: false });
      this.openAlert('warning', t('appointment.new.requiredFields'));
      return;
    }

    if (!this.validateDateStart()) {
      this.setState({ checkInvalid: true, confirmOpenNewAppointment: false });
      this.openAlert('warning', t('appointment.new.haspracticewithoutdate'));
      return;
    }
    this.setState({ loading: false, confirmOpenNewAppointment: false });
    const items = this.formatItems();
    const body = this.formatNewSchedule(items);
    this.postAppointment(body);
  }

  performSaveTools(appointmentId) {
    this.setState({
      loading: false,
      appointmentId,
      performAppointmentToolsSave: true,
    });
  }

  saveAppointmentTools = () => browserHistory.push('/solicitudes');

  handleAppointmentToolsSaveError = () => browserHistory.push('/solicitudes');

  handleSaveError(error) {
    this.setState({
      loading: false
    })
    if (error.message) {
      if (error.message == 'TIME_OUT_EXCEPTION') {
        this.setState({ showTimeOutException: true });
        return;
      }
    }
    const message = error.message ?? error;
    this.openAlert('danger', message + '');
    this.setState({ showConfirmSave: false });
  }

  postSaveBookings() {
    const { t } = this.props;
    SchedulerInvoker.postSaveBookings(
      this.state.appointmentBookingId,
      data => {
        if (data.message) {
          this.openAlert('success', data.message);
        }
        this.openAlert('success', t('appointment.message.success'));
        this.fetchAppointment(false);
        this.props.onSaveBookings();
        this.setState({ showConfirmSave: false });
      }, (error) => {
        const message = error.message ?? error;
        this.openAlert('danger', message + '');
        this.setState({ showConfirmSave: false, busy: false });
      }
    );
  }

  confirmSave() {
    const { location } = this.props;
    if (location.state.mode === 'edit') {
      this.postSaveBookings();
      return;
    }
    if (location.state.mode === 'assign_agenda') {
      this.saveMetadata();
      return;
    }
    this.assignAgenda();
  }

  cancelUndo = () => {
    this.setState({ showConfirmUndo: false });
  }

  deleteBookings = () => {
    this.setState({ openConfirmCancel: false });
    SchedulerInvoker.deleteBookings(this.state.appointmentBookingId);
    browserHistory.push('/solicitudes');
  }

  saveBookings = () => this.setState({ showConfirmSave: true, busy: false });

  getColor = (status) => {
    let colors = {
      0: 'E157CA',
      1: 'F06292',
      2: '5AB4FF',
      3: '79009B',
      4: 'D81B60',
      5: '0A85FF',
      6: 'BF00B0',
      7: '880E4F',
      8: '1F3FB9',
    };
    return colors[status.group % 9];
  }

  setAuthorizerMetadata(value) {
    const currMeta = this.state.metadata;
    const { family, requestor, auditor } = value;
    const newMeta = {
      ...currMeta,
      medicalData: {
        family, requestor, auditor,
      }
    };
    this.setState({ metadata: newMeta });
  }

  setDisabilityMetadata(value) {
    const currMeta = this.state.metadata;
    const { activeDisabilityCertificate, disabledPatient, disabilityCertificateCode } = value;
    const newMeta = {
      ...currMeta,
      disability: {
        disabledPatient,
        activeDisabilityCertificate,
        disabilityCertificateCode,
      }
    };
    this.setState({ metadata: newMeta });
  }

  setDiagnostic(value, field) {
    const currMeta = this.state.metadata;
    const newMeta = {
      ...currMeta,
      diagnostic: {
        ...currMeta.diagnostic,
        [field]: value,
      }
    };
    this.setState({ metadata: newMeta });
  }

  onChangeView = (event) => {
    this.state.events.forEach((b, i) => {
      b.view = event;
    });

    this.setState({ calendarViewMode: event });
  }

  confirmSaveMetadata = () => this.setState({ confirmMetadataEdit: true, busy: true });

  undoChangesMetadata(initialState) {
    const [observations, barthelIndex, complexity, principalDiagnostic, hospitalizationDiagnostic, medicalData, disability] = initialState;
    
    this.setState((prev) => ({
      metadata: {
        sensibility: prev.metadata.sensibility,
        medicalData,
        diagnostic: {
          observations,
          barthelIndex,
          complexity,
          principalDiagnostic: {
            recordId: principalDiagnostic,
            id: principalDiagnostic,
          },
          hospitalizationDiagnostic: {
            recordId: hospitalizationDiagnostic,
            id: hospitalizationDiagnostic,
          },
        },
        disability,
      }
    }));
  }

  formatPostModules(itemRequestPackageDetails) {
    if (!itemRequestPackageDetails) {
      return null;
    }
    return itemRequestPackageDetails.map((req) => ({
      ...req,
      endTime: req.endTime ? moment(req.endTime, 'hh:mm a').format('HH:mm') : null,
      startTime: req.startTime ? moment(req.startTime, 'hh:mm a').format('HH:mm') : null,
    }));
  }

  buildBodyNew(appointementScheduleItem) {
    appointementScheduleItem.appointmentItemRequestStatus = "CREATED";
    const isIntraday = appointementScheduleItem.schedulePlan && appointementScheduleItem.schedulePlan.recurrenceType === 'INTRADAY';
    if (isIntraday) {
      appointementScheduleItem.schedulePlan.recurrenceType = 'WEEK';
      appointementScheduleItem.schedulePlan.weekDays = isArray(appointementScheduleItem.schedulePlan.weekDays) ?
      appointementScheduleItem.schedulePlan.weekDays.join(',') : appointementScheduleItem.schedulePlan.weekDays
    } else if (appointementScheduleItem.schedulePlan) {
      appointementScheduleItem.schedulePlan.weekDays = isArray(appointementScheduleItem.schedulePlan.weekDays) ?
      appointementScheduleItem.schedulePlan.weekDays.join(',') : appointementScheduleItem.schedulePlan.weekDays
    }
    if (appointementScheduleItem.companyIdPartnership.companId == undefined) {
      appointementScheduleItem.companyIdPartnership = { "companyId": appointementScheduleItem.companyIdPartnership };
    }
    if (appointementScheduleItem.observations) {
      const isSliceText = appointementScheduleItem.observations.length > 600;
      appointementScheduleItem.observations = isSliceText ? appointementScheduleItem.observations.slice(0, 600) : appointementScheduleItem.observations;
    }
    appointementScheduleItem.startDate = appointementScheduleItem.startDate ?? null;
    appointementScheduleItem.endDate = appointementScheduleItem?.endDate ?? null;
    appointementScheduleItem.creationEmployeeId = localStorage.getItem('user_id');
    return [appointementScheduleItem];
  }


  saveNewPractices(appointementScheduleItem, cb = () => { }) {

    appointementScheduleItem.itemRequestPackageDetails = this.formatPostModules(appointementScheduleItem.itemRequestPackageDetails);
    appointementScheduleItem.creationEmployeeId = localStorage.getItem('user_id');
    if (this.props.location.state.mode !== 'copy') {
      const data = this.buildBodyNew(appointementScheduleItem);
      const { appointmentId } = this.props.selectedAppointment;

      AppointmentApiInvoker.postAppointmentScheduleItems(appointmentId, data, (res) => {
        if(data[0].file && Object.keys(data[0].file).length > 0){

          const filesSave = res;
          const uploadedFiles = data[0].file; 
  
          const sendFile = (body) => {
            AppointmentApiInvoker.postSaveFile(body, (res) => {
              console.log('Respuesta del servicio:', res);
            });
          };
  
          filesSave.forEach((item) => {
            const matchingItem = uploadedFiles; 
            
            if (item.order === data[0].order) {
              const { name, type, date, base64 } = matchingItem
  
              const [day, month, year, hourRaw, minute, period] = date.split(/\/|\s|:/)
  
              const hour = period.toLowerCase() === 'p. m.' && hourRaw !== '12'
                ? (parseInt(hourRaw) + 12).toString()
                : hourRaw === '12' && period.toLowerCase() === 'a. m.'
                ? '00'
                : hourRaw;
  
              const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
  
              const body = {
                name: name || 'file',
                extension: type.split('/')[1].toUpperCase(),
                file: base64,
                appointmentItemRequestId: item.appointmentItemRequestId,
                dateUpload: formattedDate,
              };
  
              // Enviar el objeto creado al servicio
              sendFile(body);
            }
          });
        }

        this.getAppointment();
        return cb(res);
      }, (error) => {
        console.error('** error postAppointmentScheduleItems:', error);
      });
      return;
    }
    const itemsCopyNew = this.state.itemsCopy;
    itemsCopyNew.push(appointementScheduleItem);
    const nItems = [];
    this.state.itemsCopy.forEach((b, i) => {
      b.order = i;
      nItems.push(b);
    });
    this.setState({ itemsCopy: nItems });
  }

  replacePractices = (itemCopy) => {
    const itemsCopyNew = [];
    this.state.itemsCopy.forEach((b, i) => {
      if (b.order === itemCopy.order) {
        itemCopy.order = i
        itemsCopyNew.push(itemCopy);
      } else {
        b.order = i;
        itemsCopyNew.push(b);
      }
    });
    this.setState({ itemsCopy: itemsCopyNew });
  }

  deletePractices(practiceToDelete, appointmentItemRequestIdToDelete) {
    if (this.props.location.state.mode === 'copy') {
      const itemsCopyNew = [];
      let index = 0;
      this.state.itemsCopy.forEach((b, i) => {
        if (b.order !== appointmentItemRequestIdToDelete) {
          b.order = index;
          itemsCopyNew.push(b);
          index++;
        }
      })
      this.setState({ itemsCopy: itemsCopyNew });
      return;
    }

    if (!appointmentItemRequestIdToDelete) {
      const appScheduleItem = this.state.items.find(i => {
        if (i.practiceTypeId) {
          return i.practiceTypeId === practiceToDelete.practiceTypeId;
        } else if (i.packageId) {
          return i.packageId === practiceToDelete.packageId;
        } else {
          return false;
        }
      })
      if (appScheduleItem) {
        AppointmentApiInvoker.deleteAppointmentScheduleItems(appScheduleItem.appointmentItemRequestId, (res) => {
        }, (res) => {
          console.log(res);
        });
      }
    } else {
      AppointmentApiInvoker.deleteAppointmentScheduleItems(appointmentItemRequestIdToDelete, (res) => {
      }, (res) => {
        console.log(res);
      })
    }
  }

  setProviderF(value) {
    this.setState({
      provider: value,
    }, () => { });
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: (value.financierId) ? value.financierId : '',
      affiliateId: (value.affiliateId) ? value.affiliateId : '',
    });
  }

  getProviderValue(selectedAppointment) {
    return selectedAppointment ? this.getProvider() : null;
  }

  alertErrorStartDate = (t) => (
    <CustomSweetAlert
      type="danger"
      onConfirm={() => this.setState({ invalidStartDate: false })}
      confirmBtnCssClass="danger"
      confirmBtnText={t('common.cancel')}
      showCancel={false}
      title={t('appointment.new.appointmentReview')}
      message={<p>{t('appointment.invalidDate')}</p>}
    />
  );

  cancellationsTableData = (data, t) => data.map(cancelation => {
    return {
      'order': cancelation.order,
      'type': t(cancelation.type),
      'speciality': cancelation.speciality,
      'count': cancelation.count,
      'startDate': moment(cancelation.startDate).format(dateFormat),
      'cancelDate': moment(cancelation.cancelDate).format(dateFormat),
      'reason': cancelation.cancelType === 'Total' ? '' : cancelation.reason,
    }
  });

  getColorIcon(isEvents, isCancellations) {
    switch (true) {
      case (isEvents) && (isCancellations):
      case (!(isEvents) && !(isCancellations)):
        return 'secondary';
    
      default:
        return 'primary';
    }
  }

  renderModalComponentHandler(handleModalLeave, handleModalCancel) {
    const { t } = this.props;
    const { appointmentBookingId } = this.state;
    const mThis = this;
    if (!appointmentBookingId) {
      handleModalLeave()
      return;
    }
    return (
      <CustomSweetAlert
        type="warning"
        onConfirm={() => mThis.confirmUndo()}
        onCancel={() => handleModalCancel()}
        confirmBtnCssClass="primary"
        cancelBtnCssClass="danger"
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.confirm')}
        showCancel
        title={t('reload.site.title')}
        message={<p>{t('reasign.reload.site.changes.lost')}</p>}
      />
    )
  }

  getVarsRender() {
    const { t, location, selectedAppointment, selectedSchedules } = this.props;
    const { cancellations, events, event_original, selected_item, saveChangesDisabled, isDateRangeValid } = this.state;
    const eventChange = (JSON.stringify(events) !== JSON.stringify(event_original));
    const viewMode = location.state.mode;
    const company_partnership_id = localStorage.getItem('company_partnership_id');
    let authorityAgenda = location.state?.authority && location.state.authority === 'appointment_edit_agenda';
    if (company_partnership_id != null) {
      authorityAgenda = true;
    }
    const cancellationsCanceled = cancellations.filter(c => c.canceled);
    const cancellationsData = this.cancellationsTableData(cancellationsCanceled, t);
    const cancellationsAppoStatus = ['CANCELLED', 'SCHEDULES_ASSIGNED', 'OVERDUE', 'ENDED'];
    const isCancellationsAppoStatusOk = cancellationsAppoStatus.find(f => f === selectedAppointment.appointmentStatus) !== undefined;
    const viewPracticesModulesTable = viewMode === 'view' || viewMode === 'assign_agenda' || viewMode === 'copy' || viewMode === 'edit' || selectedAppointment.appointmentStatus === 'CANCELLED';
    const isEvents = events.length > 0 && viewMode !== 'copy';
    const isCancellations = cancellationsCanceled.length > 0 && isCancellationsAppoStatusOk;
    const showCoreFrames = selectedAppointment.applicationClient !== 'appmobilepatient';
    const agendaIconColor = showCoreFrames ? 'secondary' : 'primary';
    const cancelIconColor = showCoreFrames && isEvents ? 'primary' : 'secondary';
    const toolsIconColor = this.getColorIcon(isEvents, isCancellations);
    const hasItems = selected_item.length === 0;
    const disabledScheduleDetail = !((viewMode === 'edit' || viewMode === 'copy') && (selectedAppointment.appointmentStatus === 'CREATED' || selectedAppointment.appointmentStatus === 'PARTIAL_ASSIGNED' || selectedAppointment.appointmentStatus === 'SCHEDULES_ASSIGNED'));
    const disabledForms = (viewMode !== 'edit' && viewMode !== 'copy') || (viewMode === 'edit' && authorityAgenda);
    const disabledSaveChanges = saveChangesDisabled || !_.get(this.state, 'metadata.diagnostic.principalDiagnostic.id', _.get(this.state, 'metadata.diagnostic.principalDiagnostic.recordId')) || !_.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.id', _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.recordId'));
    const isEventsError = selectedSchedules?.some(schedule => schedule.scheduleStatus === 'REJECTED');

    return {
      agendaIconColor, authorityAgenda, cancellationsData, cancelIconColor, company_partnership_id, disabledScheduleDetail, disabledForms, disabledSaveChanges,
      eventChange, hasItems, isCancellations, isEvents, isEventsError, showCoreFrames, toolsIconColor, viewMode, viewPracticesModulesTable, isDateRangeValid
    }
  }

  isLoading(props, state) {
    const { selectedAppointment } = props;
    const { appointmentId, loading } = state;
    return loading || (!appointmentId && !selectedAppointment);
  }

  getCreationEmployeeName(selectedAppointment) {
    return selectedAppointment?.creationEmployee ? `${selectedAppointment.creationEmployee.lastName} ${selectedAppointment.creationEmployee.firstName}`: '-';
  }

  getAppointmentStatus(appointmentStatus) {
    const { t } = this.props;
    return appointmentStatus ? t(`appointment.table.status.${appointmentStatus}`) : '';
  }

  getCreateDatetime(createdDateTime) {
    return createdDateTime ? moment(createdDateTime).format(dateFormatTime) : null;
  }

  getStartEndDates(viewMode, selectedAppointment) {
    const startDate = viewMode === 'copy' && this.state.provider ? this.state.provider.dateStart : selectedAppointment.startDate;
    const endDate = viewMode === 'copy' && this.state.provider ? this.state.provider.dateEnd : selectedAppointment.endDate;

    return {
      startDate,
      endDate,
    }
  }

  getRenderItems(viewMode) {
    return viewMode == 'copy' ? this.state.itemsCopy : this.state.items;
  }

  getCancellationsText() {
    const { t, selectedAppointment } = this.props;
    return selectedAppointment.appointmentStatus === 'CANCELLED' ? t('appointment.cancellations.type.total') : t('appointment.cancellations.type.partial');
  }

  renderCancellations() {
    const { t, selectedAppointment } = this.props;
    const { cancellationsData, cancelIconColor, isCancellations } = this.getVarsRender();
    if (isCancellations) {
      return (
        <GridItem xs={12} className="cancellations">
          <Card>
            <CardHeader icon>
              <CardIcon color={cancelIconColor}>
                <DeleteDocument />
              </CardIcon>
              <h3 className="card-icon-title">{t('appointment.cancellations.title')}</h3>
            </CardHeader>
            <CardBody>
              <Table
                filtrable={false}
                loading={this.state.loading}
                tableHeaderColor="primary"
                defaultPageSize={5}
                tableHead={[
                  { Header: '#', accessor: 'order' },
                  { Header: t('cancellations.typePn'), accessor: 'type' },
                  { Header: t('cancellations.name'), accessor: 'speciality' },
                  { Header: t('cancellations.quantity'), accessor: 'count' },
                  { Header: t('cancellations.date_init'), accessor: 'startDate' },
                  { Header: t('cancellations.date_cancel'), accessor: 'cancelDate' },
                  { Header: t('cancellations.reason'), accessor: 'reason' },
                ]}
                sortable={false}
                tableData={cancellationsData}
                colorsColls={['primary']}
                showPaginationBottom={true}
              />
              <GridContainer className="cancellation-container">
                <GridItem xs={4}>
                  <CustomInput
                    labelText={t('appointment.cancellations.type')}
                    value={this.getCancellationsText()}
                    formControlProps={{ disabled: true, fullWidth: true }}
                  />
                </GridItem>
                {selectedAppointment.appointmentStatus === 'CANCELLED' &&
                  <GridItem xs={8}>
                    <CustomInput
                      labelText={t('appointment.cancellations.reason')}
                      value={this.state.cancellationReason || t('appointment.cancellations.undefined')}
                      formControlProps={{ disabled: true, fullWidth: true, }}
                    />
                  </GridItem>
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      );
    }
  }

  getComponentAction(authorityAgenda, viewMode) {
    return authorityAgenda || viewMode === 'view' || viewMode === 'assign_agenda' ? 'view' : 'edit';
  }

  renderAuthorizerDiagnosticFrom() {
    const { t, classes } = this.props;
    const { authorityAgenda, company_partnership_id, disabledForms, disabledSaveChanges, showCoreFrames, viewMode } = this.getVarsRender();
    const showButtons = viewMode === 'edit' && company_partnership_id == null && !authorityAgenda;
    
    if (showCoreFrames) {
      return <GridItem xs={12} id="authorizer-diagnostic">
        <Card className="authorizer-diagnostic-forms">
          <CardHeader icon className={classes.cardHeader}>
            <CardIcon color="secondary">
              <HealthBook />
            </CardIcon>
            <h3 className="card-icon-title">{t('appointment.clinicalData')}</h3>
          </CardHeader>
          <CardBody>
            <SchedulerService.AuthorizerFormService
              disabled={disabledForms}
              value={{ metadata: this.state.metadata?.medicalData }}
              onFilledAuthorizer={(value) => this.setAuthorizerMetadata(value)}
            />
            <SchedulerService.DisabilityFormService
              disabled={disabledForms}
              value={{ metadata: this.state.metadata?.disability }}
              onFilledDisability={(value) => this.setDisabilityMetadata(value)}
            />
            <SchedulerService.DiagnosticFormService
              disabled={disabledForms}
              observation={this.state.metadata?.diagnostic?.observations}
              barthel={this.state.metadata?.diagnostic?.barthelIndex}
              accessor={'id'}
              complexity={this.state.metadata?.diagnostic?.complexity}
              patology={this.state.metadata?.diagnostic?.principalDiagnostic}
              hospitalPatology={this.state.metadata?.diagnostic?.hospitalizationDiagnostic}
              onFilledPatology={(value) => this.setDiagnostic(value, 'principalDiagnostic')}
              onFilledHospitalPatology={(value) => this.setDiagnostic(value, 'hospitalizationDiagnostic')}
              onFilledBarthel={(value) => this.setDiagnostic(value, 'barthelIndex')}
              onFilledComplexity={(value) => this.setDiagnostic(value, 'complexity')}
              onFilledObservation={(value) => this.setDiagnostic(value, 'observations')}
            />
            {showButtons &&
              <SaveChanges
                setNewInitialState={this.state.saveChangesDisabled}
                disabled={disabledSaveChanges}
                undoText={t('label.undo')}
                applyText={t('saveChanges.apply')}
                observedValues={[
                  _.get(this.state, 'metadata.diagnostic.observations'),
                  _.get(this.state, 'metadata.diagnostic.barthelIndex'),
                  _.get(this.state, 'metadata.diagnostic.complexity'),
                  _.get(this.state, 'metadata.diagnostic.principalDiagnostic.id',
                  _.get(this.state, 'metadata.diagnostic.principalDiagnostic.recordId')),
                  _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.id',
                  _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.recordId')),
                  _.get(this.state, 'metadata.medicalData'),
                  _.get(this.state, 'metadata.disability'),
                ]}
                onChangeDetected={() => {
                  this.setState({ saveChangesDisabled: false });
                }}
                onSaveChanges={() => {
                  this.confirmSaveMetadata();
                }}
                onUndoChanges={(initialState) => {
                  this.undoChangesMetadata(initialState);
                }}
              />
            }
          </CardBody>
        </Card>
      </GridItem>
    }
  }

  renderPracticesModulesTable() {
    const { location, params, selectedAppointment } = this.props;
    const { disabledScheduleDetail, viewMode, viewPracticesModulesTable } = this.getVarsRender();

    if (viewPracticesModulesTable) {
      return (
        <SchedulerService.ScheduleDetailService
          onFilledSchedule={value => {
            this.setState({ schedule: value });
          }}
          onDeletePracticeModule={(deleted, appointmentItemRequestId) => {
            this.deletePractices(deleted, appointmentItemRequestId);
          }}
          onNewPracticeModuleAdded={(value, valueRaw) => {
            this.saveNewPractices(value, valueRaw);
          }}
          onUpdatePlanCopy={(value) => {
            this.replacePractices(value[0]);
          }}
          disabled={disabledScheduleDetail}
          hasschedules={this.state.events != null && this.state.events.length > 0}
          customer={this.state.customer}
          dates={{
            startDate: this.getStartEndDates(viewMode, selectedAppointment).startDate,
            endDate: this.getStartEndDates(viewMode, selectedAppointment).endDate,
          }}
          action={location.state.mode}
          appointmentId={params.id}
          checkInvalid={this.state.checkInvalid}
          items={this.getRenderItems(viewMode)}
          value={this.state.events}
          viewMode={viewMode}
          onCalculatePractices={(value) => {
            this.setState({ myPractices: value });
          }}
          onSelectedItems={(value, names) => {
            this.setState({ selected_item: value, selected_names: names });
          }}
          appointmentStatus={selectedAppointment.appointmentStatus}
          setProvider={this.setProvider}
          provider={this.getProviderValue(selectedAppointment)}
        />
      )
    }
  }

  renderEvents() {
    const { t, location, selectedAppointment } = this.props;
    const { agendaIconColor, eventChange, isEvents, isEventsError } = this.getVarsRender();
    const isEditMode = location.state.mode === 'edit';
    if (isEvents ) {
      return (
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color={agendaIconColor}>
                <CalendarToday />
              </CardIcon>
              <h3 className="card-icon-title">{t('appointment.agenda')}</h3>
              {isEventsError &&
                <div className="alert-rejected-card">
                  <ErrorOutlineIcon /> <span className="alertRejectedText">{t('appointment.new.schedule.rejected')}</span>
                </div>}
            </CardHeader>
            <CardBody className="calendarBody" calendar>
              <React.Fragment>
                <Calendar
                  showMultiDayTimes={true}
                  selectable
                  localizer={this.state.localizer}
                  events={this.state.events}
                  defaultView="month"
                  step={30}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onView={event => this.onChangeView(event)}
                  onSelectEvent={event => this.handleOnSelectedEvent(event)}
                  eventPropGetter={event => this.eventColors(event)}
                  messages={{
                    next: t('common.next'),
                    previous: t('common.prev'),
                    today: t('common.today'),
                    month: t('common.month'),
                    week: t('common.week'),
                    day: t('common.day'),
                    event: t('Practice'),
                    date: t('common.date'),
                    time: t('common.hour'),
                    noEventsInRange: t('appointment.notPractices'),
                  }}
                  components={{
                    event: EventWithIcon,
                  }}
                />
                {isEditMode ?
                  <div className="booking-errors-actions">
                    <Button
                      color="danger"
                      disabled={eventChange === false}
                      onClick={() => this.undoChanges()}
                      id="button-booking-undo"
                    >
                      {t('appointment.button.undo')}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.saveBookings()}
                      disabled={this.state.alertStatus === 'ERROR' || !eventChange}
                      id="button-booking-apply"
                    >
                      {t('appointment.button.apply')}
                    </Button>
                  </div>
                  : <div />
                }
                <CustomDialog
                  title={t('appointment.scheduleDetail')}
                  maxWidth="md"
                  open={this.state.openDetail}
                  onClose={() => this.setState({ openDetail: false })}
                  className="appointment-detail-component"
                >
                  <BookingDetail
                    readOnly={(this.state.scheduleStatus !== 'APPROVED'
                      && this.state.scheduleStatus !== 'PENDING_APPROVAL'
                      && this.state.scheduleStatus !== 'REJECTED')
                    }
                    event={this.state.currentEvent}
                    action={location.state.action}
                    mode={location.state.mode}
                    appointmentStatus={selectedAppointment.appointmentStatus}
                    onCancel={() => this.setState({ openDetail: false })}
                    updateAppointment={appointment => this.updateAppointment(appointment)}
                    disabled={location.state.mode === 'view'}
                    errors={this.state.updateErrors}
                    status={this.state.status}
                  />
                </CustomDialog>
              </React.Fragment>
            </CardBody>
          </Card>
        </GridItem>
      )
    }
  }

  formatBookingsErrors() {
    const bookingErrorsList = this.state.bookingErrors.map((item, index) => ({
      ...item,
      key: index+1,
    }));
    return bookingErrorsList.map((e) => <li key={`error-${e.key}`}>{e.detail}</li>);
  }

  renderOnNotLocationView() {
    const { t, location } = this.props;
    const { bookings, bookingErrors, busy, openConfirmCancel, openConfirmChanges, alertErrorMessage, alertErrorOpen, alertOpen, alertStatus } = this.state;
    if (location.state.mode !== 'view') {
      return (
        <>
          {openConfirmCancel &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.button.undo')}
              onCancel={() => this.setState({ openConfirmCancel: false })}
              cancelBtnCssClass="danger"
              cancelBtnText={t('common.cancel')}
              onConfirm={this.deleteBookings()}
              confirmBtnCssClass="primary"
              confirmBtnText={t('common.accept')}
            />
          }

          {openConfirmChanges &&
            <CustomSweetAlert
              type="warning"
              className="alertModifyChanges"
              title={t('common.modifyChanges')}
              onCancel={() => this.setState({ openConfirmChanges: false })}
              showCancel={false}
              showConfirm={false}
              message={
                <>
                  <p>{t('appointment.messageConfirm')}</p>
                  <div className="booking-errors-actions">
                    <Button
                      color="danger"
                      onClick={() => this.setState({ openConfirmChanges: false })}
                      id="button-cancel-book"
                    >
                      {t('common.cancel')}
                    </Button>
                    <ButtonSpinner
                      className="buttonSpinner"
                      loading={busy}
                      color="primary"
                      onClick={() => this.saveBookings()}
                      disabled={alertStatus === 'ERROR' || busy}
                      label={t('common.save')}
                      labelLoading={t('common.saving')}
                      id="button-save-book"
                    />
                  </div>
                </>
              }
            />
          }

          {alertErrorOpen &&
            <CustomSweetAlert
              type="error"
              className="alertErrorMessage"
              title={t('appointment.new.appointmentReview')}
              showConfirm={false}
              showCancel
              onCancel={() => this.setState({ alertErrorOpen: false })}
              cancelBtnCssClass="danger"
              cancelBtnText={t('common.cancel')}
              message={<p>{alertErrorMessage}</p>}
            />
          }

          {alertOpen && alertStatus === 'ERROR' ?
            <CustomSweetAlert
              type="error"
              className="alertListMessages"
              title={t('appointment.new.appointmentReview')}
              style={{ display: "block", marginTop: "-100px" }}
              onConfirm={() => this.setState({ alertOpen: false, showAgendaPracticeDisplay: false })}
              confirmBtnCssClass="danger"
              confirmBtnText={t('common.cancel')}
              showCancel={false}
              message={
                bookingErrors.length > 0 &&
                  <ul className="errors-list-alert">
                    {this.formatBookingsErrors()}
                  </ul>
              }
            /> : <div />}
            {alertOpen && alertStatus === 'WARNING' ?
              <CustomSweetAlert
                type="warning"
                title={t('appointment.new.appointmentReview')}
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => this.afterSave(bookings)}
                confirmBtnCssClass="primary"
                confirmBtnText={t('common.continue')}
                showCancel={false}
                message={
                  bookingErrors.length > 0 &&
                    <ul className="errors-list-alert">
                      {this.formatBookingsErrors()}
                    </ul>
                }
              /> : <div />
          }
        </>
      )
    }
  }

  getCustomerFormServiceValue() {
    if (this.props.selectedAppointment) {
      return this.getCustomer();
    }
    return null;
  }

  getProviderFormServiceValue() {
    if (this.props.selectedAppointment) {
      return this.getProvider();
    }
    return null;
  }

  formatSelectedNames() {
    const practicesSelected = this.state.selected_names.map((item, index) => ({
      name: item,
      id: index+1,
    }));
    return practicesSelected.map((e) =>
      <li key={`practice-${e.id}`}>{e.name}</li>
    );
  }

  onDateRange (isValid) {
    this.setState({ isDateRangeValid: isValid });
  }

  render() {
    const { t, appointmentsTools, onGetAppointmentsTools, onCheckAppointmentToolsEdit, params, selectedAppointment, onDeleteAppointmentTool } = this.props;

    if (this.isLoading(this.props, this.state)) {
      return (<FullLoading />)
    }

    const { authorityAgenda, eventChange, hasItems, showCoreFrames, toolsIconColor, viewMode, isDateRangeValid } = this.getVarsRender();

    return (
      <BeforeUnloadComponent
        blockRoute={true}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          this.renderModalComponentHandler(handleModalLeave, handleModalCancel);
        }}
      >
        <Snackbar
          place="tr"
          color={this.state.alertMessageColor}
          message={this.state.alertMessageMsg}
          open={this.state.alertMessageOpen}
        />
        <GridContainer className={`appointment-detail appointment-${viewMode}`}>
          <GridItem className="appointment-data" xs={12}>
            {this.state.showConfirmUndo &&
              <CustomSweetAlert
                type="warning"
                title={t('common.undoMessage')}
                onConfirm={() => this.confirmUndo()}
                onCancel={() => this.cancelUndo()}
                confirmBtnCssClass="primary"
                cancelBtnCssClass="danger"
                cancelBtnText={t('common.cancel')}
                confirmBtnText={t('common.confirm')}
                showCancel
              />
            }

            {this.state.invalidStartDate && this.alertErrorStartDate(t)}

            {this.state.showConfirmSave &&
              <CustomSweetAlert
                type="warning"
                title={t('common.saveChangesMessage')}
                onConfirm={() => this.confirmSave()}
                onCancel={() => this.cancelSave()}
                showCancel={false}
                showConfirm={false}
                message={
                  <div className="customAlertButtons">
                    <Button
                      id="button-cancel-conf"
                      color="danger"
                      onClick={() => {
                        this.cancelSave()
                        this.setState({ busy: false })
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                    <ButtonSpinner
                      onClick={() => { this.confirmSave() }}
                      disabled={this.state.busy}
                      label={t('confirm')}
                      labelLoading={t('common.saving')}
                      loading={this.state.busy}
                      typeButton="submit"
                      color="primary"
                      id="button-save-conf"
                    />
                  </div>
                }
              />
            }
            {viewMode !== 'copy' &&
              <Card>
                <CardHeader icon>
                  <CardIcon color="secondary">
                    <Assignment />
                  </CardIcon>
                  <h3 className="card-icon-title">{t('appointment.title')}</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer className="appointment-detail-schedule">
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="appointment-id"
                        disabled={true}
                        labelText={t('appointment.input.appointment_id')}
                        value={selectedAppointment?.appointmentId ?? null}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="appointment_status"
                        labelText={t('appointment.input.status')}
                        value={this.getAppointmentStatus(selectedAppointment?.appointmentStatus)}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input" xs={2}>
                      <ValidationInput
                        id="init_date"
                        disabled={true}
                        text={t('appointment.input.init_date')}
                        value={this.getCreateDatetime(selectedAppointment?.createdDateTime)}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer className="appointment-detail-schedule">
                    <GridItem className="fullWidth-input validation-input" xs={12} sm={4}>
                      <ValidationInput
                        id="appointment-user"
                        classes={{
                          input: "fullWidth-input"
                        }}
                        fullWidth
                        text={t('appointment.input.created_user')}
                        disabled={true}
                        className="fullWidth-input"
                        value={this.getCreationEmployeeName(selectedAppointment)}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input validation-input" xs={2} sm={4}>
                      <CustomInput
                        id="clientId"
                        labelText={t('appointment.input.clientId')}
                        value={selectedAppointment?.applicationClient ?? '-'}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem className="fullWidth-input" xs={2}>
                      <CustomInput
                        id="foreignId"
                        labelText={t('appointment.input.foreignId')}
                        value={selectedAppointment.foreignId ?? '-'}
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            }
          </GridItem>

          <GridItem className="schedule-form" xs={12} sm={12} md={6}>
            <SchedulerService.CustomerFormService
              onFilledCustomer={(value) => {
                this.setState((prevState) => ({...prevState, customerId: value.customer, addressId: value.address, zoneId: value.zone }))}}
              value={this.getCustomerFormServiceValue()}
              disabled={viewMode !== 'copy'}
              linkAccept={true}
            />
          </GridItem>
          <SchedulerService.ProviderFormService
            onFilledProvider={value => this.setProviderF(value)}
            onFilledFinancier={value => this.setFinancier(value)}
            value={this.getProviderFormServiceValue()}
            disabled={viewMode !== 'copy'}
            isDateRangeValid={(isValid) => this.onDateRange(isValid)}
          />
          {this.renderAuthorizerDiagnosticFrom()}

          {this.renderPracticesModulesTable()}

          {this.renderEvents()}

          {this.renderCancellations()}

          {showCoreFrames &&
            <AppointmentToolsService.Form
              assignAgenda={viewMode === 'assign_agenda'}
              viewMode={authorityAgenda ? 'view' : viewMode}
              appointmentIdToSave={this.state.appointmentId}
              appointmentId={this.state.appointmentId || params.id}
              componentAction={this.getComponentAction(authorityAgenda, viewMode)}
              onDeleteAppointmentTool={onDeleteAppointmentTool}
              onGetAppointmentsTools={onGetAppointmentsTools}
              appointmentsTools={appointmentsTools}
              onBack={this.onBack}
              eventChangesPending={eventChange}
              selectedAppointment={selectedAppointment}
              onCheckAppointmentToolsEdit={onCheckAppointmentToolsEdit}
              afterAppointmentToolsSave={this.saveAppointmentTools}
              onAppointmentToolsSaveError={this.handleAppointmentToolsSaveError}
              performSave={this.state.performAppointmentToolsSave}
              iconCardColor={toolsIconColor}
            />
          }
          {viewMode === 'assign_agenda' &&
            <GridItem xs={12} className="schedule-grid-submit justify-space-between">
              <Button id="button-back" onClick={() => browserHistory.push('/solicitudes')}>
                {t('common.cancel')}
              </Button>
              <Button id="button-new-request" disabled={hasItems} color="blue" onClick={() => this.confirmSave()}>
                {t('appointment.new.request-appointment')}
              </Button>
            </GridItem>
          }
          {viewMode === 'copy' &&
            <GridItem xs={12} className="schedule-grid-submit justify-space-between">
              <Button id="button-back" onClick={() => browserHistory.push('/solicitudes')}>
                {t('common.cancel')}
              </Button>
              <Button id="button-save" color="blue" disabled={!isDateRangeValid} onClick={() => this.showDialogSaveNewAppointment()}>
                {t('appointment.new.save')}
              </Button>
            </GridItem>
          }

          {viewMode === 'edit'  &&
            <ConfirmAlert
              className="saveChangesConfirm"
              confirmBodyText={t('saveChanges.confirm')}
              onConfirm={() => this.saveMetadata(true)}
              onClose={() => {
                this.setState({ confirmMetadataEdit: false, busy: false })
              }}
              showCancel={false}
              showConfirm={false}
              customButtons={
                <>
                  <Button
                    id="button-cancel"
                    color="danger"
                    onClick={() => {
                      this.setState({ confirmMetadataEdit: false, busy: false })
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <ButtonSpinner
                    onClick={() => {
                      this.setState({ busy: true })
                      this.saveMetadata(true)
                    }}
                    disabled={this.state.busy}
                    label={t('confirm')}
                    labelLoading={t('common.saving')}
                    loading={this.state.busy}
                    typeButton="submit"
                    color="primary"
                    id="button-saveMeta"
                  />
                </>
              }
              showConfirmWithText={this.state.confirmMetadataEdit}
            />
          }
          {this.state.showAgendaPracticeDisplay &&
            <CustomSweetAlert
              type="warning"
              className="alertAssignListPM"
              title={t('appointment.new.dialog.asign.schedule')}
              onConfirm={() => this.confirmedAssignAgendaReal()}
              confirmBtnCssClass="primary"
              confirmBtnText={t('common.accept')}
              message={
                <div className="date-before-message">
                  <p>{t('asign.schedules.practice.question.title')}</p>
                  <ul className="errors-list-alert">
                    {this.formatSelectedNames()}
                  </ul>
                </div>
              }
              showCancel={false}
            />
          }
          {this.state.confirmOpenNewAppointment &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.new.dialog.confimation.title')}
              onConfirm={() => this.saveNewSchedule()}
              onCancel={() => this.setState({ confirmOpenNewAppointment: false })}
              confirmBtnCssClass="primary"
              cancelBtnCssClass="danger"
              confirmBtnText={t('appointment.new.dialog.confimation.button_confirm')}
              cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
              message={t('appointment.new.dialog.confimation.description')}
              showCancel
            />
          }
          {this.state.showTimeOutException &&
            <CustomSweetAlert
              type="danger"
              title={t('appointment.agenda.review.scheduls.quantity.title')}
              onConfirm={() => this.setState({ showTimeOutException: false })}
              confirmBtnCssClass="danger"
              confirmBtnText={t('appointment.agenda.review.scheduls.quantity.cancel')}
              message={t('appointment.agenda.review.scheduls.quantity.message')}
              showCancel={false}
            />
          }
          {this.state.hasInvalidItemRequest &&
            <CustomSweetAlert
              type="danger"
              title={""}
              onConfirm={() => this.setState({ hasInvalidItemRequest: false })}
              confirmBtnCssClass="danger"
              confirmBtnText={t('appointment.agenda.review.scheduls.quantity.cancel')}
              message={t('appointment.agenda.has.invalid.item.request.message')}
              showCancel={false}
            />
          }
          {this.state.showErrorStartDayBefore &&
            <CustomSweetAlert
              type="warning"
              title={t('appointment.agenda.dialog.error_has_praticesbefore.title')}
              onConfirm={() => this.confirmedAssignAgenda()}
              onCancel={() => this.setState({ showErrorStartDayBefore: false })}
              confirmBtnCssClass="primary"
              cancelBtnCssClass="danger"
              confirmBtnText={t('appointment.agenda.dialog.confimation.button_confirm')}
              cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
              message={
                <div className="date-before-message">
                  <p>{t('appointment.agenda.dialog.error_has_praticesbefore.message.options')}</p>
                  <ul className="errors-list-alert">
                    <li key="option1">{t('appointment.agenda.dialog.error_has_praticesbefore.message.option1')}</li>
                    <li key="option2">{t('appointment.agenda.dialog.error_has_praticesbefore.message.option2')}</li>
                  </ul>
                </div>
              }
              showCancel
            />
          }
          {this.renderOnNotLocationView()}
        </GridContainer>
      </BeforeUnloadComponent>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
}

AppointmentDetail.propTypes = {
  t: PropTypes.func,
  selectedAppointment: PropTypes.object,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onGetAppointmentsTools: PropTypes.func,
  onDeleteAppointmentTool: PropTypes.func,
  onGetSchedulesAppointment: PropTypes.func,
  classes: PropTypes.object,
  onCheckAppointmentToolsEdit: PropTypes.func,
  onSaveBookings: PropTypes.func,
  appointmentsTools: PropTypes.array,
}

const styles = {
  cardHeader: {
    zIndex: '0 !important',
  }
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));