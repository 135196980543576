import React from "react"
import CircularProgress from '@material-ui/core/CircularProgress'

export const CustomLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}
