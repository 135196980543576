import { useEffect, useMemo, useState } from 'react'
import { browserHistory } from 'react-router'
import DiabeticApiInvoker from '../../../api/DiabeticApiIvoker'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import Button from '../../CustomButtons/Button'
import { Assignment, Edit } from '@material-ui/icons'

export const useDiabeticProgram = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterable, setFilterable] = useState(true)
  const [customerName, setCustomerName] = useState(null)
  const [affiliateId, setAffiliateId] = useState(null)
  const [localDateStart, setLocalDateStart] = useState(null)
  const [doctor, setDoctor] = useState(null)

  const addProgram = () => browserHistory.push('/diabetic-program/form')

  let company = localStorage.getItem('itlg_default_company_name')

  const filterSearchClear = () => {
    setCustomerName('')
    setDoctor('')
    setAffiliateId('')
    setLocalDateStart(null)
    setFilterable(false)
    getAllDiabetic()
  }

  const filterSearch = () => {
    setLoading(true)
    let createdUserNameQuery
    let customerNameQuery
    let affiliateIdQuery
    let localDateStartQuery

    if (customerName) {
      customerNameQuery = `customerName=${customerName}`
    }
    if (doctor) {
      createdUserNameQuery = `&&createdUserName=${doctor}`
    }
    if (affiliateId) {
      affiliateIdQuery = `&&affiliateId=${affiliateId}`
    }
    if (localDateStart) {
      localDateStartQuery = `&&localDateStart=${localDateStart}`
    }
    let params = `${customerNameQuery ? customerNameQuery : ''}${
      createdUserNameQuery ? createdUserNameQuery : ''
    }${affiliateIdQuery ? affiliateIdQuery : ''}${
      localDateStartQuery ? localDateStartQuery : ''
    }`

    DiabeticApiInvoker.filterListData(params, (response) => {
      setData(formatDataToTable(response))
    })
  }

  const formatDataToTable = (response) => {
    let data = []
    response.map((diabetic) => {
      let dataDiabetic = {
        name: diabetic.diabetics.patientId.firstName,
        last_name: diabetic.diabetics.patientId.lastName,
        axa_id: diabetic.diabetics.affiliateId,
        date: diabetic.diabetics.dateCreated,
        time: diabetic.diabetics.timeCreated,
        doctor: diabetic.diabetics.createdUserName,
        actions: renderActionComponent(
          diabetic.diabetics.diabeticsId,
          diabetic.editable
        ),
      }
      data.push(dataDiabetic)
    })
    setLoading(false)
    return data
  }

  const renderActionComponent = (id, editable) => {
    return (
      <div className="appointments-actions">
        <Tooltip title={'Ver'}>
          <span>
            <Button
              simple
              justIcon
              color="info"
              authority="diabetic_view"
              data-testid="ver-button"
              onClick={() => goToView(id)}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={'Editar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={!editable}
              color="success"
              authority="diabetic_edit"
              data-testid="edit-button"
              onClick={() => goToEdit(id)}
            >
              <Edit />
            </Button>
          </div>
        </Tooltip>
      </div>
    )
  }

  const goToEdit = (id) => {
    browserHistory.push(`/diabetic-program/edit/${id}`)
  }

  const goToView = (id) => {
    
    browserHistory.push({pathname:`/diabetic-program/view/${id}`, state: {readOnlyMode: true}})
  }

  const getAllDiabetic = () => {
    setLoading(true)
    DiabeticApiInvoker.getAllDiabetics((response) => {
      setData(formatDataToTable(response))
      setFilterable(true)
    })
  }

  useEffect(() => {
  }, [customerName, doctor, affiliateId, localDateStart]);
  
  
  useEffect(() => {
   
    getAllDiabetic();
    window.scrollTo(0, 0); 
  
  }, [filterable]); 

  return {
    data,
    loading,
    filterable,
    filterSearchClear,
    filterSearch,
    setDoctor,
    doctor,
    setLocalDateStart,
    localDateStart,
    setAffiliateId,
    affiliateId,
    setCustomerName,
    customerName,
    addProgram,
    renderActionComponent 
  }
}
